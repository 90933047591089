import styled, { css } from 'styled-components';
import { breakpointUp } from '@btc-snxt/ui';

const WebkitScrollbarCSS = css`
  ::-webkit-scrollbar {
    right: 5rem;
    width: 0.375rem;
  }

  ::-webkit-scrollbar-thumb {
    &:hover {
      background: #555;
    }
  }
`;

export const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

export const StyledBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: calc(100% - 4.25rem);
  overflow: auto;
  position: fixed;
  transition: 0.5s;
  top: 4.063rem;
  width: 100%;

  ${breakpointUp.MD} {
    height: calc(100% - 4rem);
    transition: 0.5s;
    width: 100%;

    &.open {
      margin: 0 0 0 16.563rem;
      transition: 0.5s;
      width: calc(100% - 16.563rem);
    }
  }

  ${WebkitScrollbarCSS}

  & * {
    ${WebkitScrollbarCSS}
  }
`;
