import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { ClientResponse } from 'api/types/user/profile';

const useClientQuery = (clientId: string, options?: UseQueryOptionsObject<ClientResponse>) =>
  useMakeQuery<ClientResponse>(
    [queries.client, clientId],
    () => Api.admin.company.fetch(clientId),
    {
      cacheTime: 1000,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useClientQuery };
