import { AxiosRequestConfig } from 'axios';
import Client from 'api/services/client';
import {
  PaginationData,
  PaginationDataFilter,
  PaginationDataFilter as Filter,
} from 'api/types/app/pagination';
import { EmptyResponse, SearchRequest } from 'api/types/common';
import {
  EmailListRequest,
  EmailRequest,
  ExportCategoryNodesRequest,
  SurveyTypeResponse,
  ResultAnalysisResponseDto,
  SurveyTableResponse,
  SurveySelectResponseDto,
  SurveyResponse,
} from 'api/types/survey';

class Survey extends Client {
  api = {
    SURVEY_CREATE: `${this.http.baseUrl}/surveys/survey`,
    SURVEY_LIST: `${this.http.baseUrl}/surveys`,
    SURVEY_NO_FILTER_LIST: `${this.http.baseUrl}/surveys/surveys`,
    SURVEY_SELECTABLE_LIST: `${this.http.baseUrl}/surveys/selectable-surveys/get`,
    SURVEY_CATEGORIES_GET: `${this.http.baseUrl}/surveys/categories/{survey}`,
    SURVEY_ALL_CATEGORIES_GET: `${this.http.baseUrl}/surveys/categories`,
    SURVEY_DOWNLOAD_POST: `${this.http.baseUrl}/surveys/share`,
    SURVEY_EMAIL_POST: `${this.http.baseUrl}/surveys/share/email`,
    SURVEY_DUPLICATE_POST: `${this.http.baseUrl}/surveys/duplicate/{survey}`,
    SURVEY_GET: `${this.http.baseUrl}/surveys/survey/{survey}`,
    SURVEY_PUT: `${this.http.baseUrl}/surveys/survey/{survey}`,
    SURVEY_DELETE: `${this.http.baseUrl}/surveys/survey/{survey}`,
    SURVEY_EMAIL_LIST_POST: `${this.http.baseUrl}/surveys/share/email/{survey}`,
    SURVEY_PUBLISH_POST: `${this.http.baseUrl}/surveys/publish`,
    SURVEY_QUESTION_GENERATE_XLSX: `${this.http.baseUrl}/generate-xlsx/survey-questions/{surveyQuestion}`,
    SURVEY_TYPES_GET: `${this.http.baseUrl}/surveys/types/get`,
  };

  createSurvey = (data: any): Promise<any> => {
    return this.http.post(this.api.SURVEY_CREATE, data);
  };

  filterSurveys = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<SurveyTableResponse>> => {
    return this.http.get(this.api.SURVEY_LIST, { params: request });
  };

  getSurveys = (): Promise<SurveyTableResponse[]> => {
    return this.http.get(this.api.SURVEY_NO_FILTER_LIST);
  };

  getSelectableSurveys = (request: SearchRequest): Promise<SurveySelectResponseDto[]> => {
    return this.http.get(this.api.SURVEY_SELECTABLE_LIST, { params: request });
  };

  getAllCategories = (): Promise<any[]> => {
    return this.http.get(this.api.SURVEY_ALL_CATEGORIES_GET);
  };

  downloadQR = (
    url: string,
    fileType: string,
    data: Filter = {},
    config: AxiosRequestConfig = {},
  ): Promise<Blob> => {
    return this.http.post(
      this.api.SURVEY_DOWNLOAD_POST,
      { url: url, fileType: fileType, data: data },
      { ...config, responseType: 'blob' },
    );
  };

  sendQRToEmail = (data: EmailRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.SURVEY_EMAIL_POST, data);
  };

  getSurvey = (survey: string): Promise<ResultAnalysisResponseDto[]> => {
    return this.http.get(this.buildUrl(this.api.SURVEY_CATEGORIES_GET, { survey }));
  };

  getSurveyToUpdate = (survey: string): Promise<SurveyResponse | any> => {
    return this.http.get(this.buildUrl(this.api.SURVEY_GET, { survey }));
  };

  updateSurvey = (survey: string, data: any): Promise<any> => {
    return this.http.put(this.buildUrl(this.api.SURVEY_PUT, { survey }), data);
  };

  sendLinkToEmails = (
    survey: string,
    data: EmailListRequest[],
    content: string,
    color: string,
  ): Promise<EmptyResponse> => {
    return this.http.post(this.buildUrl(this.api.SURVEY_EMAIL_LIST_POST, { survey }), {
      emailList: data,
      content: content,
      color: color,
    });
  };

  surveyPublish = (
    surveyId: string | undefined,
    categoryId: string,
    categoryName: string,
  ): Promise<EmptyResponse> => {
    return this.http.post(this.api.SURVEY_PUBLISH_POST, {
      surveyId: surveyId,
      categoryId: categoryId,
      categoryName: categoryName,
    });
  };

  duplicateSurvey = (survey: string): Promise<any> => {
    return this.http.post(this.buildUrl(this.api.SURVEY_DUPLICATE_POST, { survey }));
  };

  delete = (survey: string): Promise<any> => {
    return this.http.delete(this.buildUrl(this.api.SURVEY_DELETE, { survey }));
  };

  surveyQuestionGenerateXLSX = (
    surveyQuestion: string,
    data: ExportCategoryNodesRequest,
    config: AxiosRequestConfig = {},
  ): Promise<Blob> => {
    return this.http.post(
      this.buildUrl(this.api.SURVEY_QUESTION_GENERATE_XLSX, { surveyQuestion }),
      data,
      {
        ...config,
        responseType: 'blob',
      },
    );
  };

  getSurveyTypes = (): Promise<SurveyTypeResponse[]> => this.http.get(this.api.SURVEY_TYPES_GET);
}

export default Survey;
