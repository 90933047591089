import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { BaseModalProps, IconAdd, LoadingState, Modal, withLoader } from '@btc-snxt/ui';
import Icons from 'pages/Common/Settings/tabs/MembershipSettings/services/icon';
import { StyledCircle, StyledIconButton, StyledIconsBlock } from './MembershipIconsModal.style';

const MembershipIconsModal: React.FC<BaseModalProps & LoadingState> = (props) => {
  const { t } = useTranslation();

  const membershipIcons = new Icons();

  const [{ value: iconId }, , { setValue }] = useField<number>('icon_id');

  return (
    <Modal {...props} title={t('membership.form.choose_icon')}>
      <StyledIconsBlock>
        {membershipIcons.getIconsList().map((icon, index) => (
          <StyledIconButton
            key={index}
            className={iconId == icon.id ? 'selected' : ''}
            onClick={() => {
              setValue(icon.id);
              props.setIsOpen(false);
            }}
          >
            <StyledCircle>{icon.icon}</StyledCircle>

            <IconAdd className={iconId == icon.id ? 'selected' : ''} />
          </StyledIconButton>
        ))}
      </StyledIconsBlock>
    </Modal>
  );
};

export default withLoader(MembershipIconsModal);
