import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import {
  PersonPercentBarChart,
  QuestionAnswersFilter,
  QuestionType,
  StackedPercentBarChart,
} from '@btc-snxt/graph';
import {
  Card,
  Dropdown,
  FixedCenteredSpinner,
  ImageFormat,
  PrimaryButton,
  SubmenuDropdown,
  componentToImageAndDownload,
  DropdownItemWrapper,
  error,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { MainQuestions } from 'api/types/filter/filter';
import { ExportCategoryNodesRequest } from 'api/types/survey';
import { appendToArrayOrDeleteIfExists } from 'helpers/commonFunctions';
import i18n from 'services/i18n';
import { DataForSurveyController } from 'pages/Common/ResultAnalysis/ResultAnalysis';
import { AnswerTable } from 'pages/Common/ResultAnalysis/CommonComponents/index';
import {
  StyledGraphTitleWrapper,
  StyledGraphWrapper,
} from 'pages/Common/ResultAnalysis/helpers.style';
import { NPSDistributionPieChart } from './components/NPSDistributionPieChart';
import { NPSGaugeBarChart } from './components/NPSGaugeBarChart';
import {
  StyledNPSOverview,
  StyledNPSOverviewBlock,
  StyledPersonPercentBarChart,
} from './NPSController.style';

export interface Props {
  data: DataForSurveyController;
}

const NPSController: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [filters] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<MainQuestions[]>([]);
  const [questionFilters, setQuestionFilters] = useState<QuestionAnswersFilter>();
  const [questionFiltersArray, setQuestionFiltersArray] = useState<QuestionAnswersFilter[]>([]);
  const [exportCategoryNodes, setExportCategoryNodes] = useState<ExportCategoryNodesRequest>(
    () => ({
      categoryNodes: props.data.filters.map((filter) => ({
        id: filter.toString(),
      })),
    }),
  );

  const stackedPercentBarChartRef = useRef(null);
  const personPercentBarChartRef = useRef(null);
  const npsDistributionPieChartRef = useRef(null);
  const npsGaugeBarChartRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Api.filter.filter
        .sendFilters(props.data)
        .then((data) => setFilteredData(data.questions))
        .finally(() => setLoading(false));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (questionFilters != undefined) {
      appendToArrayOrDeleteIfExists(
        props.data.questionAnswersFilter,
        questionFilters.questionAnswersFilter.id!,
      );
      setQuestionFiltersArray([questionFilters]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionFilters]);

  useEffect(() => {
    setExportCategoryNodes({
      categoryNodes: props.data.filters.map((filter) => ({
        id: filter.toString(),
      })),
    });
  }, [props.data.filters]);

  const submitAnswerFilters = async () => {
    setLoading(true);
    await Api.filter.filter
      .sendFilters(props.data)
      .then((data) => setFilteredData(data.questions))
      .finally(() => setLoading(false));
  };

  const handleXLSXExport = async (surveyQuestionId?: number | string, questionTitle?: string) => {
    surveyQuestionId &&
      (await Api.survey.survey
        .surveyQuestionGenerateXLSX(surveyQuestionId.toString(), exportCategoryNodes)
        .then((data) => fileDownload(data, `${questionTitle}.xlsx` ?? 'generatedXLSX'))
        .catch(() => error(t('message.fail_export'))));
  };

  const renderSubmenuItem = (title: string, handleClick: () => void) => (
    <DropdownItemWrapper onClick={handleClick}>{title}</DropdownItemWrapper>
  );

  const renderResultsBasedOnType = (questions: MainQuestions, index: number) => {
    if (
      questions.questions[0].answers.length === 0 ||
      !questions.questions[0].answers.find((v) => v.count > 0)
    ) {
      return (
        <StyledNPSOverview>
          <Card title={`#${index + 1} `.concat(t('graph.nps.overview'))}>{t('graph.no_data')}</Card>
        </StyledNPSOverview>
      );
    }

    if (questions.questions[0].questionType === QuestionType.SingleSelect) {
      return (
        <StyledPersonPercentBarChart ref={stackedPercentBarChartRef}>
          <Card
            title={`#${index + 1} `.concat(questions.mainQuestionTitle[i18n.language as any])}
            headerEndElement={
              <Dropdown>
                <SubmenuDropdown title={t('common.export')}>
                  {renderSubmenuItem(ImageFormat.PNG, () =>
                    componentToImageAndDownload(
                      stackedPercentBarChartRef.current as unknown as HTMLElement,
                      ImageFormat.PNG,
                      questions.mainQuestionTitle[i18n.language as any],
                    ),
                  )}

                  {renderSubmenuItem(ImageFormat.JPEG, () =>
                    componentToImageAndDownload(
                      stackedPercentBarChartRef.current as unknown as HTMLElement,
                      ImageFormat.JPEG,
                      questions.mainQuestionTitle[i18n.language as any],
                    ),
                  )}

                  {renderSubmenuItem(ImageFormat.SVG, () =>
                    componentToImageAndDownload(
                      stackedPercentBarChartRef.current as unknown as HTMLElement,
                      ImageFormat.SVG,
                      questions.mainQuestionTitle[i18n.language as any],
                    ),
                  )}

                  {renderSubmenuItem('XLSX', () =>
                    handleXLSXExport(
                      questions.questions[0].questionId,
                      questions.mainQuestionTitle[i18n.language as any],
                    ),
                  )}
                </SubmenuDropdown>
              </Dropdown>
            }
          >
            <StackedPercentBarChart
              questions={questions.questions}
              questionAnswersFilter={props.data.questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={true}
              filters={filters}
            />
          </Card>
        </StyledPersonPercentBarChart>
      );
    }

    if (questions.questions[0].questionType === QuestionType.OpinionScale) {
      return (
        <>
          <StyledPersonPercentBarChart ref={personPercentBarChartRef}>
            <Card
              title={`#${index + 1} `.concat(questions.mainQuestionTitle[i18n.language as any])}
              headerEndElement={
                <Dropdown>
                  <SubmenuDropdown title={t('common.export')}>
                    {renderSubmenuItem(ImageFormat.PNG, () =>
                      componentToImageAndDownload(
                        personPercentBarChartRef.current as unknown as HTMLElement,
                        ImageFormat.PNG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.JPEG, () =>
                      componentToImageAndDownload(
                        personPercentBarChartRef.current as unknown as HTMLElement,
                        ImageFormat.JPEG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.SVG, () =>
                      componentToImageAndDownload(
                        personPercentBarChartRef.current as unknown as HTMLElement,
                        ImageFormat.SVG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem('XLSX', () =>
                      handleXLSXExport(
                        questions.questions[0].questionId,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}
                  </SubmenuDropdown>
                </Dropdown>
              }
            >
              <PersonPercentBarChart
                questionTitle={questions.questions[0].questionTitle[i18n.language as any]}
                answers={questions.questions[0].answers}
                questionAnswersFilter={props.data.questionAnswersFilter}
                setQuestionFilters={setQuestionFilters}
                questionFiltersArray={questionFiltersArray}
                answersLabels={questions.questions[0].answersLabels}
              />
            </Card>
          </StyledPersonPercentBarChart>

          <StyledNPSOverviewBlock>
            <StyledNPSOverview ref={npsDistributionPieChartRef}>
              <Card
                title={`#${index + 1} `.concat(questions.mainQuestionTitle[i18n.language as any])}
                headerEndElement={
                  <Dropdown>
                    <SubmenuDropdown title={t('common.export')}>
                      {renderSubmenuItem(ImageFormat.PNG, () =>
                        componentToImageAndDownload(
                          npsDistributionPieChartRef.current as unknown as HTMLElement,
                          ImageFormat.PNG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem(ImageFormat.JPEG, () =>
                        componentToImageAndDownload(
                          npsDistributionPieChartRef.current as unknown as HTMLElement,
                          ImageFormat.JPEG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem(ImageFormat.SVG, () =>
                        componentToImageAndDownload(
                          npsDistributionPieChartRef.current as unknown as HTMLElement,
                          ImageFormat.SVG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem('XLSX', () =>
                        handleXLSXExport(
                          questions.questions[0].questionId,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}
                    </SubmenuDropdown>
                  </Dropdown>
                }
              >
                <NPSDistributionPieChart
                  questionId={questions.questions[0].questionId}
                  questionTitle={questions.questions[0].questionTitle[i18n.language as any]}
                  questionType={questions.questions[0].questionType}
                  answers={questions.questions[0].answers}
                />
              </Card>
            </StyledNPSOverview>

            <StyledNPSOverview ref={npsGaugeBarChartRef}>
              <Card
                title={`#${index + 1} `.concat(questions.mainQuestionTitle[i18n.language as any])}
                headerEndElement={
                  <Dropdown>
                    <SubmenuDropdown title={t('common.export')}>
                      {renderSubmenuItem(ImageFormat.PNG, () =>
                        componentToImageAndDownload(
                          npsGaugeBarChartRef.current as unknown as HTMLElement,
                          ImageFormat.PNG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem(ImageFormat.JPEG, () =>
                        componentToImageAndDownload(
                          npsGaugeBarChartRef.current as unknown as HTMLElement,
                          ImageFormat.JPEG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem(ImageFormat.SVG, () =>
                        componentToImageAndDownload(
                          npsGaugeBarChartRef.current as unknown as HTMLElement,
                          ImageFormat.SVG,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}

                      {renderSubmenuItem('XLSX', () =>
                        handleXLSXExport(
                          questions.questions[0].questionId,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}
                    </SubmenuDropdown>
                  </Dropdown>
                }
              >
                <NPSGaugeBarChart
                  questionId={questions.questions[0].questionId}
                  questionTitle={questions.questions[0].questionTitle[i18n.language as any]}
                  questionType={questions.questions[0].questionType}
                  answers={questions.questions[0].answers}
                />
              </Card>
            </StyledNPSOverview>
          </StyledNPSOverviewBlock>
        </>
      );
    }

    if (questions.questions[0].questionType === QuestionType.FreeText) {
      return (
        <Card
          title={`#${index + 1} `.concat(
            questions.questions[0].questionTitle[i18n.language as any],
          )}
          headerEndElement={
            <Dropdown>
              <SubmenuDropdown title={t('common.export')}>
                {renderSubmenuItem('XLSX', () =>
                  handleXLSXExport(
                    questions.questions[0].questionId,
                    questions.mainQuestionTitle[i18n.language as any],
                  ),
                )}
              </SubmenuDropdown>
            </Dropdown>
          }
        >
          <AnswerTable answers={questions.questions[0].answers} />
        </Card>
      );
    }
  };

  return (
    <StyledGraphWrapper>
      {loading ? (
        <FixedCenteredSpinner />
      ) : (
        Object.values(filteredData).map((value, index) => (
          <>
            <StyledGraphTitleWrapper>
              {questionFilters !== undefined ? (
                <PrimaryButton onClick={submitAnswerFilters}>
                  {t('graph.apply_filters')}
                </PrimaryButton>
              ) : (
                <> </>
              )}
            </StyledGraphTitleWrapper>

            {renderResultsBasedOnType(value, index)}
          </>
        ))
      )}
    </StyledGraphWrapper>
  );
};

export default NPSController;
