import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  BaseModalProps,
  Col,
  FormModal,
  ModalH3,
  Row,
  SecondaryButton,
  LoadingState,
  withLoader,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { UpdateProfileRequest } from 'api/types/user/profile';
import { BodyWrapper, FooterWrapper } from '../Profile/ProfileModal.style';

interface Props extends BaseModalProps {
  invoiceId: string;
}

const InvoiceModal: React.FC<Props & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  enum State {
    Default,
    Edit,
  }

  const [modalState, setModalState] = useState<State>(State.Default);

  const [formData, setFormData] = useState<any>({
    companyName: '',
    invoiceNumber: '',
    amount: '',
    status: '',
    generatedAt: '',
    name: '',
    billingFrequency: '',
    nextBillingDate: '',
    price: '',
    autoRenew: '',
  });

  const FormSchema = Yup.object().shape({
    companyName: Yup.string().required(),
    invoiceNumber: Yup.string().required(),
    amount: Yup.string().required(),
    status: Yup.string().required(),
    generatedAt: Yup.string().required(),
    name: Yup.string().required(),
    billingFrequency: Yup.string().required(),
    nextBillingDate: Yup.string().required(),
    price: Yup.string().required(),
    autoRenew: Yup.string().required(),
  });

  const isDisabled = () => modalState == State.Default;

  const isEditable = () => modalState == State.Edit;

  const handleOpen = async () => {
    props.setIsLoading(true);
    const data = await Api.settings.invoice.preview(props.invoiceId);
    setFormData({
      companyName: data.invoice.companyName,
      invoiceNumber: data.invoice.invoiceNumber,
      amount: '€' + data.invoice.amount,
      status: data.invoice.status,
      generatedAt: data.invoice.generatedAt.split('T')[0],
      name: data.membershipPlan.name,
      billingFrequency: data.clientMembership.billingFrequency,
      nextBillingDate: data.clientMembership.nextBillingDate,
      price: '€' + data.clientMembership.price,
      autoRenew: data.clientMembership.autoRenew ? t('common.yes') : t('common.no'),
    });
    props.setIsLoading(false);
  };

  const handleSubmit = async <T extends UpdateProfileRequest>(helper: FormikHelpers<T>) => {
    helper.setSubmitting(false);
  };

  return (
    <FormModal
      {...props}
      onOpened={handleOpen}
      title={t('title.invoice')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
      components={{
        Footer: ({ setIsOpen }) => {
          return (
            <FooterWrapper>
              <SecondaryButton
                onClick={() => {
                  setIsOpen(false);
                  isEditable() && setModalState(State.Default);
                }}
              >
                {t('common.close')}
              </SecondaryButton>
            </FooterWrapper>
          );
        },
      }}
    >
      <BodyWrapper>
        <Row>
          <Col xs={12} sm={6}>
            <Label htmlFor={'companyName'} text={t('label.company_name')} />
            <Field
              align={'left'}
              type={'text'}
              name={'companyName'}
              id={'companyName'}
              placeholder={t('label.company_name')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>

          <Col xs={12} sm={6}>
            <Label htmlFor={'invoiceNumber'} text={t('label.invoice_number')} />
            <Field
              type={'text'}
              name={'invoiceNumber'}
              id={'invoiceNumber'}
              placeholder={t('label.invoice_number')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4}>
            <Label htmlFor={'amount'} text={t('label.amount')} />
            <Field
              type={'text'}
              name={'amount'}
              id={'amount'}
              placeholder={t('label.amount')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>

          <Col xs={12} sm={4}>
            <Label htmlFor={'status'} text={t('label.status')} />
            <Field
              type={'text'}
              name={'status'}
              id={'status'}
              placeholder={t('label.status')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>

          <Col xs={6} sm={4}>
            <Label htmlFor={'generatedAt'} text={t('label.generated_at')} />
            <Field
              type={'text'}
              name={'generatedAt'}
              id={'generatedAt'}
              placeholder={t('label.generated_at')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>
        </Row>

        <ModalH3>{t('label.membership_info')}</ModalH3>

        <Row>
          <Col xs={12}>
            <Label htmlFor={'name'} text={t('membership.form.title')} />
            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('membership.form.title')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'billingFrequency'} text={t('label.billing_frequency')} />
            <Field
              type={'text'}
              name={'billingFrequency'}
              id={'billingFrequency'}
              placeholder={t('label.billing_frequency')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>

          <Col>
            <Label htmlFor={'nextBillingDate'} text={t('label.next_billing_date')} />
            <Field
              type={'text'}
              name={'nextBillingDate'}
              id={'nextBillingDate'}
              placeholder={t('label.next_billing_date')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'price'} text={t('label.price')} />
            <Field
              type={'text'}
              name={'price'}
              id={'price'}
              placeholder={t('label.price')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>

          <Col>
            <Label htmlFor={'autoRenew'} text={t('label.auto_renew')} />
            <Field
              type={'text'}
              name={'autoRenew'}
              id={'autoRenew'}
              placeholder={t('label.auto_renew')}
              className={'form-control'}
              disabled={isDisabled()}
            />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(InvoiceModal);
