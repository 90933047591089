import React from 'react';
import { IconCompany, IconNetwork, IconUserLetter } from '@btc-snxt/ui';

interface Icon {
  id: number;
  icon: JSX.Element;
}

class Icons {
  private iconsMap: Icon[] = [
    {
      id: 1,
      icon: <IconUserLetter />,
    },
    {
      id: 2,
      icon: <IconNetwork />,
    },
    {
      id: 3,
      icon: <IconCompany />,
    },
  ];

  public getIconsList = () => {
    return this.iconsMap;
  };

  public getIcon = (id: number) => {
    return this.iconsMap.find((value) => value.id == id);
  };
}

export default Icons;
