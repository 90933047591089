import Client from 'api/services/client';
import { DashboardRequest, DashboardResponse } from 'api/types/dashboard/dashboard';

class Dashboard extends Client {
  public readonly api = {
    GET_DATA: `${this.http.baseUrl}/dashboard`,
  };

  getDashboardData = (request: DashboardRequest): Promise<DashboardResponse> =>
    this.http.get(this.api.GET_DATA, { params: request });
}

export default Dashboard;
