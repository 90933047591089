import styled from 'styled-components';
import { breakpointDown, Icon } from '@btc-snxt/ui';
import { DropdownToggle } from 'reactstrap';

export const StyledProfileDropdown = styled.div``;

export const StyledProfileButton = styled(DropdownToggle)`
  align-self: center;
  border: none;
  border-radius: 0.5rem;
  line-height: 0;
  outline: none;
  padding: 0.625rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.grey200};
  }

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900} !important;
    height: 1.25rem;
    width: 1.25rem;

    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.grey700};
    }
  }
`;

export const StyledDropdownItem = styled.div`
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  width: 100%;

  ${breakpointDown.MD} {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }
`;
