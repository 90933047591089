import styled from 'styled-components';
import { NavItem as ReactStrapNavItem } from 'reactstrap';

export const StyledSubMenu = styled.div`
  a {
    padding-left: 2rem;
  }
`;

export const SubMenuNavItem = styled(ReactStrapNavItem)`
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  width: 100%;
`;
