import styled from 'styled-components';
import { Icon, noGlow } from '@btc-snxt/ui';

export const StyledCalendarInput = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.gray3};
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-grow: 1;

  input {
    border: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-size: 0.875rem;
    margin: 0 !important;

    &:disabled {
      background-color: transparent;
    }

    ${noGlow};
  }

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const StyledCalendarInputIcon = styled.div`
  align-items: center;
  display: flex;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0 0.5rem;
`;
