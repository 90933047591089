import { useAppState } from 'modules/appState';

const usePermissions = () => {
  const { permissions, setPermissions, resetPermissions } = useAppState();

  const convertPermissions = (permission: string | string[]): string[] => {
    if (Array.isArray(permission)) {
      return permission;
    } else {
      return permission.split('|');
    }
  };

  const hasAny = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (permissions.includes(permission)) {
          return true;
        }
      }
    }

    return false;
  };

  const hasAll = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    const includedPermissions: string[] = [];
    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (permissions.includes(permission)) {
          includedPermissions.push(permission);
        }
      }
    }
    return includedPermissions.length === arrayPermissions.length;
  };

  const doesNotHaveAll = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    const includedPermissions: string[] = [];
    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (!permissions.includes(permission)) {
          includedPermissions.push(permission);
        }
      }
    }

    return includedPermissions.length === arrayPermissions.length;
  };

  const doesNotHaveAny = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (!permissions.includes(permission)) {
          return true;
        }
      }
    }
    return false;
  };

  const can = hasAny;
  const cannot = doesNotHaveAny;

  return {
    hasAny,
    hasAll,
    doesNotHaveAny,
    doesNotHaveAll,
    can,
    cannot,
    setPermissions,
    resetPermissions,
  };
};

export default usePermissions;
