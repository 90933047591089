import React from 'react';
import AddContactsCSV from './AddContactsCSV';
import { EmailWrapper } from './ShareByEmail.style';

const ShareByEmail: React.FC = () => {
  const returnLinks = () => {
    return (
      <>
        <AddContactsCSV />
      </>
    );
  };

  return <EmailWrapper>{returnLinks()}</EmailWrapper>;
};

export default ShareByEmail;
