import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrimaryButton,
  SecondaryButton,
  Col,
  BaseModalProps,
  Modal,
  Row,
  error,
  success,
} from '@btc-snxt/ui';
import { Api } from 'api';

export interface CategoryModalProps extends BaseModalProps {
  categoryId: number;
}

interface Props extends CategoryModalProps {
  setIsParentModalOpen?: React.Dispatch<SetStateAction<boolean>>;
  handleDelete?: () => void;
}

const CategoryDeleteModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      await Api.category.category.deleteCategory(String(props.categoryId));
      success(t('category.delete_success'));
      props.setIsOpen(false);
      props.setIsParentModalOpen && props.setIsParentModalOpen(false);
      props.handleDelete && props.handleDelete();
    } catch (e) {
      error(t('category.delete_error'));
    }
  };

  return (
    <Modal
      {...props}
      title={t('common.confirmation')}
      size={'sm'}
      components={{
        Footer: () => {
          return (
            <div className={'d-flex justify-content-between'}>
              <Row>
                <Col>
                  <SecondaryButton onClick={handleDelete}>{t('common.yes')}</SecondaryButton>
                </Col>
                <Col>
                  <PrimaryButton
                    onClick={() => {
                      props.setIsOpen(false);
                    }}
                  >
                    {t('common.no')}
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          );
        },
      }}
    >
      <Row>
        <Col>{t('category.delete_confirm')}</Col>
      </Row>
    </Modal>
  );
};

export default CategoryDeleteModal;
