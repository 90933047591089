import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import fileDownload from 'js-file-download';
import { EmailInput, error, H5, H6, PrimaryButton, success } from '@btc-snxt/ui';
import { Api } from 'api';
import {
  QRContainer,
  DownloadWrapper,
  ExportQrWrapper,
  SendEmailWrapper,
  LabelWrapper,
  DownloadTypeSelect,
  DownloadTypeAndButtonWrapper,
  StyledShareQrCode,
  StyledShareQrCodeContent,
} from './ShareQrCode.style';
import { EmailRequest } from 'api/types/survey';

interface Props {
  links: any;
}

interface Options {
  value: any;
  label: any;
}

const ShareQrCode: React.FC<Props> = (props) => {
  const [links, setLinks] = useState<any[]>(props.links);
  const { t } = useTranslation();
  useEffect(() => {
    setLinks(props.links);
  }, [props.links]);

  const options: Options[] = [
    { value: 'svg', label: 'SVG' },
    { value: 'pdf', label: 'PDF' },
  ];

  const [selectedOption, setSelectedOption] = useState<Options>(options[0]);

  const SendEmailSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });
  const [sendEmailRequest] = useState({
    email: '',
    url: '',
  });

  const onSubmit = useCallback(
    async (request: EmailRequest, helpers: FormikHelpers<EmailRequest>) => {
      try {
        await Api.survey.survey.sendQRToEmail(request);
        success(t('qr_share.qr_share_success'));
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const downloadQR = async (link: string) => {
    await Api.survey.survey
      .downloadQR(link, selectedOption.value)
      .then((response) => fileDownload(response, `qr_code.` + selectedOption.value))
      .catch(() => error(t('invoices.export_error')));
  };

  const returnLinks = () => {
    return (
      <StyledShareQrCodeContent>
        {links.map((link: any) => (
          <div key={link.name}>
            <H6>{link.name} :</H6>
            <LabelWrapper key={link.name}>
              <QRContainer>
                <img src={link.qrcode} />
              </QRContainer>
              <ExportQrWrapper>
                <DownloadWrapper>
                  <H5>{t('qr_share.download_label')}</H5>
                  <DownloadTypeAndButtonWrapper>
                    <DownloadTypeSelect
                      classNamePrefix={'react-select'}
                      onChange={(newValue) => {
                        setSelectedOption(newValue as unknown as Options);
                      }}
                      value={selectedOption}
                      options={options}
                    />
                    <PrimaryButton
                      onClick={() => {
                        downloadQR(link.url);
                      }}
                    >
                      {t('qr_share.download_button')}
                    </PrimaryButton>
                  </DownloadTypeAndButtonWrapper>
                </DownloadWrapper>
                <SendEmailWrapper>
                  <H5>{t('qr_share.email_label')}</H5>
                  <Formik
                    initialValues={sendEmailRequest}
                    validationSchema={SendEmailSchema}
                    onSubmit={(request, helpers) => {
                      request.url = link.url;
                      onSubmit(request, helpers);
                    }}
                  >
                    {({ handleSubmit, handleChange, values }) => (
                      <Form onSubmit={handleSubmit}>
                        <EmailInput
                          id={'email'}
                          name={'email'}
                          placeholder={t('label.email')}
                          onChange={handleChange}
                          value={values.email}
                        />
                        <PrimaryButton type={'submit'}>{t('qr_share.email_button')}</PrimaryButton>
                      </Form>
                    )}
                  </Formik>
                </SendEmailWrapper>
              </ExportQrWrapper>
            </LabelWrapper>
          </div>
        ))}
      </StyledShareQrCodeContent>
    );
  };

  return <StyledShareQrCode>{returnLinks()}</StyledShareQrCode>;
};

export default ShareQrCode;
