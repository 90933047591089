import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { error, success } from '@btc-snxt/ui';
import { StyledTextArea, StyledTextBlock } from './EditableField.style';
import { Api } from 'api';
import usePermissions from 'helpers/usePermissions';

interface Props {
  line: string;
  en: string;
  lt: string;
  ru: string;
  lang: 'en' | 'lt' | 'ru';
  setShouldReload?: Dispatch<SetStateAction<boolean>>;
}

const EditableField: React.FC<Props> = ({ line, en, lt, ru, lang, setShouldReload }) => {
  const { t } = useTranslation();

  const remotePermissions = usePermissions();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [text, setText] = useState<string>(lang == 'en' ? en : lang == 'lt' ? lt : ru);

  const handleSubmit = async () => {
    try {
      await Api.translations.translations.updateTranslations(line, {
        en: lang == 'en' ? text : en,
        lt: lang == 'lt' ? text : lt,
        ru: lang == 'ru' ? text : ru,
      });
      setShouldReload && setShouldReload(true);
    } catch (e: any) {
      error(e.response?.error);
    } finally {
      success(t('translations.update_success'));
      setIsEditing(false);
    }
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.key === 'Enter' && e.currentTarget.blur();
  };

  const handleClick = () => {
    remotePermissions.hasAny('translations.update') && setIsEditing(true);
  };

  return isEditing ? (
    <StyledTextArea
      onChange={(e) => setText(e.target.value)}
      onBlur={handleSubmit}
      onKeyDown={(e) => handleEnterPress(e)}
      value={text}
    />
  ) : (
    <StyledTextBlock onClick={handleClick}>{text ?? '-'}</StyledTextBlock>
  );
};

export default EditableField;
