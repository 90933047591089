import styled from 'styled-components';
import { Button } from 'reactstrap';
import { breakpointUp, Icon, IconAdd, SecondaryButton } from '@btc-snxt/ui';

export const StyledMembershipCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 2.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.625rem ${({ theme }) => theme.colors.gray11};
  width: 100%;

  ${breakpointUp.MD} {
    width: 15.25rem;
  }
`;

export const StyledMembershipCardHeader = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledMembershipPlanIcon = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  width: 4.375rem;
  margin: 1rem;
  border-radius: 3rem;
  background-color: rgba(0, 130, 184, 0.15);

  ${Icon} {
    height: 2rem;
    width: 2rem;
  }
`;

export const StyledPriceBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPrice = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.25rem;
  line-height: 2.125rem;
`;

export const StyledPriceText = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.75rem;
`;

export const StyledYearPriceText = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledDescription = styled.div`
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;
  max-height: 1.125rem;
  overflow: hidden;
`;

export const StyledFeaturesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const StyledFeaturesHeader = styled.div`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: bold;
`;

export const StyledFeaturesBody = styled.div`
  height: 5rem;
  margin: 0.75rem 0;
`;

export const StyledFeaturesFooter = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 0.625rem;
`;

export const StyledFeature = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 1.25rem;

  ${Icon} {
    height: 1rem;
    width: 1rem;
    margin: 0.125rem;
  }

  ${IconAdd} {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

export const StyledAdminIconsBlock = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  bottom: -2rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    padding: 0;
    height: 2rem;
    width: 2rem;

    &:hover {
      ${Icon} {
        background-color: ${({ theme }) => theme.colors.blue};
      }
    }
  }

  ${Icon} {
    height: 1rem;
    width: 1rem;
    margin: 0.5rem;
  }
`;

export const StyledDetailsButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.blue};
  transition: 0.15s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledCurrentPlanButton = styled(SecondaryButton)`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledUnsubscribeButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  border: none !important;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.gray2};
  font-size: 0.75rem;
  margin-top: 0.125rem;
  margin-bottom: -1.25rem;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`;
