import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { FormModal, Row, LoadingState, withLoader, error, success, Label } from '@btc-snxt/ui';
import { Api } from 'api';
import { CategoryNodeRequest } from 'api/types/category/category';
import { BodyWrapper } from 'pages/Modal/Users/AddUserModal.style';
import { CategoryModalProps } from './CategoryDeleteModal';

interface Props extends CategoryModalProps {
  updateInfoModal?: () => Promise<void>;
  onEdit?: () => void;
}

const CategoryEditModal: React.FC<Props & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<CategoryNodeRequest>({
    id: 0,
    name: '',
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const handleOpen = async () => {
    try {
      props.setIsLoading(true);
      const data = await Api.category.category.fetchCategory(String(props.categoryId));
      setFormData({
        id: data.id,
        name: data.name,
      });
      props.setIsLoading(false);
    } catch (e) {
      error(t('category.fetch_error'));
    }
  };

  const handleSubmit = async <T extends CategoryNodeRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    try {
      await Api.category.category.editCategory(String(props.categoryId), values);
      success(t('category.update_success'));
      handleOpen();
      props.setIsOpen(false);
      props.updateInfoModal && (await props.updateInfoModal());
      props.onEdit && props.onEdit();
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      onOpened={handleOpen}
      title={t('category.update_category')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
    >
      <BodyWrapper>
        <Row>
          <Label htmlFor={'name'} text={t('label.name')} required={true} />

          <Field
            align={'left'}
            type={'text'}
            name={'name'}
            id={'name'}
            placeholder={t('label.name')}
            className={'form-control'}
          />

          <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(CategoryEditModal);
