import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import fileDownload from 'js-file-download';
import { error, H1, PrimaryButton, success } from '@btc-snxt/ui';
import { Api } from 'api';
import { usePrivacyPolicyQuery } from 'api/queries/privacy-policy/usePrivacyPolicyQuery';
import { StyledPrivacyPolicy } from './PrivacyPolicy.style';

const PrivacyPolicy: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [fetch, setFetch] = useState<number>(0);
  const reFetch = () => setFetch((prev) => prev + 1);

  const { data } = usePrivacyPolicyQuery(fetch);

  const [currentText, setCurrentText] = useState<string>();

  useEffect(() => {
    if (data && data.text) {
      setCurrentText(Object.entries(data.text).find((v) => v[0] === i18n.language)?.[1]);
    } else {
      reFetch();
    }
  }, [data, i18n.language]);

  const downloadPrivacyPolicy = async () => {
    success(t('qr_share.qr_share_success'));
    await Api.privacyPolicy.privacyPolicy
      .downloadPrivacyPolicy()
      .then((response) => fileDownload(response, 'privacy-policy.pdf'))
      .catch(() => error(t('tos.error')));
  };

  return (
    <div className="container">
      <StyledPrivacyPolicy>
        <H1>{t('settings.privacy_policy')}</H1>

        {currentText && parse(currentText)}

        <PrimaryButton onClick={() => downloadPrivacyPolicy()}>
          {t('qr_share.download_button')}
        </PrimaryButton>
      </StyledPrivacyPolicy>
    </div>
  );
};

export default PrivacyPolicy;
