import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { UserProfileResponse } from 'api/types/user/profile';

const useProfileQuery = (options?: UseQueryOptionsObject<UserProfileResponse>) =>
  useMakeQuery<UserProfileResponse>([queries.profile], () => Api.user.profile.fetch(), {
    staleTime: Infinity,
    retry: false,
    ...defaultOptions,
    ...(options ?? {}),
  });

export { useProfileQuery };
