import Http from 'api/http';
import Client from 'api/services/client';
import { PaginationData, PaginationDataFilter } from 'api/types/app/pagination';
import { EmptyResponse, TranslationsResponse, UpdateTranslationRequest } from 'api/types/common';

class Translations extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      TRANSLATIONS_LIST: `${this.http.baseUrl}/locale`,
      TRANSLATIONS_UPDATE: `${this.http.baseUrl}/locale/{line}`,
    };
  }

  filterTranslations = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<TranslationsResponse>> => {
    return this.http.get(this.api.TRANSLATIONS_LIST, { params: request });
  };

  updateTranslations = (line: string, data: UpdateTranslationRequest): Promise<EmptyResponse> => {
    return this.http.put(this.buildUrl(this.api.TRANSLATIONS_UPDATE, { line }), data);
  };
}

export default Translations;
