import { Button } from 'reactstrap';
import styled from 'styled-components';

export const StyledFacebookCallbackPage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const StyledCallbackHeader = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2.5rem;
`;

export const StyledCallbackBody = styled.div`
  font-size: 1rem;
`;

export const StyledCallbackButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue};
  margin: 1rem;
`;

export const StyledSpinnerWrapper = styled.div`
  font-size: 1rem;
  margin: 1rem;
`;
