import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import i18n from 'i18next';
import { Common } from 'helpers/enums/Common';
import { DataStorage } from '@btc-snxt/system';
import { AuthUtils } from 'helpers/useAuth/Auth.utils';
import { RouteList } from 'routeList';

class Http {
  axios: AxiosInstance;
  post: AxiosInstance['post'];
  get: AxiosInstance['get'];
  put: AxiosInstance['put'];
  delete: AxiosInstance['delete'];

  baseUrl = process.env.REACT_APP_BASE_URL;

  constructor() {
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Expose-Headers': 'Access-Control-*',
      'Access-Control-Allow-Headers':
        'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Access-Control-Allow-Origin': '*',
      Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      crossdomain: true,
    };
    const jwt = DataStorage.get(Common.JWT_TOKEN);

    this.axios = axios.create({
      withCredentials: true,
      headers: defaultHeaders,
    });

    if (!!jwt) {
      this.setBearer(jwt);
    }

    this.post = this.axios.post;
    this.get = this.axios.get;
    this.put = this.axios.put;
    this.delete = this.axios.delete;

    this.axios.interceptors.request.use((request) => {
      this.setBearer(DataStorage.get(Common.JWT_TOKEN));
      if (request.headers) {
        request.headers['locale'] = i18n.language;
      }

      return request;
    });
    this.axios.interceptors.response.use((response: AxiosResponse) => {
      return response.data;
    }, this.errorInterceptor);
  }

  setBearer(accessToken: string | null): void {
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  removeBearer(): void {
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: '',
    };
  }

  errorInterceptor = (error: AxiosError): any => {
    error.message =
      error?.response?.data.message || error?.response?.data.error || i18n.t('common.error');
    error.response = error?.response?.data || {};

    if (error?.response?.status === 401) {
      AuthUtils.removeJwt();
      location.href = RouteList.AUTHENTICATION.LOGIN.path;
    }

    return Promise.reject(error);
  };
}

export default Http;
