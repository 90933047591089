import styled from 'styled-components';
import { breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledMembershipSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
`;

export const StyledHeaderText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2.5rem;
`;

export const StyledBody = styled.div`
  width: 100%;

  ${breakpointUp.MD} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledCardsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${breakpointDown.MD} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAddPlanButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  i {
    margin-right: 0.5rem;
  }
`;
