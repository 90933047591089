import React, { useEffect, useState } from 'react';

import { CategoryNodeLevelResponse } from 'api/types/category/category';
import { Api } from 'api';
import { H4, IconArrowRightAlt } from '@btc-snxt/ui';
import { ButtonWrapper, InputsWrapper, InputWithBorderColor } from './CategoryTree.style';
import { OnVisibilityToggleParams } from './CategoryTree';
import { useTranslation } from 'react-i18next';

interface Props {
  level: number;
  setLevelsData: (data: CategoryNodeLevelResponse[]) => void;
  action: OnVisibilityToggleParams;
  categoryId: string;
}

const CategoryNodeLevel: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [levels, setLevels] = useState<CategoryNodeLevelResponse[]>([]);
  const [depth, setDepth] = useState<number>(0);

  const fetchData = async () => {
    Api.category.category.fetchNodeLevelsByCategory(props.categoryId).then((response) => {
      setLevels(response);
      props.setLevelsData(structuredClone(response));
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeInputValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    level: CategoryNodeLevelResponse,
  ) => {
    const name = event.target.value;

    setLevels((result) =>
      result.map((current) => (current.id === level.id ? { ...current, name } : current)),
    );
  };

  useEffect(() => {
    if (props.level < depth && props.action.expanded === true) {
      while (levels.length + 1 != props.level) {
        levels.pop();
      }
    }
    setDepth(props.level);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.level]);

  useEffect(() => {
    props.setLevelsData(levels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levels]);

  const renderInput = (name: string, level: CategoryNodeLevelResponse) => {
    return (
      <InputsWrapper key={level.id}>
        {level.depth != 1 ? <IconArrowRightAlt /> : <></>}
        <InputWithBorderColor
          $index={level.depth - 1}
          value={name}
          onChange={(event) => {
            onChangeInputValue(event, level);
          }}
        />
      </InputsWrapper>
    );
  };

  const renderInputs = () => {
    const content = [];
    for (let i = 0; i < props.level - 1; i++) {
      if (levels[i]) {
        content.push(renderInput(levels[i].name, levels[i]));
      } else {
        const name = '';
        levels.push({ id: i, depth: i + 1, name: name, category_collection_id: props.categoryId });
        content.push(renderInput(name, levels[i]));
      }
    }
    return content;
  };

  return (
    <div>
      <H4>{t('category.levels_header')}</H4>
      <ButtonWrapper>{renderInputs()}</ButtonWrapper>
    </div>
  );
};

export default CategoryNodeLevel;
