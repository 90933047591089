import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@btc-snxt/ui';
import { SurveyItemType, TemplateItem } from '@btc-snxt/survey';
import { useSurveyEditor } from '../../../SurveyEditorContext';
import { StyledAdditionalQuestionPicker } from './AdditionalQuestionPicker.style';

const AdditionalQuestionPicker: React.FC = () => {
  const context = useSurveyEditor();
  const { t } = useTranslation();
  const introExists = context.items.some((item) => item.type === SurveyItemType.Intro);
  const [introChecked, setIntroChecked] = useState(introExists);

  return (
    <>
      <StyledAdditionalQuestionPicker>
        {t('builder.intro')}
        <Switch
          checked={introChecked}
          onChange={() => {
            if (!introChecked) {
              const introItem: TemplateItem = {
                id: Date.now().toString(),
                type: SurveyItemType.Intro,
                initialValues: undefined,
                values: undefined,
              };
              context.addItem(introItem, 0);
            } else {
              const updatedItems = context.items.filter(
                (item) => item.type !== SurveyItemType.Intro,
              );
              context.setItems(updatedItems);
            }
            setIntroChecked(!introChecked);
          }}
        ></Switch>
      </StyledAdditionalQuestionPicker>
    </>
  );
};

export default AdditionalQuestionPicker;
