import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  IconMessage,
  MessageButton,
  PrimaryButton,
  DataGrid,
  DataEntry,
  Modal,
  BaseModalProps,
  ModalH3,
  Row,
  LoadingState,
  withLoader,
  SecondaryButton,
  error,
  success,
  ConfirmActionModal,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { useClientQuery } from 'api/queries/client/useClientQuery';
import { useCountriesQuery } from 'api/queries/common/useCountriesQuery';
import { Permissions } from 'helpers/auth';
import usePermissions from 'helpers/usePermissions';
import ClientEditModal from './ClientEditModal';
import { FooterWrapper } from './ClientInfoModal.style';

export interface ClientModalProps extends BaseModalProps {
  clientId: string;
  toggleRender: () => void;
}

const ClientInfoModal: React.FC<ClientModalProps & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  const { hasAny } = usePermissions();

  const client = useClientQuery(props.clientId);
  const countries = useCountriesQuery();

  setTimeout(() => props.setIsLoading(client.isLoading || countries.isLoading), 0);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const toggleConfirmModal = () => setConfirmModalOpen(!isConfirmModalOpen);

  const [onConfirm, setOnConfirm] = useState<() => void>();

  const toggleClientActive = () =>
    client.setQueryData((prev) => ({ ...prev, active: !prev.active }));

  const handleUpdateActive = async () => {
    let fail = false;
    props.setIsLoading(true);

    if (client.data) {
      await Api.admin.company
        .updateActive(props.clientId, { active: !client.data.active })
        .catch(() => (fail = true))
        .finally(() => {
          if (fail) {
            error(t(`client.${client.data.active ? 'deactivate' : 'activate'}_error`));
          } else {
            success(t(`client.${client.data.active ? 'deactivate' : 'activate'}_success`));
            toggleClientActive();
          }
          props.setIsLoading(false);
        });
    }
  };

  const handleDelete = async () => {
    let fail = false;
    await Api.admin.company
      .delete(props.clientId)
      .catch(() => {
        fail = true;
        error(t('client.delete_error'));
      })
      .finally(() => {
        if (!fail) {
          success(t('client.delete_success'));
          props.setIsOpen(false);
          props.toggleRender();
        }
      });
  };

  const handleUpdateInfoModal = () => {
    toggleEditModal();
    props.setIsOpen(false);
    props.toggleRender();
  };

  return (
    <Modal
      {...props}
      title={t('title.client_info')}
      components={{
        Footer: () => (
          <FooterWrapper>
            {hasAny(Permissions.CLIENTS.UPDATE_ACTIVE) && (
              <SecondaryButton
                onClick={() => {
                  setOnConfirm(() => handleUpdateActive);
                  toggleConfirmModal();
                }}
              >
                {t(`common.${client.data?.active ? 'deactivate' : 'activate'}`)}
              </SecondaryButton>
            )}

            <PrimaryButton onClick={toggleEditModal}>{t('common.edit')}</PrimaryButton>

            <PrimaryButton
              onClick={() => {
                setOnConfirm(() => handleDelete);
                toggleConfirmModal();
              }}
            >
              {t('common.delete')}
            </PrimaryButton>
          </FooterWrapper>
        ),
      }}
    >
      <Row>
        <Col>
          <MessageButton>
            {t('common.messages')}

            <IconMessage />
          </MessageButton>
        </Col>
      </Row>

      <DataGrid>
        <ModalH3>{client.data?.company_name}</ModalH3>
        <DataEntry label={t('label.category')} text={client.data?.category} />
        <DataEntry label={t('label.legal_code')} text={client.data?.legal_code} />
        <DataEntry label={t('label.vat')} text={client.data?.vat ?? ''} />
        <DataEntry
          label={t('label.country')}
          text={client.data && countries.data ? countries.data[client.data.country] : ''}
        />
        <DataEntry label={t('label.city')} text={client.data?.city} />
        <DataEntry label={t('label.address')} text={client.data?.address} />
        <DataEntry label={t('label.email')} text={client.data?.email} />
        <DataEntry label={t('label.mobile')} text={client.data?.mobile} />
      </DataGrid>

      <hr />

      <DataGrid>
        <ModalH3>{t('label.responsible_person')}</ModalH3>
        <DataEntry label={t('label.name')} text={client.data?.user?.name} />
        <DataEntry label={t('label.surname')} text={client.data?.user?.surname} />
        <DataEntry label={t('label.position')} text={client.data?.user?.position.join(', ')} />
        <DataEntry label={t('label.email')} text={client.data?.user?.email} />
        <DataEntry label={t('label.mobile')} text={client.data?.user?.mobile} />
      </DataGrid>

      {props.clientId && (
        <ClientEditModal
          clientId={props.clientId}
          setIsOpen={setIsEditModalOpen}
          isOpen={isEditModalOpen}
          toggle={toggleEditModal}
          updateInfoModal={handleUpdateInfoModal}
        />
      )}

      <ConfirmActionModal
        setIsOpen={setConfirmModalOpen}
        isOpen={isConfirmModalOpen}
        toggle={toggleConfirmModal}
        onConfirm={onConfirm}
      />
    </Modal>
  );
};

export default withLoader(ClientInfoModal);
