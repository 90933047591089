import styled from 'styled-components';

export const StyledPrivacyPolicy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: justify;

  .btn-secondary {
    margin-bottom: 1rem;
  }
`;
