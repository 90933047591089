import { useNavigate } from 'react-router-dom';
import { Api, Http } from 'api';
import usePermissions from 'helpers/usePermissions';
import { useAppState } from 'modules/appState';
import { RouteList } from 'routeList';
import { LoginUserResponse } from 'types/types';
import { isAuthJwtObject } from './Auth.guards';
import { AuthJwtObject, jwtStorageKey } from './Auth.types';
import { AuthUtils } from './Auth.utils';

const useAuth = () => {
  const { setAppStarted, setClient } = useAppState();
  const { setPermissions, resetPermissions } = usePermissions();

  const navigate = useNavigate();

  const startSession = async (jwt?: string | AuthJwtObject, permissions?: string[]) => {
    const token = !jwt ? AuthUtils.getJwt() : isAuthJwtObject(jwt) ? jwt : AuthUtils.make(jwt);

    Http.setBearer(token.token);
    AuthUtils.setJwt(token.token);

    permissions && setPermissions(permissions);

    await Api.user.profile.fetch().then(setClient);
  };

  const endSession = async () => {
    setAppStarted(false);

    try {
      Http.removeBearer();
      AuthUtils.removeJwt();
      resetPermissions();
    } finally {
      setAppStarted(true);
      setClient(null);

      navigate(RouteList.AUTHENTICATION.LOGIN.path);
    }
  };

  const login = async (access_token: string, permissions: string[]): Promise<void> => {
    setAppStarted(false);

    try {
      await startSession(access_token, permissions);

      navigate(RouteList.DASHBOARD.path);
    } finally {
      setAppStarted(true);
    }
  };

  const logout = async (logout: () => Promise<void>): Promise<void> => {
    logout();

    await endSession();
  };

  const refresh = async (promise: () => Promise<LoginUserResponse>) => {
    try {
      // No token
      if (AuthUtils.getPayload() === null) {
        return;
      }

      if (AuthUtils.isExpired()) {
        await endSession();

        return;
      }

      Http.setBearer(AuthUtils.getJwt().token);
      const response = await promise();

      await startSession(response.access_token, response.permissions);
    } finally {
      setAppStarted(true);
    }
  };

  const handleStorageTokenChange = async (e: StorageEvent): Promise<void> => {
    if (e.key !== jwtStorageKey) {
      return;
    }

    if (e.oldValue && !e.newValue) {
      // Means user has logged out
      await endSession();

      return;
    }

    const newJwt = AuthUtils.make(e.newValue ?? '');

    if (!isAuthJwtObject(newJwt)) {
      await endSession();

      return;
    }

    if (!e.oldValue) {
      // Means user has logged in
      await startSession();

      return;
    }

    if (e.oldValue && e.newValue) {
      // Means token has been refreshed, probably
      const oldJwt = AuthUtils.make(e.oldValue);
      if (
        isAuthJwtObject(oldJwt) &&
        !AuthUtils.isExpired(newJwt) &&
        oldJwt.token !== newJwt.token
      ) {
        await startSession(newJwt);
      }
    }
  };

  const mount = () => window.addEventListener('storage', handleStorageTokenChange);

  const dismount = () => window.removeEventListener('storage', handleStorageTokenChange);

  return {
    login,
    logout,
    refresh,
    startSession,
    endSession,
    mount,
    dismount,
  };
};
export { useAuth };
