import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  BaseModalProps,
  Label,
  Modal,
  PasswordInput,
  PrimaryButton,
  PrimarySpinner,
  success,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { ChangePasswordRequest } from 'api/types/user/profile';
import { StyledButtonWrapper, StyledInput } from './ChangePasswordModal.style';

const ChangePasswordModal: React.FC<BaseModalProps> = (props) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);

  const [formData] = useState<ChangePasswordRequest>({
    current_password: '',
    password: '',
    password_confirmation: '',
  });

  const FormSchema = Yup.object().shape({
    current_password: Yup.string().required(),
    password: Yup.string().required(),
    password_confirmation: Yup.string().required(),
  });

  const onSubmit = useCallback(
    async (request: ChangePasswordRequest, helpers: FormikHelpers<ChangePasswordRequest>) => {
      setLoading(true);
      let didCatchError = false;

      try {
        await Api.user.profile.changePassword(request);
      } catch (e: any) {
        didCatchError = true;
        helpers.setErrors(e.response?.errors);
      } finally {
        if (!didCatchError) {
          success(t('auth.change_password.change_success'));
          props.setIsOpen(false);
        }
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal {...props} title={t('auth.change_password.title')}>
      <Formik onSubmit={onSubmit} initialValues={formData} validationSchema={FormSchema}>
        {({ handleSubmit, handleChange, values }) => (
          <>
            <StyledInput>
              <Label
                htmlFor={'current_password'}
                text={t('auth.change_password.label_current_password')}
                required={true}
              />

              <PasswordInput
                id={'current_password'}
                name={'current_password'}
                placeholder={t('auth.change_password.placeholder_current_password')}
                onChange={handleChange}
                value={values.current_password}
              />

              <ErrorMessage component={'div'} className={'text-danger'} name={'current_password'} />
            </StyledInput>

            <StyledInput>
              <Label
                htmlFor={'password'}
                text={t('auth.change_password.label_new_password')}
                required={true}
              />

              <PasswordInput
                id={'password'}
                name={'password'}
                placeholder={t('auth.change_password.placeholder_new_password')}
                onChange={handleChange}
                value={values.password}
              />

              <ErrorMessage component={'div'} className={'text-danger'} name={'password'} />
            </StyledInput>

            <StyledInput>
              <Label
                htmlFor={'password_confirmation'}
                text={t('auth.change_password.label_confirm_password')}
                required={true}
              />

              <PasswordInput
                id={'password_confirmation'}
                name={'password_confirmation'}
                placeholder={t('auth.change_password.placeholder_confirm_password')}
                onChange={handleChange}
                value={values.password_confirmation}
              />

              <ErrorMessage
                component={'div'}
                className={'text-danger'}
                name={'password_confirmation'}
              />
            </StyledInput>

            <StyledButtonWrapper>
              <PrimaryButton onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <PrimarySpinner /> : t('common.save')}
              </PrimaryButton>
            </StyledButtonWrapper>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;
