import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormikHelpers } from 'formik';
import { BadgeSelect } from '@btc-snxt/ui';
import { Api } from 'api';
import { useRolesQuery } from 'api/queries/role/useRolesQuery';
import { CreateUserRequest } from 'api/types/user/user';
import { Col, FormModal, BaseModalProps, Row, success, Label } from '@btc-snxt/ui';
import { BodyWrapper } from './AddUserModal.style';

interface Props extends BaseModalProps {
  handleAdd?: () => void;
}

type Option = { label: string; value: any };

const AddUserModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();

  const roles = useRolesQuery();

  const options: Option[] =
    roles.data?.map((v) => ({
      value: v.name,
      label: v.name,
    })) ?? [];

  const [formData, setFormData] = useState<CreateUserRequest>({
    name: '',
    surname: '',
    position: [''],
    email: '',
    mobile: '',
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
    surname: Yup.string().required(),
    position: Yup.array().required(),
    email: Yup.string().email().required(),
    mobile: Yup.string().required(),
  });

  const clearValues = () => {
    setFormData({
      name: '',
      surname: '',
      position: [''],
      email: '',
      mobile: '',
    });
  };

  const handleSubmit = async <T extends CreateUserRequest>(values: T, helper: FormikHelpers<T>) => {
    helper.setSubmitting(true);
    try {
      await Api.user.user.add(values);
      props.setIsOpen(false);
      props.handleAdd && props.handleAdd();
      success(t('user.add_success'));
      clearValues();
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.add_user')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
    >
      <BodyWrapper>
        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />

            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'surname'} text={t('label.surname')} required={true} />

            <Field
              type={'text'}
              name={'surname'}
              id={'surname'}
              placeholder={t('label.surname')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'surname'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'position'} text={t('label.position')} required={true} />

            <Field
              name={'position'}
              id={'position'}
              as={BadgeSelect}
              options={options}
              isMulti
              defaultValue={formData.position}
              placeholder={t('label.position')}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'position'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'email'} text={t('label.email')} required={true} />

            <Field
              type={'email'}
              name={'email'}
              id={'email'}
              placeholder={t('label.email')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'email'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'mobile'} text={t('label.mobile')} required={true} />

            <Field
              type={'text'}
              name={'mobile'}
              id={'mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'mobile'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default AddUserModal;
