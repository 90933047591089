import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionAnswersFilter } from '@btc-snxt/graph';
import { FixedCenteredSpinner, error } from '@btc-snxt/ui';
import { Api } from 'api';
import { MainQuestions } from 'api/types/filter/filter';
import { ExportCategoryNodesRequest } from 'api/types/survey';
import { DataForSurveyController } from 'pages/Common/ResultAnalysis/ResultAnalysis';
import RenderGraph from 'pages/Common/ResultAnalysis/helpers';
import { StyledGraphWrapper } from 'pages/Common/ResultAnalysis/helpers.style';
import { IChartSelect, BarsChartShowSelect } from 'pages/Common/ResultAnalysis/types';

export interface Props {
  data: DataForSurveyController;
}

const CustomerEffortController: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<MainQuestions[]>([]);
  const [questionFilters, setQuestionFilters] = useState<QuestionAnswersFilter>();
  const [selectedBarsChartShowTypes, setSelectedBarsChartShowTypes] = useState<IChartSelect[]>([]);
  const [exportCategoryNodes, setExportCategoryNodes] = useState<ExportCategoryNodesRequest>(
    () => ({
      categoryNodes: props.data.filters.map((filter) => ({
        id: filter.toString(),
      })),
    }),
  );

  const singleSelectTypes: IChartSelect[] = Object.values(BarsChartShowSelect).map((value) => ({
    value: value,
    label: t(`graph.enum.${value}`),
  }));

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Api.filter.filter
        .sendFilters(props.data)
        .then((data) => {
          setFilteredData(data.questions);
          setSelectedBarsChartShowTypes(
            data.questions.map(() => ({
              value: BarsChartShowSelect.Average,
              label: t(`graph.enum.${BarsChartShowSelect.Average}`),
            })),
          );
        })
        .catch(() => error(t('common.error')))
        .finally(() => setLoading(false));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (questionFilters) {
      setFilters((prevState) => {
        const newState = [...prevState];

        if (newState.find((v) => v === questionFilters.questionAnswersFilter.id)) {
          newState.splice(newState.indexOf(questionFilters.questionAnswersFilter.id ?? ''), 1);
        } else if (questionFilters.questionAnswersFilter.id) {
          newState.push(questionFilters.questionAnswersFilter.id);
        }

        return newState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionFilters]);

  useEffect(() => {
    setSelectedBarsChartShowTypes((prevState) =>
      prevState.map((type) => ({
        ...type,
        label: t(`graph.enum.${type.value}`),
      })),
    );
  }, [t]);

  useEffect(() => {
    setExportCategoryNodes({
      categoryNodes: props.data.filters.map((filter) => ({
        id: filter.toString(),
      })),
    });
  }, [props.data.filters]);

  const submitAnswerFilters = async (): Promise<void> => {
    setLoading(true);
    await Api.filter.filter
      .sendFilters({
        ...props.data,
        questionAnswersFilter: filters,
      })
      .then((response) => setFilteredData(response.questions))
      .finally(() => setLoading(false));
  };

  const renderResultsBasedOnType = (questions: MainQuestions, index: number): JSX.Element => (
    <RenderGraph
      questionIndex={index}
      questions={questions}
      questionAnswersFilter={props.data.questionAnswersFilter}
      setQuestionFilters={setQuestionFilters}
      chartSelectTypes={singleSelectTypes}
      selectedBarsChartShowTypes={selectedBarsChartShowTypes}
      setSelectedBarsChartShowTypes={setSelectedBarsChartShowTypes}
      exportCategoryNodes={exportCategoryNodes}
      filters={filters}
      handleApplyFilters={submitAnswerFilters}
    />
  );

  return (
    <StyledGraphWrapper>
      {loading ? (
        <FixedCenteredSpinner />
      ) : (
        Object.values(filteredData).map((value, index) => (
          <Fragment key={index}>{renderResultsBasedOnType(value, index)}</Fragment>
        ))
      )}
    </StyledGraphWrapper>
  );
};

export default CustomerEffortController;
