import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  BaseModalProps,
  DropdownItemWrapper,
  FormModal,
  LoadingState,
  withLoader,
  PrimaryButton,
  Switch,
  TranslatableInput,
  Label,
  Locale,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { UpdateMembershipRequest } from 'api/types/membership/membership';
import {
  useMembershipSettingsContext,
  useMembershipContext,
} from 'pages/Common/Settings/tabs/MembershipSettings/MembershipSettingsContext';
import Icons from 'pages/Common/Settings/tabs/MembershipSettings/services/icon';
import { MembershipBenefitsForm } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipBenefitsForm';
import { MembershipDropdown } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipDropdown';
import { MembershipIconsModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipIconsModal';
import {
  StyledBenefitsLabel,
  StyledDropdown,
  StyledInput,
  StyledInputRow,
  StyledLabelInput,
  StyledModalBody,
  StyledSelectedIconButton,
  StyledCircle,
  StyledIconButtonRow,
  StyledSwitch,
  StyledSwitchLabel,
  StyledPricesBlock,
} from './MembershipEditModal.style';

const MembershipEditModal: React.FC<BaseModalProps & LoadingState> = (props) => {
  const { t, i18n } = useTranslation();
  const [inputsLocale, setInputsLocale] = useState<string>(i18n.language);

  const currentMembershipList = useMembershipSettingsContext();
  const currentMembership = useMembershipContext();

  const [isIconsModalOpen, setIsIconsModalOpen] = useState<boolean>(false);
  const toggleIconsModal = () => setIsIconsModalOpen(!isIconsModalOpen);

  const membershipIcons = new Icons();

  const initialTranslatableInput = { en: '', lt: '', ru: '' };

  const [formData, setFormData] = useState<UpdateMembershipRequest>({
    name: initialTranslatableInput,
    description: initialTranslatableInput,
    yearly_price: 0,
    semi_yearly_price: 0,
    quarterly_price: 0,
    monthly_price: 0,
    is_active: false,
    benefits: [
      {
        labels: initialTranslatableInput,
        is_active: false,
      },
    ],
    icon_id: 0,
  });

  // TODO: add yup method which if value is traslatable, i.e.,  name: yupmethod(),
  const FormSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required(),
      lt: Yup.string().required(),
      ru: Yup.string().required(),
    }),
    description: Yup.object().shape({
      en: Yup.string().required(),
      lt: Yup.string().required(),
      ru: Yup.string().required(),
    }),
    yearly_price: Yup.number().required(),
    semi_yearly_price: Yup.number().required(),
    quarterly_price: Yup.number().required(),
    monthly_price: Yup.number().required(),
    is_active: Yup.boolean().required(),
    benefits: Yup.array()
      .of(
        Yup.object().shape({
          labels: Yup.object().shape({
            en: Yup.string().required(),
            lt: Yup.string().required(),
            ru: Yup.string().required(),
          }),
          is_active: Yup.boolean().required(),
        }),
      )
      .required(),
    icon_id: Yup.number().required(),
  });

  const handleOpen = async () => {
    try {
      props.setIsLoading(true);

      const data = currentMembershipList.data.find(
        (membership) => membership.id == currentMembership.id,
      );
      data && setFormData(data);
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleSubmit = async <T extends UpdateMembershipRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    helper.setSubmitting(true);
    try {
      await Api.membership.membership.update(currentMembership.id, values);
    } finally {
      currentMembershipList.setData((prevState) => {
        return prevState.map((membership) =>
          membership.id == currentMembership.id
            ? { ...values, id: currentMembership.id }
            : membership,
        );
      });

      props.setIsOpen(false);
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      onOpened={handleOpen}
      title={t('membership.edit_title')}
      initialValues={formData}
      validationSchema={FormSchema}
      handleSubmit={handleSubmit}
      components={{
        Footer: ({ isSubmitting, values, setFieldValue }) => {
          return (
            <>
              <StyledSwitch>
                <StyledSwitchLabel>
                  {values.is_active ? t('membership.form.active') : t('membership.form.inactive')}
                </StyledSwitchLabel>

                <Switch
                  checked={values.is_active}
                  onChange={() => {
                    setFieldValue('is_active', !values.is_active);
                  }}
                />
              </StyledSwitch>

              <PrimaryButton disabled={isSubmitting} type={'submit'}>
                {isSubmitting ? `${t('common.loading')}` : t('common.save')}
              </PrimaryButton>
            </>
          );
        },
      }}
    >
      {({ values }: FormikProps<UpdateMembershipRequest>) => (
        <StyledModalBody>
          <StyledInputRow>
            <StyledLabelInput>
              <Label
                htmlFor={`name.${inputsLocale}`}
                text={t('membership.form.title')}
                required={true}
              />

              <Field
                as={TranslatableInput}
                locale={inputsLocale}
                name={`name`}
                id={`name.${inputsLocale}`}
                placeholder={t('membership.form.title')}
                className={'form-control'}
                maxLength={16}
              />

              <ErrorMessage
                component={'div'}
                className={'text-danger'}
                name={`name.${inputsLocale}`}
              />
            </StyledLabelInput>

            <StyledDropdown>
              <Label text={t('membership.form.locale')} required={true} />

              <MembershipDropdown currentText={inputsLocale}>
                {Object.values(Locale).map((locale, index) => (
                  <DropdownItemWrapper key={index} onClick={() => setInputsLocale(locale)}>
                    {locale}
                  </DropdownItemWrapper>
                ))}
              </MembershipDropdown>
            </StyledDropdown>
          </StyledInputRow>

          <StyledInputRow>
            <StyledInput>
              <Label
                htmlFor={`description.${inputsLocale}`}
                text={t('membership.form.description')}
                required={true}
              />

              <Field
                as={TranslatableInput}
                locale={inputsLocale}
                name={`description`}
                id={`description.${inputsLocale}`}
                placeholder={t('membership.form.description')}
                className={'form-control'}
              />

              <ErrorMessage
                component={'div'}
                className={'text-danger'}
                name={`description.${inputsLocale}`}
              />
            </StyledInput>
          </StyledInputRow>

          <StyledInputRow>
            <StyledIconButtonRow>
              <StyledSelectedIconButton type={'button'} onClick={toggleIconsModal}>
                <StyledCircle>{membershipIcons.getIcon(values.icon_id)?.icon}</StyledCircle>
              </StyledSelectedIconButton>
            </StyledIconButtonRow>
          </StyledInputRow>

          <StyledPricesBlock>
            <StyledInputRow>
              <StyledInput>
                <Label
                  htmlFor={'monthly_price'}
                  text={t('membership.form.monthly_price')}
                  required={true}
                />

                <Field
                  type={'text'}
                  name={`monthly_price`}
                  id={`monthly_price`}
                  placeholder={t('membership.form.monthly_price')}
                  className={'form-control'}
                />

                <ErrorMessage component={'div'} className={'text-danger'} name={`monthly_price`} />
              </StyledInput>

              <StyledInput>
                <Label
                  htmlFor={'quarterly_price'}
                  text={t('membership.form.quarterly_price')}
                  required={true}
                />

                <Field
                  type={'text'}
                  name={`quarterly_price`}
                  id={`quarterly_price`}
                  placeholder={t('membership.form.quarterly_price')}
                  className={'form-control'}
                />

                <ErrorMessage
                  component={'div'}
                  className={'text-danger'}
                  name={`quarterly_price`}
                />
              </StyledInput>
            </StyledInputRow>

            <StyledInputRow>
              <StyledInput>
                <Label
                  htmlFor={'yearly_price'}
                  text={t('membership.form.yearly_price')}
                  required={true}
                />

                <Field
                  type={'text'}
                  name={`yearly_price`}
                  id={`yearly_price`}
                  placeholder={t('membership.form.yearly_price')}
                  className={'form-control'}
                />

                <ErrorMessage component={'div'} className={'text-danger'} name={`yearly_price`} />
              </StyledInput>

              <StyledInput>
                <Label
                  htmlFor={'semi_yearly_price'}
                  text={t('membership.form.semi_yearly_price')}
                  required={true}
                />

                <Field
                  type={'text'}
                  name={`semi_yearly_price`}
                  id={`semi_yearly_price`}
                  placeholder={t('membership.form.semi_yearly_price')}
                  className={'form-control'}
                />

                <ErrorMessage
                  component={'div'}
                  className={'text-danger'}
                  name={`semi_yearly_price`}
                />
              </StyledInput>
            </StyledInputRow>
          </StyledPricesBlock>

          <StyledBenefitsLabel>{t('membership.form.benefits')}</StyledBenefitsLabel>

          <MembershipBenefitsForm inputsLocale={inputsLocale} />

          <MembershipIconsModal
            isOpen={isIconsModalOpen}
            setIsOpen={setIsIconsModalOpen}
            toggle={toggleIconsModal}
          />
        </StyledModalBody>
      )}
    </FormModal>
  );
};

export default withLoader(MembershipEditModal);
