import { Input } from '@btc-snxt/ui';
import styled from 'styled-components';

export const StyledShareLink = styled.div`
  width: 80%;
  margin-top: 2rem;
  font-size: 1.25rem;
`;

export const ShareLinkWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const StyledShareLinkItem = styled(Input)`
  width: 20rem;
  min-width: 10rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
`;

export const StyledSuccessMessage = styled.div`
  display: flex;
  gap: 0.5rem;
`;
