import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseModalProps,
  IconAdd,
  IconClose,
  LoadingState,
  Modal,
  PrimaryButton,
  withLoader,
} from '@btc-snxt/ui';
import { useMembershipContext } from 'pages/Common/Settings/tabs/MembershipSettings/MembershipSettingsContext';
import Icons from 'pages/Common/Settings/tabs/MembershipSettings/services/icon';
import {
  StyledDetailsBody,
  StyledIcon,
  StyledName,
  StyledCircle,
  StyledMonthlyPriceBlock,
  StyledMonthlyPrice,
  StyledMonthlyPriceText,
  StyledDescription,
  StyledYearlyPrice,
  StyledFeaturesBlock,
  StyledFeaturesTitle,
  StyledFeature,
  StyledFeaturesWrapper,
} from './MembershipDetailsModal.style';

const MembershipDetailsModal: React.FC<BaseModalProps & LoadingState> = (props) => {
  const { t, i18n } = useTranslation();

  const currentMembership = useMembershipContext();

  const membershipIcons = new Icons();

  return (
    <Modal
      {...props}
      title={t('membership.details_title')}
      size={'sm'}
      components={{
        Footer: () => {
          return <PrimaryButton onClick={props.toggle}>{t('common.close')}</PrimaryButton>;
        },
      }}
    >
      <StyledDetailsBody>
        <StyledName>{currentMembership.name[i18n.language]}</StyledName>

        <StyledIcon>
          <StyledCircle>{membershipIcons.getIcon(currentMembership.icon_id)?.icon}</StyledCircle>
        </StyledIcon>

        <StyledMonthlyPriceBlock>
          <StyledMonthlyPrice>€{currentMembership.monthly_price}</StyledMonthlyPrice>

          <StyledMonthlyPriceText>{t('membership.price_month')}</StyledMonthlyPriceText>
        </StyledMonthlyPriceBlock>

        <StyledYearlyPrice>
          €{currentMembership.yearly_price} {t('membership.price_year')}
        </StyledYearlyPrice>

        <StyledDescription>{currentMembership.description[i18n.language]}</StyledDescription>

        <StyledFeaturesBlock>
          <StyledFeaturesTitle>{t('membership.plan_features')}</StyledFeaturesTitle>

          <StyledFeaturesWrapper>
            {currentMembership.benefits.map((benefit, index) => (
              <StyledFeature key={index}>
                {benefit.is_active ? <IconAdd /> : <IconClose />}
                {benefit.labels[i18n.language]}
              </StyledFeature>
            ))}
          </StyledFeaturesWrapper>
        </StyledFeaturesBlock>
      </StyledDetailsBody>
    </Modal>
  );
};

export default withLoader(MembershipDetailsModal);
