import styled from 'styled-components';
import { breakpointDown } from '@btc-snxt/ui';

export const StyledDashboard = styled.div`
  display: flex;

  ${breakpointDown.LG} {
    flex-direction: column;
  }
`;

export const StyledFirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

export const StyledSecondBlock = styled.div`
  flex: 3 0 0;
`;

export const StyledSmallBlock = styled.div`
  display: flex;

  ${breakpointDown.SM} {
    flex-direction: column;
  }
`;
