import React from 'react';
import { useTranslation } from 'react-i18next';
import { H1 } from '@btc-snxt/ui';
import { StyledNotFoundPage } from './NotFound.style';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledNotFoundPage>
      <H1>{t('common.page_not_found')}</H1>
    </StyledNotFoundPage>
  );
};

export default NotFound;
