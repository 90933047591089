import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconCertificate,
  IconFindStatistics,
  IconGroup,
  IconRightTime,
  ProgressBar,
} from '@btc-snxt/ui';
import { DashboardCalendar } from './components/DashboardCalendar';
import { DashboardCard } from './components/DashboardCard';
import { StyledBigNumber } from './components/DashboardCard/DashboardCard.style';
import { useGenericLayoutStore } from 'layouts/GenericLayout/GenericLayout.store';
import {
  StyledDashboard,
  StyledFirstBlock,
  StyledSecondBlock,
  StyledSmallBlock,
} from './Dashboard.style';
import { useDashboardQuery } from 'api/queries/dashboard/useDasboardQuery';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const sidebarState = useGenericLayoutStore();

  const [startDate, setStartDate] = useState<Date | null>(() => {
    const date = new Date();
    date.setMonth(new Date().getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const { data } = useDashboardQuery([startDate, endDate], {
    start_date: startDate,
    end_date: endDate,
  });

  useEffect(() => {
    sidebarState.setIsSidebarOpen({ state: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDashboard>
      <StyledFirstBlock>
        <DashboardCard title={t('dashboard.today_responses')} icon={<IconRightTime />} size={'md'}>
          <ProgressBar currentValue={data?.todayResponses ?? 0} max={200} />
        </DashboardCard>

        <DashboardCard
          title={t('dashboard.total_responses')}
          extraText={t('dashboard.total_responses_bottom')}
          icon={<IconGroup />}
          size={'md'}
        >
          <ProgressBar currentValue={data?.totalResponses ?? 0} max={1000} />
        </DashboardCard>
      </StyledFirstBlock>

      <StyledSecondBlock>
        <DashboardCard
          title={t('dashboard.filter')}
          extraText={t('dashboard.filter_bottom')}
          size={'lg'}
        >
          <DashboardCalendar
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </DashboardCard>

        <StyledSmallBlock>
          <DashboardCard
            title={t('dashboard.active_surveys')}
            extraText={t('dashboard.active_surveys_bottom')}
            icon={<IconCertificate />}
            size={'sm'}
          >
            <StyledBigNumber>{data?.activeSurveys ?? 0}</StyledBigNumber>
          </DashboardCard>

          <DashboardCard
            title={t('dashboard.active_surveys_period')}
            extraText={t('dashboard.active_surveys_period_bottom')}
            icon={<IconFindStatistics />}
            size={'sm'}
          >
            <StyledBigNumber>{data?.activeSurveysPeriod ?? 0}</StyledBigNumber>
          </DashboardCard>
        </StyledSmallBlock>
      </StyledSecondBlock>
    </StyledDashboard>
  );
};

export default Dashboard;
