import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { moveItemInArray, SurveyItemType, TemplateItem } from '@btc-snxt/survey';
import { Api } from 'api';
import { SurveySelectResponse } from 'api/types/survey';
import { convertStringToApiFriendly } from 'helpers/common';
import { SurveyEditorMode, SurveyStatus } from 'helpers/enums';
import { SurveyType } from '@btc-snxt/survey';
import { SurveyEditorContext, SurveyEditorContextProps } from './SurveyEditorContext';
import { DesignMode, PreviewMode, ShareMode } from './modes';
import { getTemplateSettings, getUrl } from './SurveyEditor.utils';
import { EmailItem } from './modes/ShareMode/shareMethods/ShareByEmail/AddContactsCSV';
import {
  StyledBody,
  StyledButtons,
  StyledHeaderButton,
  StyledTitle,
  StyleSurveyEditor,
} from './SurveyEditor.style';
import { FixedCenteredSpinner, Locale } from '@btc-snxt/ui';

const SurveyEditor: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, mode, surveyId } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [surveyType, setSurveyType] = useState<SurveyType>(
    type ? (convertStringToApiFriendly(type) as SurveyType) : SurveyType.IndividualSurvey,
  );

  const [surveyEditorMode, setSurveyEditorMode] = useState<SurveyEditorMode>(
    (mode as SurveyEditorMode) ?? SurveyEditorMode.Design,
  );

  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>(SurveyStatus.Draft);
  const [category, setCategory] = useState<SurveySelectResponse>();
  const [items, setItems] = useState<TemplateItem[]>(getTemplateSettings(surveyType).items);
  const [color, setColor] = useState<string>('#0082b8');
  const [receivers, setReceiversList] = useState<EmailItem[]>([]);
  const [logo, setSurveyLogo] = useState<string>('');
  const [emailLogo, setSurveyEmailLogo] = useState<string>('');
  const [logoObject, setSurveyLogoObject] = useState<any>({});
  const [name, setGlobalName] = useState<string>(location.state?.name);
  const [availableLanguages, setAvailableLanguages] = useState<Array<string>>([Locale.LT]);
  const [isAvailableLanguegesLoading, setAvailableLanguegesLoading] = useState<boolean>(!!surveyId);
  const [isfetching, setIsFetching] = useState<boolean>(!!surveyId);

  const fetchSurvey = async (surveyId: string) => {
    const data = await Api.survey.survey.getSurveyToUpdate(surveyId);
    let category = undefined;
    if (data.category !== undefined) {
      category = data.category;
      category.value = category.name;
      category.label = category.name;
    }

    const survey = data.survey;

    if (survey.questions === undefined) {
      return {};
    }

    const fetchedSurveyItems = survey.questions;
    const surveyItems: TemplateItem[] = [];

    fetchedSurveyItems.forEach((item: any) => {
      const surveyItem: TemplateItem = {
        id: item.id,
        type: item.type,
        initialValues: item.design_traits,
        values: item.design_traits,
      };
      surveyItems.push(surveyItem);
    });
    setAvailableLanguegesLoading(false);
    setIsFetching(false);
    return {
      items: surveyItems,
      status: survey.status as SurveyStatus,
      category: category,
      color: surveyItems[0].initialValues.color,
      logo: data.logo ? data.logo : '',
      emailLogo: data.emailLogo ? data.emailLogo : '',
      name: data.survey.name ?? '',
      availableLanguages: survey.available_languages,
    };
  };

  useEffect(() => {
    if (surveyId) {
      (async () => {
        try {
          const { status, items, category, color, logo, emailLogo, name, availableLanguages } =
            await fetchSurvey(surveyId);

          setItems(items as TemplateItem[]);
          setSurveyStatus(status as SurveyStatus);
          setCategory(category);
          setColor(color);
          setSurveyEmailLogo(emailLogo);
          setSurveyLogo(logo);
          setGlobalName(name);
          setAvailableLanguages(availableLanguages);
        } catch (e: any) {
          const url = getUrl(surveyEditorMode, surveyType, undefined);
          navigate(url);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const state = useMemo<SurveyEditorContextProps>(() => {
    const findItemIndex = (id: string): number => {
      return items.findIndex((x) => x.id === id);
    };

    const setItemType = (id: string, type: SurveyItemType) => {
      const index = findItemIndex(id);
      if (index === -1) {
        return;
      }

      const newItems = [...items];
      newItems[index].type = type;
      newItems[index].initialValues = undefined;
      newItems[index].values = undefined;
      setItems(newItems);
    };

    const addItem = (item: TemplateItem, index: number) => {
      const newItems = [...items];
      newItems.splice(index, 0, item);
      setItems(newItems);
    };

    const setGlobalColor = (hex: string) => {
      setColor(hex);
    };

    const setName = (name: string) => {
      setGlobalName(name);
    };

    const setReceivers = (emails: EmailItem[]) => {
      setReceiversList(emails);
    };

    const setLogoObject = (files: any) => {
      setSurveyLogoObject(files);
    };

    const setLogo = async (files: any) => {
      if (files == '') {
        setSurveyLogo('');
      }
    };

    const setEmailLogo = (files: any) => {
      setSurveyEmailLogo(files);
    };

    const onValuesChanged = (id: string, values: any, color: string) => {
      const index = findItemIndex(id);
      if (index === -1) {
        return;
      }
      items[index].values = values;
      items[index].values.color = color;
    };

    const onMoveUp = (id: string) => {
      const index = findItemIndex(id);
      if (index === -1) {
        return;
      }

      const newItems = moveItemInArray(items, index, index - 1);
      setItems(newItems);
    };

    const onMoveDown = (id: string) => {
      const index = findItemIndex(id);
      if (index === -1) {
        return;
      }

      const newItems = moveItemInArray(items, index, index + 1);
      setItems(newItems);
    };

    const onRemove = (id: string) => {
      const index = findItemIndex(id);
      if (index === -1) {
        return;
      }

      const newItems = [...items];
      newItems.splice(index, 1);
      setItems(newItems);
    };

    return {
      id: surveyId,
      mode: surveyEditorMode,
      setMode: setSurveyEditorMode,
      status: surveyStatus,
      surveyType: surveyType,
      setStatus: setSurveyStatus,
      category,
      setCategory,
      isEditable: getTemplateSettings(surveyType).isEditable,
      items: items,
      setItems,
      addItem,
      setItemType,
      onValuesChanged,
      onMoveUp,
      onMoveDown,
      onRemove,
      setGlobalColor,
      color: color,
      receivers,
      setReceivers,
      emailLogo,
      setEmailLogo,
      logo,
      setLogo,
      setLogoObject,
      logoObject,
      setName,
      name,
      availableLanguages,
      setAvailableLanguages,
      isAvailableLanguegesLoading,
      setAvailableLanguegesLoading,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    surveyId,
    surveyEditorMode,
    surveyStatus,
    surveyType,
    category,
    items,
    color,
    receivers,
    logo,
    logoObject,
    emailLogo,
    name,
    availableLanguages,
  ]);

  const renderButton = (buttonText: string, surveyMode: SurveyEditorMode, disabled = false) => (
    <StyledHeaderButton
      onClick={() => {
        setSurveyEditorMode(surveyMode);
        const url = getUrl(surveyMode, surveyType, surveyId);
        navigate(url);
      }}
      active={mode == surveyMode}
      disabled={disabled}
    >
      {buttonText}
    </StyledHeaderButton>
  );

  const renderEditor = (mode: SurveyEditorMode) => {
    switch (mode) {
      case SurveyEditorMode.Design:
        return <DesignMode />;
      case SurveyEditorMode.Preview:
        return <PreviewMode />;
      case SurveyEditorMode.Share:
        return <ShareMode />;
    }
  };

  return (
    <>
      {isfetching ? (
        <FixedCenteredSpinner />
      ) : (
        <>
          <StyleSurveyEditor>
            <StyledTitle>
              {t(`survey.type.${surveyType}`)}
              {name ? `: ${name}` : ''}
            </StyledTitle>

            <StyledButtons>
              {renderButton(
                t('survey.create.first_header'),
                SurveyEditorMode.Design,
                surveyStatus != SurveyStatus.Draft,
              )}
              {renderButton(t('survey.create.second_header'), SurveyEditorMode.Preview)}
              {renderButton(
                t('survey.create.third_header'),
                SurveyEditorMode.Share,
                surveyId == undefined,
              )}
            </StyledButtons>

            <StyledBody>
              <SurveyEditorContext.Provider value={state}>
                {renderEditor(surveyEditorMode)}
              </SurveyEditorContext.Provider>
            </StyledBody>
          </StyleSurveyEditor>
        </>
      )}
    </>
  );
};

export default SurveyEditor;
