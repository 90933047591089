import Client from '../client';
import {
  CreateRoleRequest,
  UpdateRoleRequest,
  RoleResponse,
  PermissionListResponse,
  RoleMatrixResponse,
  UpdateRolesPermissionsRequest,
} from 'api/types/role/role';
import { PaginationData, PaginationDataFilter } from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';

class Role extends Client {
  api = {
    ROLE_LIST: `${this.http.baseUrl}/roles`,
    ROLE_CREATE: `${this.http.baseUrl}/roles/role`,
    ROLE_LIST_FILTER: `${this.http.baseUrl}/roles/filter`,
    ROLE: `${this.http.baseUrl}/roles/role/{role}`,
    PERMISSIONS: `${this.http.baseUrl}/roles/permissions`,
    UPDATE_ROLES_PERMISSIONS: `${this.http.baseUrl}/roles/permissions/permission`,
  };

  filterRoles = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<RoleResponse>> => {
    return this.http.get(this.api.ROLE_LIST_FILTER, { params: request });
  };

  add = (data: CreateRoleRequest): Promise<RoleResponse> => {
    return this.http.post(this.api.ROLE_CREATE, data);
  };

  fetch = (role: string): Promise<RoleResponse> => {
    return this.http.get(this.buildUrl(this.api.ROLE, { role }));
  };

  edit = (role: string, data: UpdateRoleRequest): Promise<RoleResponse> => {
    return this.http.put(this.buildUrl(this.api.ROLE, { role }), data);
  };

  delete = (role: string): Promise<EmptyResponse> => {
    return this.http.delete(this.buildUrl(this.api.ROLE, { role }));
  };

  updateRolesPermissions = (request: UpdateRolesPermissionsRequest): Promise<any> => {
    return this.http.post(this.api.UPDATE_ROLES_PERMISSIONS, request);
  };

  fetchRoles = (): Promise<RoleMatrixResponse[]> => {
    return this.http.get(this.api.ROLE_LIST);
  };

  fetchPermissions = (): Promise<PermissionListResponse[]> => {
    return this.http.get(this.api.PERMISSIONS);
  };
}

export default Role;
