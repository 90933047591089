import { H5, Input, Select } from '@btc-snxt/ui';
import styled from 'styled-components';

export const StyledShareQrCode = styled.div`
  margin-top: 2rem;
  font-size: 1.25rem;
`;

export const StyledShareQrCodeContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  margin-top: 2rem;
  font-size: 1.25rem;
`;

export const QRContainer = styled.div`
  display: flex;
`;

export const ColumnLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-top-left-radius: 6.25rem;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  width: 50%;
  border-bottom-right-radius: 6.25rem;
  gap: 1.25rem;
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const ColumnRightText = styled.div`
  text-align: center;
`;

export const ColumnRightSubText = styled.div`
  text-align: center;
  font-size: 1rem;
`;

export const ExportQrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SendEmailWrapper = styled.div`
  ${Input} {
    margin-bottom: 0.625rem;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  margin-bottom: 1.25rem;
`;

export const DownloadWrapper = styled.div``;

export const EmptyPageText = styled(H5)`
  display: flex;
`;

export const DownloadTypeSelect = styled(Select)`
  font-size: 0.75rem;
  width: 7.5rem;
  .react-select__control,
  .react-select__option,
  .react-select__option--is-selected {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const DownloadTypeAndButtonWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`;
