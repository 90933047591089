import styled from 'styled-components';
import { breakpointDown, breakpointUp, Icon, IconInfo, PrimaryButton } from '@btc-snxt/ui';

export const StyledSelectSurveyTypePage = styled.div`
  align-items: start;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const StyledTemplateUseButton = styled(PrimaryButton)`
  border: none !important;
  border-radius: 0.5rem;
  transition: all 0.15s;
`;

export const StyledSidebarHeaderTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey900};
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  gap: 0.75rem;

  ${IconInfo} {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const StyledSidebarHeaderIconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${Icon} {
    height: 0.875rem;
    width: 0.875rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey200};
    border-radius: 0.5rem;
    padding: 0.75rem;
    margin: -0.75rem -0.75rem -0.75rem 0;
  }
`;

export const StyledSidebarHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  width: 100%;

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900};
  }
`;

export const StyledSidebarBody = styled.div`
  height: 100%;
  padding: 1.5rem;

  * {
    color: ${({ theme }) => theme.colors.grey900};
  }

  div:first-child {
    p:first-child {
      strong {
        font-weight: 700;
      }
    }

    p:nth-child(2) {
      margin-bottom: 1.5rem;
    }

    p:nth-child(3) {
      margin-bottom: 0.5rem;
    }
  }

  div:last-child {
    p:first-child {
      margin-bottom: 0.75rem;
    }
  }

  strong {
    font-weight: 500;
  }
`;

export const StyledSidebarFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  padding: 1.5rem;

  ${StyledTemplateUseButton} {
    width: 100%;
  }
`;

export const StyledDetailsSidebar = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4.375rem);
  justify-content: space-between;
  margin: -1rem 0 -1rem 0;
  overflow: auto;
  position: fixed;
  right: -22.75rem;
  transition: right 0.15s;
  width: 22.75rem;
  z-index: 5rem;

  &.open {
    right: 0;
  }

  ${breakpointDown.MD} {
    margin: -1rem 0 0 0;
    position: fixed;
    right: -100%;
    width: 100%;
  }
`;

export const StyledTemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  max-width: 85.5rem;
  padding: 0.5rem 0.5rem 1.75rem 0.5rem;
  transition: margin 0.15s;

  ${breakpointUp.MD} {
    padding: 2.25rem;
  }

  ${breakpointDown.MD} {
    flex-direction: column;
    width: 100%;
  }

  ${breakpointUp.SM} {
    flex-wrap: wrap;

    &.open {
      margin-right: 22.75rem;
    }
  }
`;

export const StyledTemplatesContainerTitlesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${breakpointDown.MD} {
    margin-bottom: 0.5rem;
  }

  ${breakpointUp.SM} {
    margin: 2rem 0;
  }
`;

export const StyledTemplatesContainerTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.375rem;

  ${breakpointDown.MD} {
    font-size: 1.5rem;
  }
`;

export const StyledTemplatesContainerSubTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;

  ${breakpointDown.MD} {
    font-size: 0.9375rem;
  }
`;

export const StyledTemplatesContainerButtonsBlock = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 1.5rem;
`;

export const StyledTemplateIcon = styled.div`
  ${Icon} {
    height: 1.75rem;
    width: 1.75rem;
  }
`;

export const StyledTemplateButtonHeaderTitle = styled.div`
  margin-right: 2.25rem;
  padding: 1rem;
  width: 100%;
`;

export const StyledTemplateButtonHeaderIconWrapper = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  margin: 0.5rem;
  max-height: 2.5rem;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 0.125rem;

  ${Icon} {
    height: 1.2rem;
    width: 1.2rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey200};
  }
`;

export const StyledTemplateButtonHeader = styled.div`
  align-items: start;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-weight: bold;
  font-size: 1rem;
  font-weight: 500;
  gap: 0.5rem;
  justify-content: space-between;
  position: relative;
  text-align: start;
  width: 100%;
`;

export const StyledTemplateButtonBody = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
  font-size: 0.875rem;
  font-weight: 400;
  height: 100%;
  padding: 0 1rem;
  text-align: start;
  width: 100%;
`;

export const StyledTemplateButtonFooter = styled.div`
  padding: 1rem;
  position: relative;
  text-align: start;
  width: 100%;

  ${Icon} {
    position: absolute;
    top: calc((100% / 2) - 0.9rem);
  }

  ${StyledTemplateUseButton} {
    position: inherit;
    width: 100%;
  }
`;

export const StyledTemplateButton = styled.div`
  align-items: start;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 13.725rem;
  justify-content: space-between;
  padding: 0;
  width: 15rem;

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900};
  }

  ${breakpointDown.MD} {
    width: 100%;
  }

  ${StyledTemplateButtonFooter} {
    ${StyledTemplateUseButton} {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.active,
  &:hover,
  &:focus {
    background-color: transparent !important;
    border-color: ${({ theme }) => theme.colors.blue} !important;

    ${StyledTemplateButtonFooter} {
      ${StyledTemplateUseButton} {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

export const StyledTemplateIconWrapper = styled.div`
  left: calc(50% - 1.3125rem);
  position: absolute;
  top: calc(50% - 1.3125rem);

  svg {
    height: 2.625rem;
    width: 2.625rem;
  }
`;

export const StyledTemplateBlankButton = styled(StyledTemplateButton)`
  position: relative;
`;
