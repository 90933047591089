import { AxiosRequestConfig } from 'axios';
import Client from 'api/services/client';
import {
  PaginationData,
  PaginationDataFilter,
  PaginationDataFilter as Filter,
} from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';
import {
  InvoicePaymentResponse,
  InvoicesTableResponse,
  UpdateInvoiceStatusRequest,
  InvoicePreviewResponse,
} from 'api/types/settings/settings';

class Invoice extends Client {
  public readonly api = {
    POST_EXPORT: `${this.http.baseUrl}/settings/invoices/export`,
    POST_DOWNLOAD: `${this.http.baseUrl}/settings/invoices/{invoice}/download`,
    GET_INVOICE_LIST: `${this.http.baseUrl}/settings/invoices`,
    PUT_INVOICE: `${this.http.baseUrl}/settings/invoices/{invoice}`,
    GET_PAY: `${this.http.baseUrl}/settings/invoices/{invoice}/pay`,
    GET_PREVIEW: `${this.http.baseUrl}/settings/invoices/{invoice}`,
  };

  export = (data: Filter = {}, config: AxiosRequestConfig = {}): Promise<Blob> => {
    return this.http.post(this.api.POST_EXPORT, data, { ...config, responseType: 'blob' });
  };

  download = (
    invoiceId: number,
    data: Filter = {},
    config: AxiosRequestConfig = {},
  ): Promise<Blob> => {
    return this.http.post(this.buildUrl(this.api.POST_DOWNLOAD, { invoice: invoiceId }), data, {
      ...config,
      responseType: 'blob',
    });
  };

  filterInvoices = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvoicesTableResponse>> => {
    return this.http.get(this.api.GET_INVOICE_LIST, { params: request });
  };

  updateStatus = (invoiceId: string, data: UpdateInvoiceStatusRequest): Promise<EmptyResponse> => {
    return this.http.put(this.buildUrl(this.api.PUT_INVOICE, { invoice: invoiceId }), data);
  };

  pay = (invoiceId: string): Promise<InvoicePaymentResponse | EmptyResponse> => {
    return this.http.get(this.buildUrl(this.api.GET_PAY, { invoice: invoiceId }));
  };

  preview = (invoiceId: string): Promise<InvoicePreviewResponse | EmptyResponse> => {
    return this.http.get(this.buildUrl(this.api.GET_PREVIEW, { invoice: invoiceId }));
  };
}

export default Invoice;
