import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { DashboardRequest, DashboardResponse } from 'api/types/dashboard/dashboard';

const useDashboardQuery = (
  queryKey: any[],
  request: DashboardRequest,
  options?: UseQueryOptionsObject<DashboardResponse>,
) =>
  useMakeQuery<DashboardResponse>(
    [queries.dashboard, ...queryKey],
    () => Api.dashboard.dashboard.getDashboardData(request),
    {
      staleTime: 120 * 1000,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useDashboardQuery };
