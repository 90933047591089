import Http from 'api/http';
import Client from 'api/services/client';
import {
  CategoryNodeRequest,
  CategoryNodesRequest,
  CategoryNodesResponse,
  CreateCategoryRequest,
  CategoryResponse,
  CategoryNodeLevelResponse,
} from 'api/types/category/category';
import { PaginationData, PaginationDataFilter } from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';

class Category extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      CATEGORY_LIST: `${this.http.baseUrl}/categories/category`,
      CATEGORY_GET: `${this.http.baseUrl}/categories/category/{category}`,
      CATEGORY_CREATE: `${this.http.baseUrl}/categories/category`,
      CATEGORY_DELETE: `${this.http.baseUrl}/categories/category/{category}`,
      CATEGORY_UPDATE: `${this.http.baseUrl}/categories/category/{category}`,
      CATEGORY_NODE_LIST: `${this.http.baseUrl}/categories/nodes/{nodeCategory}`,
      CATEGORY_NODE_LIST_UPDATE: `${this.http.baseUrl}/categories/nodes/nodes`,
      CATEGORY_LEVEL_LIST: `${this.http.baseUrl}/categories/levels/{levelCategory}`,
    };
  }

  fetchCategory = (category: string): Promise<CategoryNodeRequest> => {
    return this.http.get(this.buildUrl(this.api.CATEGORY_GET, { category }));
  };

  fetchCategories = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CategoryResponse>> => {
    return this.http.get(this.api.CATEGORY_LIST, { params: request });
  };

  createCategory = (data: CreateCategoryRequest): Promise<CategoryResponse> => {
    return this.http.post(this.api.CATEGORY_CREATE, data);
  };

  editCategory = (category: string, data: CategoryNodeRequest): Promise<CategoryResponse> => {
    return this.http.put(this.buildUrl(this.api.CATEGORY_UPDATE, { category }), data);
  };

  deleteCategory = (category: string): Promise<EmptyResponse> => {
    return this.http.delete(this.buildUrl(this.api.CATEGORY_DELETE, { category }));
  };

  fetchNodes = (nodeCategory: string): Promise<CategoryNodeRequest> => {
    return this.http.get(this.buildUrl(this.api.CATEGORY_NODE_LIST, { nodeCategory }));
  };

  updateNodes = (
    data: CategoryNodesRequest,
    levels: CategoryNodeLevelResponse[] | undefined,
  ): Promise<CategoryNodesResponse> => {
    const formedData = { nodes: data, levels: levels };
    return this.http.post(this.api.CATEGORY_NODE_LIST_UPDATE, formedData);
  };

  fetchNodeLevelsByCategory = (levelCategory: string): Promise<CategoryNodeLevelResponse[]> => {
    return this.http.get(this.buildUrl(this.api.CATEGORY_LEVEL_LIST, { levelCategory }));
  };
}

export default Category;
