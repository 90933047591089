import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@nosferatu500/react-sortable-tree/style.css';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  getTreeFromFlatData,
  removeNodeAtPath,
  walk,
} from '@nosferatu500/react-sortable-tree';

import {
  IconAdd,
  PrimaryButton,
  success,
  IconRemove,
  FixedCenteredSpinner,
  H4,
  IconTick,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { CategoryNodeLevelResponse, CategoryNodesRequest } from 'api/types/category/category';
import {
  InputWithColor,
  SaveButtonsWrapper,
  StyledIconArrowLeft,
  TreePageWrapper,
  TreeWrapper,
} from './CategoryTree.style';
import CategoryNodeLevel from './CategoryNodeLevel';
import { RouteList } from 'routeList';

export interface OnVisibilityToggleParams {
  treeData: any[];
  node: any;
  expanded: boolean;
  path: number[];
}
interface Props {
  categoryId?: string;
}

const CategoryTree: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { category } = useParams();
  const [treeData, setTreeData] = useState<any>({});
  const [visibilityToggleParams, setVisibilityToggleParams] = useState<OnVisibilityToggleParams>({
    treeData: [],
    node: null,
    expanded: true,
    path: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [levels, setLevels] = useState<CategoryNodeLevelResponse[]>();

  const fetchData = async () => {
    const data = await Api.category.category.fetchNodes(props.categoryId ?? (category as string));
    const nodeArray = data as unknown as [];
    const formTree = {
      treeData: getTreeFromFlatData({
        flatData: nodeArray.map((node: any) => ({
          ...node,
          title: node.name,
          category_collection_id: props.categoryId ?? category,
          expanded: true,
        })),
        getKey: (node) => node.id, // resolve a node's key
        getParentKey: (node) => node.parentId, // resolve a node's parent's key
        rootKey: 'null', // The value of the parent key when there is no parent (i.e., at root level)
      }),
    };
    setTreeData(formTree);
    setIsLoading(false);
  };

  const getNodeMaxDepth = () => {
    let depth = 0;
    walk({
      treeData: treeData.treeData,
      getNodeKey,
      callback: (node: any) => {
        if (node.path.length > depth) depth = node.path.length;
      },
    });
    return depth;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNodeKey = ({ treeIndex }: { treeIndex: any }) => treeIndex;

  //Fetching data after submit again, to prevent duplicates in database
  const handleSubmit = async <T extends CategoryNodesRequest>(
    data: T,
    levels: CategoryNodeLevelResponse[] | undefined,
  ) => {
    await Api.category.category.updateNodes(data, levels).then(() => {
      fetchData();
      success(t('category.update_success'));
    });
  };

  const canDrop = ({ node, nextPath }: { node: any; nextPath: any }) => {
    const nodeDepth = 2;
    return nextPath.length >= nodeDepth && node.parentId !== null;
  };

  const returnButtons = (node: any, path: any) => {
    const addNodeButton = [
      <PrimaryButton
        key={node}
        onClick={() => {
          setTreeData((state: { treeData: any; addAsFirstChild: any }) => ({
            treeData: addNodeUnderParent({
              treeData: state.treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey,
              newNode: {
                name: '',
                depth: path.length,
                category_collection_id: props.categoryId ?? category,
              },
              addAsFirstChild: state.addAsFirstChild,
            }).treeData,
          }));
        }}
      >
        <IconAdd />
      </PrimaryButton>,
    ];
    if (node.parentId === null) {
      return addNodeButton;
    }
    return [
      ...addNodeButton,
      <PrimaryButton
        key={node}
        onClick={() => {
          setTreeData((state: { treeData: any }) => ({
            treeData: removeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey,
            }),
          }));
        }}
      >
        <IconRemove />
      </PrimaryButton>,
    ];
  };

  const returnTitle = (node: any, path: any) => {
    if (node.parentId === null) {
      return <div>{node.name}</div>;
    }
    return (
      <InputWithColor
        $index={path.length - 2}
        value={node.name}
        onChange={(event) => {
          const name = event.target.value;
          setTreeData((state: { treeData: any }) => ({
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey,
              newNode: { ...node, name },
            }),
          }));
        }}
      />
    );
  };

  return (
    <>
      <TreePageWrapper>
        <SaveButtonsWrapper>
          {!props.categoryId ? (
            <PrimaryButton
              onClick={() => {
                navigate(RouteList.CATEGORY.CATEGORIES.path);
              }}
            >
              <StyledIconArrowLeft /> {t('common.back')}
            </PrimaryButton>
          ) : (
            <></>
          )}

          <PrimaryButton
            onClick={() => {
              handleSubmit(treeData, levels);
            }}
          >
            <IconTick /> {t('common.save')}
          </PrimaryButton>
        </SaveButtonsWrapper>

        <CategoryNodeLevel
          action={visibilityToggleParams}
          level={getNodeMaxDepth()}
          setLevelsData={setLevels}
          categoryId={props.categoryId ?? category!}
        />
        {isLoading ? (
          <FixedCenteredSpinner />
        ) : (
          <TreeWrapper>
            <H4>{t('category.tree_header')}</H4>
            <SortableTree
              treeData={treeData.treeData}
              onChange={(treeData: any) => setTreeData({ treeData })}
              canDrop={canDrop}
              onVisibilityToggle={(visibilityToggleParams: OnVisibilityToggleParams) => {
                setVisibilityToggleParams(visibilityToggleParams);
              }}
              onMoveNode={(onDrop: any) => {
                onDrop.node.depth = onDrop.path.length - 1;
                setTreeData(onDrop);
              }}
              generateNodeProps={({ node, path }) => ({
                buttons: returnButtons(node, path),
                title: returnTitle(node, path),
              })}
            />
          </TreeWrapper>
        )}
      </TreePageWrapper>
    </>
  );
};
export default CategoryTree;
