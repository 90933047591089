import Http from './http';
import UserAuth from './services/user/auth';
import UserProfile from './services/user/profile';
import User from './services/user/user';
import AdminCompany from './services/admin/company';
import Common from './services/common';
import Category from './services/category/category';
import Invoice from './services/settings/invoice';
import Membership from './services/membership/membership';
import Role from './services/role/role';
import Survey from './services/survey/survey';
import Translations from './services/translations/translations';
import Filter from './services/filter/filter';
import Dashboard from './services/dashboard/dashboard';
import PrivacyPolicy from './services/privacy-policy/privacy-policy';

export const http = new Http();
export { http as Http };

export const Api = {
  user: {
    auth: new UserAuth(http),
    profile: new UserProfile(http),
    user: new User(http),
  },
  role: {
    role: new Role(http),
  },
  filter: {
    filter: new Filter(http),
  },
  dashboard: {
    dashboard: new Dashboard(http),
  },
  category: {
    category: new Category(http),
  },
  admin: {
    company: new AdminCompany(http),
  },
  common: {
    common: new Common(http),
  },
  membership: {
    membership: new Membership(http),
  },
  settings: {
    invoice: new Invoice(http),
  },
  survey: {
    survey: new Survey(http),
  },
  translations: {
    translations: new Translations(http),
  },
  privacyPolicy: {
    privacyPolicy: new PrivacyPolicy(http),
  },
};
