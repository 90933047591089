import Client from 'api/services/client';
import { EmptyResponse } from 'api/types/common';
import {
  CreateMembershipRequest,
  MembershipListResponse,
  MembershipResponse,
  UpdateMembershipRequest,
  SubscribeRequest,
  SubscribeResponse,
} from 'api/types/membership/membership';

class Membership extends Client {
  public readonly api = {
    MEMBERSHIP_LIST: `${this.http.baseUrl}/memberships`,
    MEMBERSHIP_CREATE: `${this.http.baseUrl}/memberships`,
    MEMBERSHIP_GET: `${this.http.baseUrl}/memberships/{membershipId}`,
    MEMBERSHIP_SUBSCRIBE: `${this.http.baseUrl}/memberships/subscribe/{membershipId}`,
    MEMBERSHIP_UNSUBSCRIBE: `${this.http.baseUrl}/memberships/unsubscribe/plan`,
  };

  fetchList = (): Promise<MembershipListResponse> => {
    return this.http.get(this.api.MEMBERSHIP_LIST);
  };

  create = (data: CreateMembershipRequest): Promise<MembershipResponse> => {
    return this.http.post(this.api.MEMBERSHIP_CREATE, data);
  };

  get = (membershipId: string): Promise<MembershipResponse> => {
    return this.http.get(this.buildUrl(this.api.MEMBERSHIP_GET, { membershipId }));
  };

  update = (membershipId: string, data: UpdateMembershipRequest): Promise<MembershipResponse> => {
    return this.http.put(this.buildUrl(this.api.MEMBERSHIP_GET, { membershipId }), data);
  };

  delete = (membershipId: string): Promise<EmptyResponse> => {
    return this.http.delete(this.buildUrl(this.api.MEMBERSHIP_GET, { membershipId }));
  };

  subscribe = (membershipId: string, data: SubscribeRequest): Promise<SubscribeResponse> => {
    return this.http.post(this.buildUrl(this.api.MEMBERSHIP_SUBSCRIBE, { membershipId }), data);
  };

  unsubscribe = (): Promise<EmptyResponse> => {
    return this.http.put(this.api.MEMBERSHIP_UNSUBSCRIBE);
  };
}

export default Membership;
