import React, { useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Table } from '@btc-snxt/ui';
import { Api } from 'api';
import { PaginationDataFilter } from 'api/types/app/pagination';
import { useTabsContext } from 'components/Tabs/TabsContext';
import { EditableField } from './components';
import { StyledTranslationsPage } from './TranslationsSettings.style';
import { useTableQuery } from 'api/queries/table/useTableQuery';

const TranslationsSettings: React.FC = () => {
  const { t } = useTranslation();

  const { setLoading } = useTabsContext();
  setTimeout(() => setLoading(false), 0);

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();

  const { data, isLoading } = useTableQuery(['translations-list', tableQueryRequest], () =>
    Api.translations.translations.filterTranslations(tableQueryRequest),
  );

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: '#',
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('translations.group'),
        accessor: 'group',
        sortType: 'string',
      },
      {
        Header: t('translations.key'),
        accessor: 'key',
        sortType: 'string',
      },
      {
        Header: 'EN',
        accessor: 'en',
        sortType: 'string',
        Cell: (cell: Cell) => (
          <EditableField
            line={cell.row.values.id}
            en={cell.row.values.en}
            lt={cell.row.values.lt}
            ru={cell.row.values.ru}
            lang={'en'}
          />
        ),
      },
      {
        Header: 'LT',
        accessor: 'lt',
        sortType: 'string',
        Cell: (cell: Cell) => (
          <EditableField
            line={cell.row.values.id}
            en={cell.row.values.en}
            lt={cell.row.values.lt}
            ru={cell.row.values.ru}
            lang={'lt'}
          />
        ),
      },
      {
        Header: 'RU',
        accessor: 'ru',
        sortType: 'string',
        Cell: (cell: Cell) => {
          return (
            <EditableField
              line={cell.row.values.id}
              en={cell.row.values.en}
              lt={cell.row.values.lt}
              ru={cell.row.values.ru}
              lang={'ru'}
            />
          );
        },
      },
    ],
    [t],
  );

  return (
    <StyledTranslationsPage>
      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        columns={columns}
        data={data}
        showPageSizes={true}
        stickyHeader={true}
        searchable={true}
      />
    </StyledTranslationsPage>
  );
};

export default TranslationsSettings;
