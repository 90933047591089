import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';

const useCountriesQuery = (options?: UseQueryOptionsObject<Record<string, string>>) =>
  useMakeQuery<Record<string, string>>(
    [queries.common, 'countries-list'],
    () => Api.common.common.getCountries(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useCountriesQuery };
