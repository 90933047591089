import styled from 'styled-components';
import { Col, ModalH3 } from '@btc-snxt/ui';

export const StyledRegisterClientPage = styled.div`
  ${Col} {
    margin-top: 0.625rem;
  }

  ${ModalH3} {
    padding-top: 1.625rem;
  }
`;
