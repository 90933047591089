import React from 'react';
import MembershipSettings from './MembershipSettings';
import { MembershipSettingsProvider } from './MembershipSettingsContext';

const MembershipSettingsWrapper: React.FC = () => (
  <MembershipSettingsProvider>
    <MembershipSettings />
  </MembershipSettingsProvider>
);

export default MembershipSettingsWrapper;
