import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalProps, Col, Modal, PrimaryButton, Row, SecondaryButton } from '@btc-snxt/ui';
import { Api } from 'api';
import {
  useMembershipSettingsContext,
  useMembershipContext,
} from 'pages/Common/Settings/tabs/MembershipSettings/MembershipSettingsContext';
import { StyledFooter } from './MembershipDeleteConfirmModal.style';

const MembershipDeleteConfirmModal: React.FC<BaseModalProps> = (props) => {
  const { t } = useTranslation();

  const currentMembershipList = useMembershipSettingsContext();
  const currentMembership = useMembershipContext();

  const handleDelete = async () => {
    try {
      await Api.membership.membership.delete(currentMembership.id);
    } finally {
      currentMembershipList.setData((prevState) => {
        const newState = prevState.filter((membership) => membership.id != currentMembership.id);

        return newState;
      });
      props.setIsOpen(false);
    }
  };

  return (
    <Modal
      {...props}
      title={t('common.confirmation')}
      size={'sm'}
      components={{
        Footer: () => {
          return (
            <StyledFooter>
              <Row>
                <Col>
                  <SecondaryButton onClick={handleDelete}>{t('common.yes')}</SecondaryButton>
                </Col>

                <Col>
                  <PrimaryButton onClick={props.toggle}>{t('common.no')}</PrimaryButton>
                </Col>
              </Row>
            </StyledFooter>
          );
        },
      }}
    >
      <Row>
        <Col>{t('membership.delete_confirm')}</Col>
      </Row>
    </Modal>
  );
};

export default MembershipDeleteConfirmModal;
