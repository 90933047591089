import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import {
  PercentBarChart,
  QuestionAnswersFilter,
  QuestionType,
  StackedPercentBarChart,
  SurveyQuestionAnswerWithCount,
} from '@btc-snxt/graph';
import {
  Card,
  CheckboxInput,
  Dropdown,
  DropdownItemWrapper,
  IconFilter,
  ImageFormat,
  Label,
  Modal,
  PrimaryButton,
  Select,
  SubmenuDropdown,
  componentToImageAndDownload,
  error,
  parseTranslatable,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { MainQuestions } from 'api/types/filter/filter';
import { ExportCategoryNodesRequest } from 'api/types/survey';
import { AnswerTable } from './CommonComponents';
import {
  StyledFilterBody,
  StyledFilterIconWrapper,
  StyledGraph,
  StyledGraphOverview,
  StyledSelectableFilter,
} from './helpers.style';
import { IChartSelect, BarsChartShowSelect } from './types';

interface Props {
  questionIndex: number;
  questions: MainQuestions;
  questionAnswersFilter: string[];
  setQuestionFilters: Dispatch<SetStateAction<QuestionAnswersFilter | undefined>>;
  chartSelectTypes: IChartSelect[];
  selectedBarsChartShowTypes: IChartSelect[];
  setSelectedBarsChartShowTypes: Dispatch<SetStateAction<IChartSelect[]>>;
  exportCategoryNodes: ExportCategoryNodesRequest;
  filters: string[];
  handleApplyFilters: () => void;
}

const RenderGraph: React.FC<Props> = ({
  questionIndex,
  questions,
  questionAnswersFilter,
  setQuestionFilters,
  chartSelectTypes,
  selectedBarsChartShowTypes,
  setSelectedBarsChartShowTypes,
  exportCategoryNodes,
  filters,
  handleApplyFilters,
}) => {
  const [t, i18n] = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModalOpen = (): void => setModalOpen((prevState) => !prevState);

  const handleXLSXExport = async (surveyQuestionId?: number | string, questionTitle?: string) =>
    surveyQuestionId &&
    (await Api.survey.survey
      .surveyQuestionGenerateXLSX(surveyQuestionId.toString(), exportCategoryNodes)
      .then((data) => fileDownload(data, `${questionTitle}.xlsx` ?? 'generatedXLSX'))
      .catch(() => error(t('message.fail_export'))));

  const renderSubmenuItem = (title: string, handleClick: () => void): JSX.Element => (
    <DropdownItemWrapper onClick={handleClick}>{title}</DropdownItemWrapper>
  );

  const setSelectedBarsChartShowType = (selectType: IChartSelect): void => {
    setSelectedBarsChartShowTypes((prevState) => {
      const newState: IChartSelect[] = Object.assign([], prevState);

      newState[questionIndex] = selectType;

      return newState;
    });
  };

  const isAnswerFilterable = (value: SurveyQuestionAnswerWithCount): boolean => !!value?.filterable;

  const renderFilterModal = (): JSX.Element => (
    <Modal
      components={{
        Footer: () => (
          <PrimaryButton onClick={handleApplyFilters}>{t('graph.apply_filters')}</PrimaryButton>
        ),
      }}
      isOpen={isModalOpen}
      setIsOpen={setModalOpen}
      title={t('graph.filter_title')}
      toggle={toggleModalOpen}
    >
      <StyledFilterBody>
        {questions.questions[0].answers.map((value, index) => (
          <StyledSelectableFilter
            key={index}
            onClick={() =>
              isAnswerFilterable(value) &&
              setQuestionFilters({
                questionAnswersFilter: questions.questions[0].answers[index],
                answers: questions.questions,
              })
            }
          >
            <CheckboxInput
              checked={value.id ? filters.includes(value.id) : false}
              disabled={!isAnswerFilterable(value)}
            />
            <Label
              text={
                value.label &&
                parseTranslatable(value.label, i18n.language)?.concat(
                  !isAnswerFilterable(value) ? ' '.concat(t('graph.filter_no_answers')) : '',
                )
              }
            />
          </StyledSelectableFilter>
        ))}
      </StyledFilterBody>
    </Modal>
  );

  if (
    questions.questions[0].answers.length === 0 ||
    !questions.questions[0].answers.find((v) => v.count > 0)
  ) {
    return (
      <StyledGraphOverview>
        <Card title={t('graph.overview')}>{t('graph.no_data')}</Card>
      </StyledGraphOverview>
    );
  }

  switch (questions.questions[0].questionType) {
    case QuestionType.SingleSelect: {
      return (
        <StyledGraph ref={ref}>
          <Card
            title={`#${questionIndex + 1} `.concat(
              questions.mainQuestionTitle[i18n.language as any],
            )}
            headerEndElement={
              <>
                {renderFilterModal()}

                {questions.questions.length === 1 && (
                  <StyledFilterIconWrapper onClick={toggleModalOpen}>
                    <IconFilter />
                  </StyledFilterIconWrapper>
                )}

                <Select
                  classNamePrefix={'react-select'}
                  value={selectedBarsChartShowTypes[questionIndex]}
                  options={chartSelectTypes}
                  onChange={(newValue) => {
                    setSelectedBarsChartShowType(newValue as IChartSelect);
                  }}
                />

                <Dropdown>
                  <SubmenuDropdown title={t('common.export')}>
                    {renderSubmenuItem(ImageFormat.PNG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.PNG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.JPEG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.JPEG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.SVG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.SVG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {questions.questions.length === 1 &&
                      renderSubmenuItem('XLSX', () =>
                        handleXLSXExport(
                          questions.questions[0].questionId,
                          questions.mainQuestionTitle[i18n.language as any],
                        ),
                      )}
                  </SubmenuDropdown>
                </Dropdown>
              </>
            }
          >
            <PercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value === BarsChartShowSelect.Average
              }
              filters={filters}
            />

            <StackedPercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value !== BarsChartShowSelect.Average
              }
              filters={filters}
            />
          </Card>
        </StyledGraph>
      );
    }

    case QuestionType.MultiSelect: {
      return (
        <StyledGraph ref={ref}>
          <Card
            title={`#${questionIndex + 1} `.concat(
              questions.mainQuestionTitle[i18n.language as any],
            )}
            headerEndElement={
              <>
                {renderFilterModal()}

                {questions.questions.length === 1 && (
                  <StyledFilterIconWrapper onClick={toggleModalOpen}>
                    <IconFilter />
                  </StyledFilterIconWrapper>
                )}

                <Select
                  classNamePrefix={'react-select'}
                  value={selectedBarsChartShowTypes[questionIndex]}
                  options={chartSelectTypes}
                  onChange={(newValue) => {
                    setSelectedBarsChartShowType(newValue as IChartSelect);
                  }}
                />

                <Dropdown>
                  <SubmenuDropdown title={t('common.export')}>
                    {renderSubmenuItem(ImageFormat.PNG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.PNG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.JPEG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.JPEG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.SVG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.SVG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem('XLSX', () =>
                      handleXLSXExport(
                        questions.questions[0].questionId,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}
                  </SubmenuDropdown>
                </Dropdown>
              </>
            }
          >
            <PercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value === BarsChartShowSelect.Average
              }
              filters={filters}
            />

            <StackedPercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value !== BarsChartShowSelect.Average
              }
              filters={filters}
            />
          </Card>
        </StyledGraph>
      );
    }

    case QuestionType.OpinionScale: {
      return (
        <StyledGraph ref={ref}>
          <Card
            title={`#${questionIndex + 1} `.concat(
              questions.mainQuestionTitle[i18n.language as any],
            )}
            headerEndElement={
              <>
                <Select
                  classNamePrefix={'react-select'}
                  value={selectedBarsChartShowTypes[questionIndex]}
                  options={chartSelectTypes}
                  onChange={(newValue) => {
                    setSelectedBarsChartShowType(newValue as IChartSelect);
                  }}
                />

                <Dropdown>
                  <SubmenuDropdown title={t('common.export')}>
                    {renderSubmenuItem(ImageFormat.PNG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.PNG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.JPEG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.JPEG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem(ImageFormat.SVG, () =>
                      componentToImageAndDownload(
                        ref.current as unknown as HTMLElement,
                        ImageFormat.SVG,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}

                    {renderSubmenuItem('XLSX', () =>
                      handleXLSXExport(
                        questions.questions[0].questionId,
                        questions.mainQuestionTitle[i18n.language as any],
                      ),
                    )}
                  </SubmenuDropdown>
                </Dropdown>
              </>
            }
          >
            <PercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value === BarsChartShowSelect.Average
              }
              filters={filters}
            />

            <StackedPercentBarChart
              questions={questions.questions}
              questionAnswersFilter={questionAnswersFilter}
              setQuestionFilters={setQuestionFilters}
              shouldShow={
                selectedBarsChartShowTypes[questionIndex].value !== BarsChartShowSelect.Average
              }
              filters={filters}
            />
          </Card>
        </StyledGraph>
      );
    }

    case QuestionType.FreeText: {
      return (
        <Card
          title={`#${questionIndex + 1} `.concat(
            questions.questions[0].questionTitle[i18n.language as any],
          )}
          headerEndElement={
            <Dropdown>
              <SubmenuDropdown title={t('common.export')}>
                {renderSubmenuItem('XLSX', () =>
                  handleXLSXExport(
                    questions.questions[0].questionId,
                    questions.mainQuestionTitle[i18n.language as any],
                  ),
                )}
              </SubmenuDropdown>
            </Dropdown>
          }
        >
          <AnswerTable answers={questions.questions[0].answers} />
        </Card>
      );
    }

    default: {
      return <></>;
    }
  }
};

export default RenderGraph;
