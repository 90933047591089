import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResultLanguageStore } from '@btc-snxt/graph';
import { SurveyType } from '@btc-snxt/survey';
import { CalendarDatePicker, PrimaryButton, Select, SelectProps } from '@btc-snxt/ui';
import { Api } from 'api';
import { useSelectableSurveysQuery } from 'api/queries/survey/useSelectableSurveysQuery';
import { useDebounceQuery } from 'api/queries/useDebounceQuery';
import { SurveyTableResponse } from 'api/types/survey';
import { NPSController } from './SurveyTemplates/NPS';
import CategoryFilterController from './CategoryFilterController';
import { CustomerSatisfactionController } from './SurveyTemplates/CustomerSatisfaction';
import { CustomerEffortController } from './SurveyTemplates/CustomerEffort';
import { ProductExperienceController } from './SurveyTemplates/ProductExperience';
import { CustomerExperienceController } from './SurveyTemplates/CustomerExperience';
import { EmployeeSatisfactionController } from './SurveyTemplates/EmployeeSatisfaction';
import { IndividualSurveyController } from './SurveyTemplates/IndividualSurvey';
import {
  FilterButtonWrapper,
  Label,
  CategoriesWrapper,
  StyledDropdown,
  SurveySelectAndCalendarWrapper,
  EmptyCategoriesButton,
} from './ResultAnalysis.style';

export interface DataForSurveyController {
  surveyId: number | undefined;
  shouldFilterRootCategory: boolean;
  filters: number[];
  dates: [Date | null, Date | null] | undefined;
  type: string | undefined;
  questions: [] | undefined;
  available_languages: string[] | undefined;
  questionAnswersFilter: string[];
}

const ResultAnalysis: React.FC = () => {
  const { t } = useTranslation();
  const availableLanguages = useResultLanguageStore();

  const [searchText, setSearchText] = useState<string>('');
  const searchSurvey = useDebounceQuery<string>(searchText, 500);
  const surveyList = useSelectableSurveysQuery({ search: searchSurvey ?? undefined });

  const [currentSurvey, setCurrentSurvey] = useState<SurveyTableResponse>();
  const [categoryList, setCategoryList] = useState<number[]>([]);
  const [filterRootCategory, setFilterRootCategory] = useState<boolean>(true);
  const [isShown, setIsShown] = useState(false);
  const [shouldRenderGraphs, setShouldRenderGraphs] = useState(false);
  const [dataToExport, setDataToExport] = useState<DataForSurveyController>({
    surveyId: undefined,
    filters: [],
    shouldFilterRootCategory: true,
    dates: undefined,
    type: '',
    questions: [],
    available_languages: [],
    questionAnswersFilter: [],
  });
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const date = new Date();
    date.setMonth(new Date().getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleSurveyClick = async (option: SelectProps) => {
    await Api.survey.survey.getSurveyToUpdate(option.value).then((response) => {
      setCurrentSurvey(response.survey);
    });

    const publishedAt = surveyList.data?.find((v) => v.id === Number(option.value))?.published_at;
    if (publishedAt) {
      setStartDate(new Date(publishedAt));
    }

    try {
      const data = await Api.survey.survey.getSurvey(option.value);

      const categories = structuredClone(data);
      const nodeIds: number[] = [];
      categories.forEach((value: any) => {
        value.nodes.forEach((val: any) => {
          nodeIds.push(val.id);
        });
      });
      setShouldRenderGraphs(false);
      setCategoryList(nodeIds);
      setIsShown(false);
      setIsShown(true);
    } catch {
      setCategoryList([]);
      setIsShown(false);
      setShouldRenderGraphs(false);
    }
  };

  const handleSubmit = () => {
    setDataToExport(() => ({
      surveyId: currentSurvey?.id,
      filters: categoryList,
      dates: [startDate, endDate],
      shouldFilterRootCategory: filterRootCategory,
      type: currentSurvey?.type,
      available_languages: currentSurvey?.available_languages,
      questions: currentSurvey?.questions,
      questionAnswersFilter: [],
    }));
    if (currentSurvey?.available_languages) {
      availableLanguages.setAvailableLanguage({
        state: currentSurvey?.available_languages,
      });
    }
    setShouldRenderGraphs(true);
  };

  const renderGraphs = () => {
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.NPS) {
      return <NPSController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.CustomerSatisfaction) {
      return <CustomerSatisfactionController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.CustomerEffort) {
      return <CustomerEffortController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.ProductExperience) {
      return <ProductExperienceController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.CustomerExperience) {
      return <CustomerExperienceController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.EmployeeSatisfaction) {
      return <EmployeeSatisfactionController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.IndividualSurvey) {
      return <IndividualSurveyController data={dataToExport} />;
    }
    if (shouldRenderGraphs && currentSurvey?.type === SurveyType.LoyaltyIntentions) {
      return <IndividualSurveyController data={dataToExport} />;
    }
  };

  return (
    <>
      <SurveySelectAndCalendarWrapper>
        <StyledDropdown>
          <Label>{t('survey.label.survey_list')}</Label>

          <Select
            classNamePrefix={'react-select'}
            placeholder={t('survey.label.survey_list')}
            onChange={(newValue) => {
              handleSurveyClick(newValue as SelectProps);
              setFilterRootCategory(true);
              setCategoryList([]);
            }}
            onInputChange={setSearchText}
            options={surveyList.data?.map((v) => ({
              value: v.id,
              label: v.name,
            }))}
          />
        </StyledDropdown>

        <CalendarDatePicker date={startDate} setDate={setStartDate} label={t('common.date_from')} />

        <CalendarDatePicker date={endDate} setDate={setEndDate} label={t('common.date_to')} />
      </SurveySelectAndCalendarWrapper>

      {isShown && (
        <>
          <CategoriesWrapper>
            <CategoryFilterController
              survey={currentSurvey}
              setCategoryListData={setCategoryList}
              setFilterRootCategory={setFilterRootCategory}
            />
          </CategoriesWrapper>

          {categoryList.length > 0 ? (
            <FilterButtonWrapper>
              <PrimaryButton onClick={() => handleSubmit()}>{t('dashboard.filter')}</PrimaryButton>
            </FilterButtonWrapper>
          ) : (
            <EmptyCategoriesButton>
              <PrimaryButton onClick={() => handleSubmit()}>{t('dashboard.filter')}</PrimaryButton>
            </EmptyCategoriesButton>
          )}
        </>
      )}

      {renderGraphs()}
    </>
  );
};

export default ResultAnalysis;
