import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Logo, LanguageDropdown, Row, Col, Label, PrimarySpinner } from '@btc-snxt/ui';
import { Api } from 'api';
import { RegisterClientRequest } from 'api/types/user/auth';
import { useAuth } from 'helpers/useAuth/useAuth';
import {
  StyledAuthHeader,
  StyledLogo,
  StyledTitle,
  StyledTextBlock,
  StyledTextBody,
  StyledLanguageSelect,
  StyledAuthBody,
  StyledButtonWrapper,
  StyledSuggestion,
  StyledText,
  StyledLink,
  StyledAuthBlockWide,
  StyledLogoutButton,
  StyledConfirmButton,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { StyledRegisterClientPage } from './RegisterClient.style';

const RegisterClient: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { login, logout } = useAuth();

  const [countries, setCountries] = useState<Record<string, string>>({});
  const [isLoading, setLoading] = useState<boolean>(false);

  const RegisterClientSchema = Yup.object().shape({
    company_name: Yup.string().required(),
    legal_code: Yup.string().required(),
    vat: Yup.string(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    address: Yup.string().required(),
    mobile: Yup.string().required(),
  });

  const [registerClientRequest] = useState({
    company_name: '',
    legal_code: '',
    vat: '',
    country: 'LT',
    city: '',
    address: '',
    mobile: '',
  });

  useEffect(() => {
    (async () => {
      const countries = await Api.common.common.getCountries();
      setCountries(countries);
    })();
  }, [i18n.language]);

  const onSubmit = useCallback(
    async (request: RegisterClientRequest, helpers: FormikHelpers<RegisterClientRequest>) => {
      setLoading(true);
      try {
        const response = await Api.user.auth.registerClient(request);

        if (response.access_token) {
          await login(response.access_token, response.permissions);
        }
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, t],
  );

  const handleLogout = () => logout(Api.user.auth.logout);

  return (
    <StyledAuthBlockWide>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextBody>{t('auth.register.register_client_text')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>

      <StyledAuthBody>
        <StyledRegisterClientPage>
          <Formik
            initialValues={registerClientRequest}
            validationSchema={RegisterClientSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Label
                      htmlFor={'company_name'}
                      text={t('label.company_name')}
                      required={true}
                    />

                    <Field
                      align={'left'}
                      type={'text'}
                      name={'company_name'}
                      id={'company_name'}
                      placeholder={t('label.company_name')}
                      className={'form-control'}
                    />
                    <ErrorMessage
                      component={'div'}
                      className={'text-danger'}
                      name={'company_name'}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={4}>
                    <Label htmlFor={'legal_code'} text={t('label.legal_code')} required={true} />

                    <Field
                      type={'text'}
                      name={'legal_code'}
                      id={'legal_code'}
                      placeholder={t('label.legal_code')}
                      className={'form-control'}
                    />
                    <ErrorMessage component={'div'} className={'text-danger'} name={'legal_code'} />
                  </Col>

                  <Col xs={6} sm={4}>
                    <Label htmlFor={'country'} text={t('label.country')} required={true} />

                    <Field as={'select'} name={'country'} id={'country'} className={'form-control'}>
                      {Object.entries(countries).map(([code, translation]) => (
                        <option key={code} value={code}>
                          {translation}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage component={'div'} className={'text-danger'} name={'country'} />
                  </Col>

                  <Col xs={6} sm={4}>
                    <Label htmlFor={'city'} text={t('label.city')} required={true} />

                    <Field
                      type={'text'}
                      name={'city'}
                      id={'city'}
                      placeholder={t('label.city')}
                      className={'form-control'}
                    />
                    <ErrorMessage component={'div'} className={'text-danger'} name={'city'} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label htmlFor={'address'} text={t('label.address')} required={true} />

                    <Field
                      type={'text'}
                      name={'address'}
                      id={'address'}
                      placeholder={t('label.address')}
                      className={'form-control'}
                    />
                    <ErrorMessage component={'div'} className={'text-danger'} name={'address'} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label htmlFor={'vat'} text={t('label.vat')} />

                    <Field
                      type={'text'}
                      name={'vat'}
                      id={'vat'}
                      placeholder={t('label.vat')}
                      className={'form-control'}
                    />
                    <ErrorMessage component={'div'} className={'text-danger'} name={'vat'} />
                  </Col>

                  <Col>
                    <Label htmlFor={'mobile'} text={t('label.mobile')} required={true} />

                    <Field
                      type={'text'}
                      name={'mobile'}
                      id={'mobile'}
                      placeholder={t('label.mobile')}
                      className={'form-control'}
                    />
                    <ErrorMessage component={'div'} className={'text-danger'} name={'mobile'} />
                  </Col>
                </Row>

                <StyledButtonWrapper>
                  <StyledConfirmButton disabled={isLoading} type={'submit'}>
                    {isLoading ? <PrimarySpinner /> : t('common.confirm')}
                  </StyledConfirmButton>
                </StyledButtonWrapper>

                <StyledSuggestion>
                  <StyledText>{t('auth.register.logout_text')}</StyledText>

                  <StyledLink>
                    <StyledLogoutButton onClick={handleLogout} type={'button'}>
                      {t('auth.register.logout')}
                    </StyledLogoutButton>
                  </StyledLink>
                </StyledSuggestion>
              </Form>
            )}
          </Formik>
        </StyledRegisterClientPage>
      </StyledAuthBody>
    </StyledAuthBlockWide>
  );
};

export default RegisterClient;
