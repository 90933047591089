import React from 'react';
import { Range } from 'react-range';
import { StyledThumb, StyledTrack } from './MembershipSlider.style';

interface Props {
  max: number;
  min?: number;
  step?: number;
  values: number[];
  setValues: React.Dispatch<React.SetStateAction<number[]>>;
}

const MembershipSlider: React.FC<Props> = ({ max, min = 0, step = 1, values, setValues }) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={setValues}
      renderThumb={({ props }) => (
        <StyledThumb
          {...props}
          style={{
            ...props.style,
          }}
        />
      )}
      renderTrack={({ props, children }) => (
        <StyledTrack
          {...props}
          style={{
            ...props.style,
          }}
        >
          {children}
        </StyledTrack>
      )}
    />
  );
};

export default MembershipSlider;
