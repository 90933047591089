export type LoginRequest = {
  email: string;
  password: string;
  remember_me: boolean;
};

export type LoginUserResponse = {
  access_token: string;
  token_type: string;
  permissions: string[];
};

export type RegisterRequest = {
  email: string;
  password: string;
  password_confirmation: string;
  tos: boolean;
  marketing_consent: boolean;
  remember_me: boolean;
};

export interface RegisterClientRequest {
  company_name: string;
  legal_code: string;
  vat: string;
  country: string;
  city: string;
  address: string;
  mobile: string;
}

export type RefreshUserResponse = LoginUserResponse;

export type ResetPasswordRequest = {
  email: string;
};

export type ConfirmResetPasswordRequest = {
  password: string;
  password_confirmation: string;
  token: string | null | undefined;
};

export interface PasswordTokenRequest {
  token: string | null | undefined;
}

export enum SocialNetworkType {
  Facebook = 'facebook',
  LinkedIn = 'linkedin',
}

export interface SocialLoginRequest {
  code: string;
  social_network_type: SocialNetworkType;
}

export interface SocialLoginUrlRequest {
  social_network_type: SocialNetworkType;
}

export interface SocialLoginUrlResponse {
  redirect_url: string;
}
