import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  BaseModalProps,
  Col,
  FormModal,
  ModalH3,
  PrimaryButton,
  Row,
  SecondaryButton,
  LoadingState,
  withLoader,
  success,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { useCountriesQuery } from 'api/queries/common/useCountriesQuery';
import { useProfileQuery } from 'api/queries/profile/useProfileQuery';
import { UpdateProfileRequest } from 'api/types/user/profile';
import { ChangePasswordModal } from './components/ChangePasswordModal';
import { BodyWrapper, FooterWrapper } from './ProfileModal.style';

const ProfileModal: React.FC<BaseModalProps & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  enum State {
    Default,
    Edit,
  }

  const [modalState, setModalState] = useState<State>(State.Default);

  const profile = useProfileQuery();
  const countries = useCountriesQuery();

  setTimeout(() => props.setIsLoading(profile.isLoading || profile.isLoading), 0);

  const formData: UpdateProfileRequest = {
    name: profile.data?.client.user.name ?? '',
    surname: profile.data?.client.user.surname ?? '',
    position: profile.data?.client.user.position ?? [],
    user_email: profile.data?.client.user.email ?? '',
    user_mobile: profile.data?.client.user.mobile ?? '',
    company_name: profile.data?.client.company_name ?? '',
    category: profile.data?.client.category ?? '',
    legal_code: profile.data?.client.legal_code ?? '',
    vat: profile.data?.client.vat ?? '' ?? '',
    country: profile.data?.client.country ?? '',
    city: profile.data?.client.city ?? '',
    address: profile.data?.client.address ?? '',
    client_email: profile.data?.client.email ?? '',
    client_mobile: profile.data?.client.mobile ?? '',
  };

  const [isPasswordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const togglePasswordModal = () => setPasswordModalOpen(!isPasswordModalOpen);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
    surname: Yup.string().required(),
    user_email: Yup.string().email().required(),
    user_mobile: Yup.string().required(),
    company_name: Yup.string().required(),
    category: Yup.string().required(),
    legal_code: Yup.string().required(),
    vat: Yup.string().nullable(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    address: Yup.string().required(),
    client_email: Yup.string().email().required(),
    client_mobile: Yup.string().required(),
  });

  const isDisabled = () => modalState == State.Default;

  const isEditable = () => modalState == State.Edit;

  const handleSubmit = async <T extends UpdateProfileRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    let didCatchError = false;
    helper.setSubmitting(true);
    try {
      await Api.user.profile.update(values);
      success(t('profile.update_success'));
    } catch (e: any) {
      didCatchError = true;
    } finally {
      if (!didCatchError) {
        props.setIsOpen(false);

        profile.setQueryData((prev) => ({
          id: prev.id,
          name: values.name ?? prev.client.user.name,
          surname: values.surname ?? prev.client.user.surname,
          position: values.position ?? prev.client.user.position,
          email: values.user_email ?? prev.client.user.email,
          mobile: values.user_mobile ?? prev.client.user.mobile,
          membership: prev.membership,
          client: {
            id: prev.client.id,
            active: prev.client.active,
            company_name: values.company_name ?? prev.client.company_name,
            category: values.category ?? prev.client.category,
            legal_code: values.legal_code ?? prev.client.legal_code,
            vat: values.vat ?? prev.client.vat,
            country: values.country ?? prev.client.country,
            city: values.city ?? prev.client.city,
            address: values.address ?? prev.client.address,
            email: values.client_email ?? prev.client.email,
            mobile: values.client_mobile ?? prev.client.mobile,
            user: {
              ...prev.client.user,
              name: values.name ?? prev.client.user.name,
              surname: values.surname ?? prev.client.user.surname,
              position: values.position ?? prev.client.user.position,
              email: values.user_email ?? prev.client.user.email,
              mobile: values.user_mobile ?? prev.client.user.mobile,
            },
          },
        }));
      }
      helper.setSubmitting(false);
      setModalState(State.Default);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.my_account')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
      components={{
        Footer: ({ setIsOpen }) => {
          return (
            <FooterWrapper>
              <PrimaryButton type={'button'} onClick={() => togglePasswordModal()}>
                {t('profile.button_change_password')}
              </PrimaryButton>

              <PrimaryButton
                type={isEditable() ? 'submit' : 'button'}
                onClick={() => {
                  !isEditable() && setModalState(State.Edit);
                }}
              >
                {isEditable() ? 'Save' : 'Edit'}
              </PrimaryButton>

              <SecondaryButton
                onClick={() => {
                  setIsOpen(false);
                  isEditable() && setModalState(State.Default);
                }}
              >
                {t('common.cancel')}
              </SecondaryButton>
            </FooterWrapper>
          );
        },
      }}
    >
      <BodyWrapper>
        <Row>
          <Col xs={12} sm={6}>
            <Label htmlFor={'company_name'} text={t('label.company_name')} required={true} />
            <Field
              align={'left'}
              type={'text'}
              name={'company_name'}
              id={'company_name'}
              placeholder={t('label.company_name')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'company_name'} />
          </Col>

          <Col xs={12} sm={6}>
            <Label htmlFor={'category'} text={t('label.category')} required={true} />
            <Field
              type={'text'}
              name={'category'}
              id={'category'}
              placeholder={t('label.category')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'category'} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4}>
            <Label htmlFor={'legal_code'} text={t('label.legal_code')} required={true} />
            <Field
              type={'text'}
              name={'legal_code'}
              id={'legal_code'}
              placeholder={t('label.legal_code')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'legal_code'} />
          </Col>

          <Col xs={6} sm={4}>
            <Label htmlFor={'country'} text={t('label.country')} required={true} />
            <Field
              as={'select'}
              name={'country'}
              id={'country'}
              className={'form-control'}
              disabled={isDisabled()}
            >
              {countries.data &&
                Object.entries(countries.data).map(([code, translation]) => (
                  <option key={code} value={code}>
                    {translation}
                  </option>
                ))}
            </Field>
            <ErrorMessage component={'div'} className={'text-danger'} name={'country'} />
          </Col>

          <Col xs={6} sm={4}>
            <Label htmlFor={'city'} text={t('label.city')} required={true} />
            <Field
              type={'text'}
              name={'city'}
              id={'city'}
              placeholder={t('label.city')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'city'} />
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <Label htmlFor={'vat'} text={t('label.vat')} />
            <Field
              type={'text'}
              name={'vat'}
              id={'vat'}
              placeholder={t('label.vat')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'vat'} />
          </Col>

          <Col>
            <Label htmlFor={'address'} text={t('label.address')} required={true} />
            <Field
              type={'text'}
              name={'address'}
              id={'address'}
              placeholder={t('label.address')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'address'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'client_email'} text={t('label.email')} required={true} />
            <Field
              type={'text'}
              name={'client_email'}
              id={'client_email'}
              placeholder={t('label.email')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'client_email'} />
          </Col>

          <Col>
            <Label htmlFor={'client_mobile'} text={t('label.mobile')} required={true} />
            <Field
              type={'text'}
              name={'client_mobile'}
              id={'client_mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'client_mobile'} />
          </Col>
        </Row>

        <ModalH3>{t('label.responsible_person')}</ModalH3>

        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />
            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>

          <Col>
            <Label htmlFor={'surname'} text={t('label.surname')} required={true} />
            <Field
              type={'text'}
              name={'surname'}
              id={'surname'}
              placeholder={t('label.surname')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'surname'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'user_email'} text={t('label.email')} required={true} />
            <Field
              type={'email'}
              name={'user_email'}
              id={'user_email'}
              placeholder={t('label.email')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'user_email'} />
          </Col>

          <Col>
            <Label htmlFor={'user_mobile'} text={t('label.mobile')} required={true} />
            <Field
              type={'text'}
              name={'user_mobile'}
              id={'user_mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
              disabled={isDisabled()}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'user_mobile'} />
          </Col>
        </Row>
      </BodyWrapper>

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        setIsOpen={setPasswordModalOpen}
        toggle={togglePasswordModal}
      />
    </FormModal>
  );
};

export default withLoader(ProfileModal);
