import styled from 'styled-components';
import { Col, ModalH3, PrimaryButton } from '@btc-snxt/ui';

export const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${Col} {
    margin-top: 0.625rem;
  }

  ${ModalH3} {
    padding-top: 1.625rem;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  width: 100%;
`;
