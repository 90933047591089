import React, { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import {
  Dropdown,
  DropdownItemWrapper,
  error,
  Filterable,
  IconCopy,
  IconEdit,
  IconRemove,
  IconShare,
  IconShow,
  success,
  Table,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { RouteList } from 'routeList';
import { SurveyEditorMode } from 'helpers/enums';
import { PaginationDataFilter } from 'api/types/app/pagination';
import {
  StyledDropdownItem,
  StyledIcon,
  StyledSurveyListPage,
  StyledText,
} from './SurveyList.style';
import { convertStringToUrlFriendly } from 'helpers/common';
import ConfirmDeleteModal from 'pages/Modal/Survey/ConfirmDeleteModal';
import { useTableQuery } from 'api/queries/table/useTableQuery';

const SurveyList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();
  const [shouldFetchData, setShouldFetchData] = useState<number>(0);
  const refetchData = () => setShouldFetchData((prevState: number) => prevState + 1);

  const { data, isLoading } = useTableQuery(
    ['survey-list', tableQueryRequest, shouldFetchData],
    () => Api.survey.survey.filterSurveys(tableQueryRequest),
  );

  const [surveyId, setSurveyId] = useState<string>('');

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);

  const hiddenColumns: Array<string> = ['id'];
  const filterable: Array<Filterable> = [{ columnName: 'created_at', columnType: 'date' }];

  const columns = useMemo<Column[] | any>(
    () => [
      {
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('survey.label.name'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('survey.label.type'),
        accessor: 'type',
        sortType: 'string',
      },
      {
        Header: t('survey.label.questions'),
        accessor: 'questions_count',
        sortType: 'number',
      },
      {
        Header: t('survey.label.respondents'),
        accessor: 'survey_participations_count',
        sortType: 'number',
      },
      {
        Header: t('survey.label.status'),
        accessor: 'status',
        sortType: 'string',
      },
      {
        Header: t('label.updated_at'),
        accessor: 'updated_at',
        sortType: 'datetime',
        Cell: (cell: Cell) => {
          return t('common.date_full', {
            date: cell.row.values.updated_at,
          });
        },
      },
      {
        Header: t('label.created_at'),
        accessor: 'created_at',
        sortType: 'datetime',
        Cell: (cell: Cell) => {
          return t('common.date_full', {
            date: cell.row.values.created_at,
          });
        },
      },
      {
        Header: t('common.more'),
        Cell: (cell: Cell) => {
          return (
            <Dropdown>
              <DropdownItemWrapper
                onClick={() => {
                  const url = generatePath(RouteList.SURVEYS.SURVEY_EDITOR.EDIT.path, {
                    surveyId: cell.row.values.id,
                    type: convertStringToUrlFriendly(cell.row.values.type),
                    mode: SurveyEditorMode.Design,
                  });
                  navigate(url);
                }}
              >
                <StyledDropdownItem>
                  <StyledIcon>
                    <IconEdit />
                  </StyledIcon>
                  <StyledText>{t('common.edit')}</StyledText>
                </StyledDropdownItem>
              </DropdownItemWrapper>

              <DropdownItemWrapper
                onClick={() => {
                  const url = generatePath(RouteList.SURVEYS.SURVEY_EDITOR.EDIT.path, {
                    surveyId: cell.row.values.id,
                    type: convertStringToUrlFriendly(cell.row.values.type),
                    mode: SurveyEditorMode.Preview,
                  });
                  navigate(url);
                }}
              >
                <StyledDropdownItem>
                  <StyledIcon>
                    <IconShow />
                  </StyledIcon>

                  <StyledText>{t('survey.label.preview_survey')}</StyledText>
                </StyledDropdownItem>
              </DropdownItemWrapper>

              <DropdownItemWrapper
                onClick={() => {
                  setSurveyId(cell.row.values.id);
                  toggleDeleteModal();
                }}
              >
                <StyledDropdownItem>
                  <StyledIcon>
                    <IconRemove />
                  </StyledIcon>

                  <StyledText>{t('common.delete')}</StyledText>
                </StyledDropdownItem>
              </DropdownItemWrapper>

              <DropdownItemWrapper onClick={() => makeDuplicate(cell.row.values.id)}>
                <StyledDropdownItem>
                  <StyledIcon>
                    <IconCopy />
                  </StyledIcon>

                  <StyledText>{t('survey.label.make_copy')}</StyledText>
                </StyledDropdownItem>
              </DropdownItemWrapper>

              <DropdownItemWrapper
                onClick={() => {
                  const url = generatePath(RouteList.SURVEYS.SURVEY_EDITOR.EDIT.path, {
                    surveyId: cell.row.values.id,
                    type: convertStringToUrlFriendly(cell.row.values.type),
                    mode: SurveyEditorMode.Share,
                  });
                  navigate(url);
                }}
              >
                <StyledDropdownItem>
                  <StyledIcon>
                    <IconShare />
                  </StyledIcon>

                  <StyledText>{t('common.share')}</StyledText>
                </StyledDropdownItem>
              </DropdownItemWrapper>
            </Dropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const makeDuplicate = async (surveyId: string) => {
    try {
      await Api.survey.survey.duplicateSurvey(surveyId);
      refetchData();
      success(t('survey.error.duplicate_success'));
    } catch (e) {
      error(t('survey.error.duplicate_error'));
    }
  };

  return (
    <StyledSurveyListPage>
      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        initialSortBy={[{ id: 'created_at', desc: true }]}
        columns={columns}
        data={data}
        stickyHeader={true}
        searchable={true}
        hiddenColumns={hiddenColumns}
        filterable={filterable}
      />

      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        toggle={toggleDeleteModal}
        surveyId={surveyId}
        onDelete={refetchData}
      />
    </StyledSurveyListPage>
  );
};

export default SurveyList;
