import styled from 'styled-components';

export const StyledThumb = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 1rem;
  height: 1.75rem;
  width: 1.75rem;
`;

export const StyledTrack = styled.div`
  background-color: ${({ theme }) => theme.colors.gray4};
  border-radius: 0.25rem;
  height: 0.5rem;
  width: 100%;
`;
