import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, FormModal, Row, LoadingState, withLoader, success, Label } from '@btc-snxt/ui';
import { Api } from 'api';
import { BodyWrapper } from '../Users/AddUserModal.style';
import { useRoleQuery } from 'api/queries/role/useRoleQuery';
import { UpdateRoleRequest } from 'api/types/role/role';
import { RoleModalProps } from './DeleteRoleModal';

interface Props extends RoleModalProps {
  updateInfoModal?: () => Promise<void>;
  handleEdit?: () => void;
}

const EditRoleModal: React.FC<Props & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  const role = useRoleQuery(props.roleId);

  setTimeout(() => props.setIsLoading(role.isLoading), 0);

  const formData: UpdateRoleRequest = {
    id: role.data?.id ?? 0,
    name: role.data?.name ?? '',
    description: role.data?.description ?? '',
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
  });

  const handleSubmit = async <T extends UpdateRoleRequest>(values: T, helper: FormikHelpers<T>) => {
    try {
      await Api.role.role.edit(props.roleId, values);
      success(t('role.update_success'));
      props.setIsOpen(false);
      props.updateInfoModal && (await props.updateInfoModal());
      props.handleEdit && props.handleEdit();
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
      role.setQueryData(values);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('role.edit_role')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
    >
      <BodyWrapper>
        <Row>
          <Col xs={12} sm={6}>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />

            <Field
              align={'left'}
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>

          <Col xs={12} sm={6}>
            <Label htmlFor={'description'} text={t('label.description')} required={true} />

            <Field
              type={'text'}
              name={'description'}
              id={'description'}
              placeholder={t('label.description')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'description'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(EditRoleModal);
