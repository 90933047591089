export interface Translatable {
  [key: string]: string;
}

export interface MembershipBenefitsResponse {
  labels: Translatable;
  is_active: boolean;
}

export interface MembershipResponse {
  id: string;
  name: Translatable;
  description: Translatable;
  yearly_price: number;
  semi_yearly_price: number;
  quarterly_price: number;
  monthly_price: number;
  is_active: boolean;
  benefits: MembershipBenefitsResponse[];
  icon_id: number;
}

export type MembershipListResponse = MembershipResponse[];

export interface MembershipBenefitsRequest {
  labels: Translatable;
  is_active: boolean;
}

export interface CreateMembershipRequest {
  name: Translatable;
  description: Translatable;
  yearly_price: number;
  semi_yearly_price: number;
  quarterly_price: number;
  monthly_price: number;
  is_active: boolean;
  benefits: MembershipBenefitsRequest[];
  icon_id: number;
}

export interface UpdateMembershipRequest {
  name: Translatable;
  description: Translatable;
  yearly_price: number;
  semi_yearly_price: number;
  quarterly_price: number;
  monthly_price: number;
  is_active: boolean;
  benefits: MembershipBenefitsRequest[];
  icon_id: number;
}

export enum BillingFrequency {
  Yearly = 'yearly',
  SemiYearly = 'semi_yearly',
  Quarterly = 'quarterly',
  Monthly = 'monthly',
}

export interface SubscribeRequest {
  billing_frequency: BillingFrequency;
}

export interface SubscribeResponse {
  payment_url: string;
}

export interface MembershipStateInterface {
  id: number | undefined;
}
