import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';
import {
  breakpointDown,
  breakpointUp,
  H1,
  IconAdd,
  PrimaryButton,
  SecondaryButton,
} from '@btc-snxt/ui';
import { StyledIntroPagePreviewer, StyledPreviewer } from '@btc-snxt/survey';

export const StyleSurveyEditor = styled.div``;

export const StyledTitle = styled(H1)``;

export const StyledButtons = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export const StyledHeaderButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray5};
  border: none;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue4};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const StyledBody = styled.div``;

export const StyledMobileWrapper = styled.div`
  border: 4px solid ${({ theme }) => theme.colors.grey400};
  border-radius: 0.5rem;
  max-height: 50.75rem;
  max-width: 23.5rem;
  overflow: auto;
`;

const MobileModeCss = css`
  align-items: center;

  form {
    ${StyledPreviewer} {
      border-top: 4px solid ${({ theme }) => theme.colors.grey400};
      max-height: 812px;
      padding: 2rem 1rem;
    }

    ${StyledIntroPagePreviewer} {
      border-top: none;
    }

    ${StyledIntroPagePreviewer} {
      max-height: 812px;
    }
  }
`;

interface StyledItemsProps {
  $isMobile?: boolean;
}

export const StyledItems = styled.div<StyledItemsProps>`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  overflow: auto;

  ${(props) => props.$isMobile && MobileModeCss}
`;

export const StyledAddItemButton = styled(SecondaryButton)`
  width: 100%;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: rgba(0, 130, 184, 0.2);
  color: ${({ theme }) => theme.colors.blue};
  font-size: 0.75rem;
  font-weight: bold;
  padding-top: 10px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledAddIcon = styled(IconAdd)`
  height: 20px;
`;

export const StyledActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${breakpointDown.SM} {
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    ${PrimaryButton} {
      width: 100%;
    }
  }
`;

export const FileUploadWrapper = styled.div`
  color: black;
  border: 1px solid black;
  p {
    color: black;
  }
  i {
    background-color: black;
  }
`;

export const StyledButtonsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${breakpointDown.XL} {
    flex-direction: column;
    gap: 1.25rem;
  }

  ${breakpointUp.XL} {
    align-items: end;
  }
`;

export const StyledButtonsSubheader = styled.div`
  display: flex;
  align-items: end;
  gap: 0.5rem;
`;

export const StyledAvailableLanguagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAvailableLanguages = styled.div``;

export const StyledPreviewDeviceBlock = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;
