import Http from 'api/http';
import Client from 'api/services/client';
import { PaginationData, PaginationDataFilter } from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';
import {
  ClientsTableResponse,
  CreateClientRequest,
  UpdateClientActiveRequest,
  UpdateClientRequest,
} from 'api/types/user/user';
import { ClientResponse } from 'api/types/user/profile';

class Company extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      CLIENT_LIST: `${this.http.baseUrl}/clients`,
      CLIENT_CREATE: `${this.http.baseUrl}/clients/client`,
      CLIENT: `${this.http.baseUrl}/clients/client/{client}`,
      CLIENT_UPDATE_ACTIVE: `${this.http.baseUrl}/clients/client/{client}/update-active`,
    };
  }

  create = (data: CreateClientRequest): Promise<ClientResponse> => {
    return this.http.post(this.api.CLIENT_CREATE, data);
  };

  fetch = (client: string): Promise<ClientResponse> => {
    return this.http.get(this.buildUrl(this.api.CLIENT, { client }));
  };

  filterClients = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ClientsTableResponse>> => {
    return this.http.get(this.api.CLIENT_LIST, { params: request });
  };

  edit = (client: string, data: UpdateClientRequest): Promise<ClientResponse> => {
    return this.http.put(this.buildUrl(this.api.CLIENT, { client }), data);
  };

  delete = (client: string): Promise<EmptyResponse> => {
    return this.http.delete(this.buildUrl(this.api.CLIENT, { client }));
  };

  updateActive = (client: string, data: UpdateClientActiveRequest): Promise<EmptyResponse> => {
    return this.http.put(this.buildUrl(this.api.CLIENT_UPDATE_ACTIVE, { client }), data);
  };
}

export default Company;
