import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner } from '@btc-snxt/ui';
import { RouteList } from 'routeList';
import {
  StyledMessagePage,
  StyledPageBody,
  StyledPageHeader,
  StyledRedirectText,
  StyledSpinnerWrapper,
  StyledSuccessText,
} from './MessageSuccess.style';

const MAX_SECONDS_COUNT = 5;
const MILISECONDS = 1000;

const MessageSuccess: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [secondsCount, setSecondsCount] = useState<number>(MAX_SECONDS_COUNT * MILISECONDS);

  useEffect(() => {
    if (secondsCount > 0) {
      setTimeout(() => {
        setSecondsCount((prevState) => prevState - MILISECONDS);
      }, MILISECONDS);
    } else {
      navigate(RouteList.DASHBOARD.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsCount]);

  const renderSeconds = () => {
    return Math.floor(secondsCount / MILISECONDS);
  };

  return (
    <StyledMessagePage>
      <StyledPageHeader>{t('message.success_title')}</StyledPageHeader>
      <StyledPageBody>
        <StyledSuccessText>{t('message.success_text')}</StyledSuccessText>

        <StyledSpinnerWrapper>
          <CenteredSpinner />
        </StyledSpinnerWrapper>

        <StyledRedirectText>
          {t('message.redirect_main_text')}
          {renderSeconds()}
          {t('message.redirect_seconds_text')}
        </StyledRedirectText>
      </StyledPageBody>
    </StyledMessagePage>
  );
};

export default MessageSuccess;
