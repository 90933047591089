import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { RoleMatrixResponse } from 'api/types/role/role';

const useRolesQuery = (options?: UseQueryOptionsObject<RoleMatrixResponse[]>) =>
  useMakeQuery<RoleMatrixResponse[]>(
    [queries.role, 'roles-list'],
    () => Api.role.role.fetchRoles(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useRolesQuery };
