import React, { useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { DropdownWrapper, IconArrowDown, IconArrowUp } from '@btc-snxt/ui';
import {
  StyledDropdown,
  StyledDropdownMenu,
  StyledDropdownToggle,
  StyledTitleBlock,
} from './TabsDropdown.style';

interface Props {
  title: string;
}

const TabsDropdown: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!isOpen);

  return (
    <DropdownWrapper>
      <StyledDropdown isOpen={isOpen} toggle={toggle}>
        <StyledDropdownToggle>
          <StyledTitleBlock>{props.title}</StyledTitleBlock>
          {isOpen ? <IconArrowUp /> : <IconArrowDown />}
        </StyledDropdownToggle>

        <StyledDropdownMenu>
          {React.Children.map(props.children, (menuItem, index) => (
            <DropdownItem key={index}>{menuItem}</DropdownItem>
          ))}
        </StyledDropdownMenu>
      </StyledDropdown>
    </DropdownWrapper>
  );
};

export default TabsDropdown;
