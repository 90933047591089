import { SurveyQuestionAnswerWithCount } from '@btc-snxt/graph';
import { Distribution } from './types';

const calculateNpsData = (
  answers: SurveyQuestionAnswerWithCount[],
  totalRespondentsCount: number,
) => {
  const tempDist: Distribution = {
    npsValue: 0,
    promotersCount: 0,
    promotersPercentage: 0,
    passivesCount: 0,
    passivesPercentage: 0,
    detractorsCount: 0,
    detractorsPercentage: 0,
  };

  answers.forEach((value) => {
    if (value.value !== undefined) {
      if (value.value > 8) {
        tempDist.promotersCount += value.count;
      } else if (value.value > 6) {
        tempDist.passivesCount += value.count;
      } else {
        tempDist.detractorsCount += value.count;
      }
    }
  });

  const calculatePercentage = (distributionEntryCount: number) => {
    return Math.round(((distributionEntryCount * 100) / totalRespondentsCount) * 100) / 100;
  };

  tempDist.promotersPercentage = calculatePercentage(tempDist.promotersCount);
  tempDist.passivesPercentage = calculatePercentage(tempDist.passivesCount);
  tempDist.detractorsPercentage = calculatePercentage(tempDist.detractorsCount);

  tempDist.npsValue = Math.round(tempDist.promotersPercentage - tempDist.detractorsPercentage);

  return tempDist;
};

export { calculateNpsData };
