import { SurveyQuestionAnswerWithCount } from '@btc-snxt/graph';
import { Table } from 'pages/Common/Survey/SurveyEditor/modes/ShareMode/shareMethods/ShareByEmail/ShareByEmail.style';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerTableWrapper } from './AnswerTable.style';

interface Props {
  answers: SurveyQuestionAnswerWithCount[];
}

const AnswerTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const processData = () => {
    return (
      <AnswerTableWrapper>
        <Table>
          <thead>
            <tr>
              <th>{t('graph.answer')}</th>
              <th>{t('graph.count')}</th>
            </tr>
          </thead>
          <tbody>
            {props.answers.map((answer: any, index: any) => (
              <tr key={index}>
                <td>{answer.value}</td>
                <td>{answer.count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </AnswerTableWrapper>
    );
  };

  return <>{processData()}</>;
};

export default AnswerTable;
