import styled from 'styled-components';

export const AnswerTableWrapper = styled.div`
  max-height: 22.5rem;
  overflow: overlay;
  @-moz-document url-prefix() {
    overflow: auto;
  }

  tr {
    color: ${({ theme }) => theme.colors.gray2};
  }
`;
