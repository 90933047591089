import React, { FunctionComponent, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Cookies, CookiesProvider } from 'react-cookie';
import { QueryParamProvider } from 'use-query-params';
import { RouteAdapter } from 'components/RouteAdapter';
import 'services/i18n';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

const cookies = new Cookies();
const queryClient = new QueryClient(); // Create a new QueryClient instance

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={'Loading'}>
      <CookiesProvider cookies={cookies}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter as unknown as FunctionComponent}>
              <App />
            </QueryParamProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </CookiesProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
