import styled from 'styled-components';
import { NavLink as ReactStrapNavLink } from 'reactstrap';
import { Icon, IconArrowDown, IconArrowUp } from '@btc-snxt/ui';

export const NavLink = styled(ReactStrapNavLink)`
  align-items: center;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.grey900};
  display: flex;
  gap: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 1rem 1.25rem;
  text-decoration: none;
  width: 100%;

  ${IconArrowDown} {
    background-color: ${({ theme }) => theme.colors.grey900};
    height: 1.25rem;
    width: 1.25rem;
  }

  ${IconArrowUp} {
    height: 1.25rem;
    width: 1.25rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};
    color: ${({ theme }) => theme.colors.grey900};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.grey100};
    color: ${({ theme }) => theme.colors.grey900};

    ${Icon} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  &.open {
    ${Icon} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const PrivacyPolicyNavLink = styled(ReactStrapNavLink)`
  color: ${({ theme }) => theme.colors.gray1};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  justify-content: center;
  &:hover {
    color: ${({ theme }) => theme.colors.blue4};
  }
`;

export const BlueNavLink = styled(NavLink)`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
