import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, BaseModalProps, Row, LoadingState, withLoader, Modal } from '@btc-snxt/ui';
import { useSurveyEditor } from 'pages/Common/Survey/SurveyEditor/SurveyEditorContext';
import { SurveyItemType, TemplateItem } from '@btc-snxt/survey';
import { BodyWrapper, StyledButton } from './AddSurveyItemModal.style';

interface Props extends BaseModalProps, LoadingState {
  handleAdd?: () => void;
  itemIndex: number;
}

interface SurveyItem {
  value: SurveyItemType;
  label: string;
}

const AddSurveyItemModal: React.FC<Props> = ({ ...props }): JSX.Element => {
  const { t } = useTranslation();
  const context = useSurveyEditor();

  const handleSubmit = (surveyItem: SurveyItem) => {
    const newItem: TemplateItem = {
      id: Date.now().toString(),
      type: surveyItem.value,
      initialValues: undefined,
      values: undefined,
    };
    context.addItem(newItem, props.itemIndex);
    props.setIsOpen(false);
    props.handleAdd && props.handleAdd();
  };

  const formatValue = (itemType: SurveyItemType) => {
    return { value: itemType, label: t(`builder.${itemType}`) };
  };

  const options = Object.values(SurveyItemType)
    .filter((value) => !(value === SurveyItemType.Intro || value === SurveyItemType.Outro))
    .map((value) => {
      return formatValue(value);
    });

  return (
    <Modal {...props} title={t('survey.create.modal_title')} size={'m'}>
      <BodyWrapper>
        {Object.entries(options).map(([, surveyItem], index) => (
          <Row key={index}>
            <Col>
              <StyledButton onClick={() => handleSubmit(surveyItem)}>
                {surveyItem.label}
              </StyledButton>
            </Col>
          </Row>
        ))}
      </BodyWrapper>
    </Modal>
  );
};

export default withLoader(AddSurveyItemModal);
