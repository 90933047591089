import React from 'react';
import { Navigate, useLocation, matchPath } from 'react-router-dom';
import InvisiblePermissions from 'helpers/auth/invisiblePermissions';
import usePermissions from 'helpers/usePermissions';
import { RouteList } from 'routeList';

interface Props {
  component: React.FC<any> | React.ComponentClass<any>;
  layout: React.FC<any> | React.ComponentClass<any>;
  permissions: Array<string>;
}

const AuthPage: React.FC<Props> = ({ component: Component, layout: Layout, permissions }) => {
  const { hasAny, doesNotHaveAny } = usePermissions();

  const { pathname } = useLocation();

  const isEmailVerified = () => {
    return hasAny(InvisiblePermissions.USER.VERIFIED);
  };

  const isInAnyAuthPage = () => {
    return Object.values(RouteList.AUTHENTICATION).find((value) => matchPath(value.path, pathname));
  };

  const isInAnyCommonPage = () => {
    return Object.values(RouteList.COMMON).find((values) => matchPath(values.path, pathname));
  };

  const isInRegisterClientPage = () => {
    return matchPath(pathname, RouteList.AUTHENTICATION.REGISTER_CLIENT.path);
  };

  const isInPageWithoutPermissions = (): boolean => {
    const permissionList = Array.isArray(permissions) ? permissions : [permissions];
    return doesNotHaveAny(permissionList);
  };

  const hasClient = () => {
    return hasAny(InvisiblePermissions.USER.HAS_CLIENT);
  };

  if (!isEmailVerified() && !isInAnyAuthPage() && !isInAnyCommonPage()) {
    return <Navigate to={RouteList.AUTHENTICATION.LOGIN.path} />;
  }

  if (!isEmailVerified() && isInRegisterClientPage()) {
    return <Navigate to={RouteList.AUTHENTICATION.LOGIN.path} />;
  }

  if (isEmailVerified() && !hasClient() && !isInRegisterClientPage()) {
    return <Navigate to={RouteList.AUTHENTICATION.REGISTER_CLIENT.path} />;
  }

  if (isEmailVerified() && hasClient() && isInAnyAuthPage()) {
    return <Navigate to={RouteList.DASHBOARD.path} />;
  }

  if (isInPageWithoutPermissions() && isEmailVerified() && hasClient()) {
    return <Navigate to={RouteList.DASHBOARD.path} />;
  }

  return (
    <Layout>
      <Component />
    </Layout>
  );
};
export default AuthPage;
