import styled from 'styled-components';
import { Nav as ReactStrapNav } from 'reactstrap';
import { breakpointDown } from '@btc-snxt/ui';

export const Nav = styled(ReactStrapNav)`
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  height: calc(100% - 92px);
  overflow: overlay;

  @-moz-document url-prefix() {
    overflow: auto;
  }

  ${breakpointDown.MD} {
    height: 100%;
  }
`;
