import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FieldArray, FormikProps, useFormikContext } from 'formik';
import { DropdownItemWrapper, IconAdd, IconClose, IconRemove, SecondaryButton } from '@btc-snxt/ui';
import { UpdateMembershipRequest } from 'api/types/membership/membership';
import { MembershipDropdown } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipDropdown';
import {
  StyledBenefitsWrapper,
  StyledDeleteButton,
  StyledInput,
  StyledInputRow,
  StyledLabel,
} from './MembershipBenefitsForm.style';

interface Props {
  inputsLocale: string;
}

const MembershipBenefitsForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { values, setFieldValue }: FormikProps<UpdateMembershipRequest> = useFormikContext();

  const initialTranslatableInput = { en: '', lt: '', ru: '' };

  return (
    <StyledBenefitsWrapper>
      <FieldArray
        name={'benefits'}
        render={(arrayHelper) => (
          <>
            <StyledInputRow>
              <SecondaryButton
                onClick={() =>
                  arrayHelper.push({
                    labels: initialTranslatableInput,
                    is_active: false,
                  })
                }
              >
                <IconAdd style={{ marginRight: '0.5rem' }} />

                {t('membership.form.add_new')}
              </SecondaryButton>
            </StyledInputRow>

            {values.benefits.map((benefit, index) => (
              <Fragment key={index}>
                <StyledInputRow>
                  <StyledLabel>
                    <MembershipDropdown
                      currentText={benefit.is_active ? <IconAdd /> : <IconClose />}
                    >
                      <DropdownItemWrapper
                        onClick={() => setFieldValue(`benefits[${index}].is_active`, true)}
                      >
                        <IconAdd />
                      </DropdownItemWrapper>

                      <DropdownItemWrapper
                        onClick={() => setFieldValue(`benefits[${index}].is_active`, false)}
                      >
                        <IconClose />
                      </DropdownItemWrapper>
                    </MembershipDropdown>
                  </StyledLabel>

                  <StyledInput>
                    <Field
                      type={'text'}
                      id={`benefits[${index}].labels[${props.inputsLocale}]`}
                      name={`benefits[${index}].labels[${props.inputsLocale}]`}
                      className={'form-control'}
                      maxLength={20}
                    />
                  </StyledInput>

                  <StyledDeleteButton type={'button'} onClick={() => arrayHelper.remove(index)}>
                    <IconRemove />
                  </StyledDeleteButton>
                </StyledInputRow>

                <ErrorMessage
                  component={'div'}
                  className={'text-danger'}
                  name={`benefits[${index}].labels[${props.inputsLocale}]`}
                />
              </Fragment>
            ))}
          </>
        )}
      />
    </StyledBenefitsWrapper>
  );
};

export default MembershipBenefitsForm;
