const queries = {
  admin: 'admin',
  category: 'category',
  client: 'client',
  common: 'common',
  dashboard: 'dashboard',
  filter: 'filter',
  membership: 'membership',
  privacy_policy: 'privacy_policy',
  profile: 'profile',
  role: 'role',
  settings: 'settings',
  survey: 'survey',
  table: 'table',
  translations: 'translations',
  user: 'user',
} as const;

type Queries = typeof queries[keyof typeof queries];

export { queries };
export type { Queries };
