import { create } from 'zustand';
import { UserProfileResponse } from 'api/types/user/profile';

interface AppState {
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
  resetPermissions: () => void;

  client: UserProfileResponse | null;
  setClient: (client: UserProfileResponse | null) => void;

  isAppStarted: boolean;
  setAppStarted: (isAppLoading: boolean) => void;
}

const useAppState = create<AppState>()((set) => ({
  permissions: [],
  setPermissions: (permissions) => set(() => ({ permissions })),
  resetPermissions: () => set(() => ({ permissions: [] })),

  client: null,
  setClient: (client) => set(() => ({ client: client })),

  isAppStarted: false,
  setAppStarted: (isAppLoading) => set({ isAppStarted: isAppLoading }),
}));

export default useAppState;
