import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BOOTSTRAP_XL_CONTAINER_WIDTH, FixedCenteredSpinner, useWindowSize } from '@btc-snxt/ui';
import { RouteList } from 'routeList';
import { TabsDropdown } from 'components/TabsDropdown';
import { useTabsContext } from './TabsContext';
import { StyledTabHeader, StyledTabButton, StyledTabBody, StyledDropdownItem } from './Tabs.style';

export interface TabProps {
  title: string;
  content: JSX.Element | undefined;
  path: string;
}

interface TabsProps {
  tabs: TabProps[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { innerWidth } = useWindowSize();

  const tabsContext = useTabsContext();

  const [currentTabOptions, setCurrentTabOptions] = useState<TabProps>(tabs[0]);

  useEffect(() => {
    if (location.pathname === RouteList.SETTINGS.HOME.path) {
      navigateToRoute(RouteList.SETTINGS.NEWS.path);
    } else {
      Object.values(tabs).forEach((tab) => {
        tab.path === location.pathname &&
          setCurrentTabOptions({
            title: tab.title,
            content: tab.content,
            path: tab.path,
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const navigateToRoute = (path: string) => {
    if (currentTabOptions.path === path) {
      return;
    }

    tabsContext.setLoading(true);
    navigate(path);
  };

  const renderTabButton = (tab: TabProps, index: number) => (
    <StyledTabButton
      active={currentTabOptions.path === tab.path}
      onClick={() => navigateToRoute(tab.path)}
      key={index}
    >
      {tab.title}
    </StyledTabButton>
  );

  const renderDropdownItem = (tab: TabProps, index: number) => (
    <StyledDropdownItem key={index} onClick={() => navigateToRoute(tab.path)}>
      {tab.title}
    </StyledDropdownItem>
  );

  return (
    <>
      <StyledTabHeader>
        {innerWidth > BOOTSTRAP_XL_CONTAINER_WIDTH ? (
          tabs.map((tab, index) => tab.title && renderTabButton(tab, index))
        ) : (
          <TabsDropdown title={currentTabOptions.title}>
            {tabs.map((tab, index) => tab.title && renderDropdownItem(tab, index))}
          </TabsDropdown>
        )}
      </StyledTabHeader>

      {currentTabOptions.content && (
        <>
          {tabsContext.isLoading && <FixedCenteredSpinner />}

          <StyledTabBody
            style={{
              display: tabsContext.isLoading ? 'none' : 'initial',
            }}
          >
            {currentTabOptions.content}
          </StyledTabBody>
        </>
      )}
    </>
  );
};

export default Tabs;
