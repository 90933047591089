import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  H1,
  LanguageDropdown,
  Locale,
  PrimaryButton,
  WysiwygEditor,
  error,
  success,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { PrivacyPolicyRequest } from 'api/types/privacy-policy/privacy-policy';
import { useTabsContext } from 'components/Tabs/TabsContext';
import {
  StyledLanguagesDropdownWrapper,
  StyledPrivacyPolicy,
  StyledSaveButtonWrapper,
} from './PrivacyPolicy.style';

interface CurrentText extends PrivacyPolicyRequest {
  fetched: boolean;
}

const PrivacyPolicy: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [locale, setLocale] = useState<string>(i18n.language);

  const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

  const [currentText, setCurrentText] = useState<CurrentText>({
    fetched: false,
    text: {
      [Locale.LT]: '<p></p>',
      [Locale.EN]: '<p></p>',
      [Locale.RU]: '<p></p>',
    },
  });

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const tabsContext = useTabsContext();

  useEffect(() => {
    (async () =>
      await Api.privacyPolicy.privacyPolicy
        .get()
        .then((response) => setCurrentText({ ...response, fetched: true }))
        .then(() => tabsContext.setLoading(false)))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editorState) {
      setCurrentText((prev) => ({
        ...prev,
        text: {
          ...prev.text,
          [locale]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  useEffect(() => {
    const text = Object.entries(currentText.text).find((v) => v[0] === locale)?.[1];

    if (text) {
      const blockFromHtml = htmlToDraft(text);
      const { contentBlocks, entityMap } = blockFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentText.fetched, locale]);

  const handleSubmit = async () => {
    let fail = false;
    setButtonLoading(true);

    await Api.privacyPolicy.privacyPolicy
      .update(currentText)
      .catch(() => {
        fail = true;
        error(t('common.error'));
      })
      .then(() => {
        setButtonLoading(false);
        !fail && success(t('common.success'));
      });
  };

  return (
    <StyledPrivacyPolicy>
      <H1>{t('settings.privacy_policy')}</H1>

      <StyledLanguagesDropdownWrapper>
        <LanguageDropdown setLocale={setLocale} />
      </StyledLanguagesDropdownWrapper>

      <WysiwygEditor editorState={editorState} setEditorState={setEditorState} />

      <StyledSaveButtonWrapper>
        <PrimaryButton disabled={isButtonLoading} onClick={handleSubmit} type={'button'}>
          {t('common.save')}
        </PrimaryButton>
      </StyledSaveButtonWrapper>
    </StyledPrivacyPolicy>
  );
};

export default PrivacyPolicy;
