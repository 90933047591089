import React, { createContext, useContext, Dispatch, SetStateAction } from 'react';
import {
  MembershipListResponse,
  MembershipResponse as MembershipContextProps,
} from 'api/types/membership/membership';
import { useMembershipQuery } from 'api/queries/membership/useMembershipQuery';

interface MembershipSettingsContextProps {
  data: MembershipListResponse;
  setData: Dispatch<SetStateAction<MembershipListResponse>>;
  isLoading: boolean;
}

const MembershipSettingsContext = createContext<MembershipSettingsContextProps>(undefined as any);

const MembershipContext = createContext<MembershipContextProps>(undefined as any);

const MembershipSettingsProvider: React.FC = (props) => {
  const { data, setQueryData, isLoading } = useMembershipQuery();

  return (
    <MembershipSettingsContext.Provider
      value={{ data: data ?? [], setData: setQueryData, isLoading }}
    >
      {props.children}
    </MembershipSettingsContext.Provider>
  );
};

const MembershipProvider: React.FC<MembershipContextProps> = (props) => {
  return <MembershipContext.Provider value={props}>{props.children}</MembershipContext.Provider>;
};

const useMembershipSettingsContext = (): MembershipSettingsContextProps => {
  return useContext(MembershipSettingsContext);
};

const useMembershipContext = (): MembershipContextProps => {
  return useContext(MembershipContext);
};

export type { MembershipSettingsContextProps, MembershipContextProps };
export {
  MembershipSettingsProvider,
  MembershipProvider,
  useMembershipSettingsContext,
  useMembershipContext,
};
