import jwt_decode from 'jwt-decode';
import { DataStorage } from '@btc-snxt/system';
import { isAuthJwtObject } from './Auth.guards';
import { AuthJwtObject, JwtInterface, jwtStorageKey } from './Auth.types';

class AuthUtils {
  private static readonly defaultState: AuthJwtObject = {
    token: '',
    expires: 0,
  };

  public static getJwt = (): AuthJwtObject => {
    const jwt = DataStorage.get(jwtStorageKey);

    if (jwt === null) {
      return AuthUtils.defaultState;
    }

    return AuthUtils.make(jwt);
  };

  public static setJwt = (token: string | AuthJwtObject): void => {
    if (isAuthJwtObject(token)) {
      DataStorage.set(jwtStorageKey, token.token);
      return;
    }

    DataStorage.set(jwtStorageKey, token);
  };

  public static removeJwt = (): void => {
    DataStorage.remove(jwtStorageKey);
  };

  public static isExpired = (jwt?: AuthJwtObject): boolean => {
    return Date.now() > (jwt?.expires ?? AuthUtils.getJwt().expires);
  };

  public static getPayload = (): null | JwtInterface => {
    if (AuthUtils.isExpired()) {
      return null;
    }

    return AuthUtils.decode(AuthUtils.getJwt().token);
  };

  public static decode = (token: string): JwtInterface => jwt_decode<JwtInterface>(token);

  public static make = (token: string): AuthJwtObject => ({
    token,
    expires: AuthUtils.decode(token).exp * 1000,
  });
}

export { AuthUtils };
