import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { SurveyItemType, TemplateItem } from '@btc-snxt/survey';
import { SurveyEditorMode, SurveyStatus } from 'helpers/enums';
import { SurveyType } from '@btc-snxt/survey';
import { SurveySelectResponse } from 'api/types/survey';
import { EmailItem } from './modes/ShareMode/shareMethods/ShareByEmail/AddContactsCSV';

export interface SurveyEditorContextProps {
  id?: string;
  mode: SurveyEditorMode;
  setMode: (mode: SurveyEditorMode) => void;
  surveyType: SurveyType;
  status: SurveyStatus;
  setStatus: (status: SurveyStatus) => void;
  category: SurveySelectResponse | undefined;
  setCategory: (category: SurveySelectResponse | undefined) => void;
  isEditable: boolean;
  items: TemplateItem[];
  setItems: (items: TemplateItem[]) => void;
  addItem: (item: TemplateItem, index: number) => void;
  onValuesChanged: (id: string, values: any, color: string) => void;
  setItemType?: (id: string, type: SurveyItemType) => void;
  onMoveUp?: (id: string) => void;
  onMoveDown?: (id: string) => void;
  onRemove?: (id: string) => void;
  setGlobalColor: (hex: string) => void;
  color: string;
  receivers: EmailItem[];
  setReceivers: (emails: EmailItem[]) => void;
  emailLogo: string;
  setEmailLogo: (files: any) => void;
  logo: string;
  setLogo: (files: any) => void;
  setLogoObject: (files: any) => void;
  logoObject: any;
  name: string;
  setName: (name: string) => void;
  availableLanguages: Array<string>;
  setAvailableLanguages: Dispatch<SetStateAction<Array<string>>>;
  isAvailableLanguegesLoading: boolean;
  setAvailableLanguegesLoading: Dispatch<SetStateAction<boolean>>;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const SurveyEditorContext = createContext<SurveyEditorContextProps>({
  mode: SurveyEditorMode.Preview,
  setMode: () => {},
  surveyType: SurveyType.IndividualSurvey,
  status: SurveyStatus.Draft,
  setStatus: () => {},
  category: {} as SurveySelectResponse,
  setCategory: () => {},
  isEditable: true,
  items: [],
  setItems: () => {},
  addItem: () => {},
  setItemType: () => {},
  onValuesChanged: () => {},
  setGlobalColor: () => {},
  color: '#FFFFFF',
  receivers: [],
  setReceivers: () => {},
  emailLogo: '',
  setEmailLogo: () => {},
  logo: '',
  setLogo: () => {},
  setLogoObject: () => {},
  logoObject: {},
  name: '',
  setName: () => {},
  availableLanguages: [],
  setAvailableLanguages: () => {},
  isAvailableLanguegesLoading: true,
  setAvailableLanguegesLoading: () => {},
});

export const useSurveyEditor = (): SurveyEditorContextProps => {
  return useContext(SurveyEditorContext);
};
