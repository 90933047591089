import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalProps, Modal, LoadingState, withLoader } from '@btc-snxt/ui';
import { StyledTextBody } from 'layouts/AuthenticationLayout/AuthenticationLayout.style';

const TermsOfServiceModal: React.FC<BaseModalProps & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  const handleOpen = async () => {
    props.setIsLoading(true);
    props.setIsLoading(false);
  };

  return (
    <Modal {...props} title={t('tos.title')} onOpened={handleOpen} size={'xl'}>
      <StyledTextBody>{t('tos.text')}</StyledTextBody>
    </Modal>
  );
};

export default withLoader(TermsOfServiceModal);
