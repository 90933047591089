import styled from 'styled-components';
import { breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledSidebarMobile = styled.div``;
export const StyledSidebarFullscreen = styled.div``;

export const StyledGenericLayout = styled.div`
  display: flex;
  flex-direction: column;

  // Works only on Firefox
  * {
    scrollbar-width: thin;
  }

  // Works only on Chrome, Edge, Safari and Opera
  *::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }
  *:hover::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grey300};
    border-radius: 1.25rem;
  }

  ${breakpointDown.MD} {
    ${StyledSidebarFullscreen} {
      display: none;
    }
  }
  ${breakpointUp.MD} {
    ${StyledSidebarMobile} {
      display: none;
    }
  }
`;
