import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, GlobalBody } from '@btc-snxt/ui';
import { BodyWrapper, StyledBody as Body } from './components/Body/Body.style';
import { Header } from './components/Header';
import { SidebarFullScreen, SidebarMobile } from './components/Sidebar';
import { useGenericLayoutStore } from './GenericLayout.store';
import {
  StyledGenericLayout,
  StyledSidebarFullscreen,
  StyledSidebarMobile,
} from './GenericLayout.style';

const GenericLayout: React.FC = ({ children }) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const sidebarState = useGenericLayoutStore();

  useEffect(() => {
    if (bodyRef.current != null) {
      bodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <>
      <GlobalBody color={Theme.colors.grey300} />
      <StyledGenericLayout>
        <Header />

        <div>
          <StyledSidebarMobile>
            <SidebarMobile />
          </StyledSidebarMobile>

          <StyledSidebarFullscreen>
            <SidebarFullScreen />
          </StyledSidebarFullscreen>

          <Body className={sidebarState.isSidebarOpen ? 'open' : ''}>
            <BodyWrapper ref={bodyRef}>{children}</BodyWrapper>
          </Body>
        </div>
      </StyledGenericLayout>
    </>
  );
};
export default GenericLayout;
