import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { PrimaryButton, Dropdown, DropdownItemWrapper, IconAdd, Table } from '@btc-snxt/ui';
import { Api } from 'api';
import { useTableQuery } from 'api/queries/table/useTableQuery';
import { PaginationDataFilter } from 'api/types/app/pagination';
import { AddUserModal, ConfirmDeleteModal, UserInfoModal } from 'pages/Modal/Users';
import { UserSettingsPageWrapper } from './Users.style';

const Users: React.FC = () => {
  const { t } = useTranslation();

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();
  const [shouldFetchData, setShouldFetchData] = useState<number>(0);
  const refetchData = () => setShouldFetchData((prevState: number) => prevState + 1);

  const { data, isLoading } = useTableQuery(
    ['users-list', tableQueryRequest, shouldFetchData],
    () => Api.user.user.filterUsers(tableQueryRequest),
  );

  const [userId, setUserId] = useState<string>('');

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const toggleInfoModal = () => setInfoModalOpen(!isInfoModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('common.number'),
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('label.user_name'),
        accessor: 'user_name',
        sortType: 'string',
      },
      {
        Header: t('label.user_type'),
        accessor: 'position',
        sortType: 'string',
        Cell: (cell: Cell) => cell.row.values.position.join(', '),
      },
      {
        Header: t('label.email'),
        accessor: 'email',
        sortType: 'string',
      },
      {
        Header: t('label.mobile'),
        accessor: 'mobile',
        sortType: 'string',
      },
      {
        Header: t('label.updated_at'),
        accessor: 'updated_at',
        sortType: 'date',
        Cell: (cell: Cell) =>
          t('common.date_full', {
            date: cell.row.values.updated_at,
          }),
      },
      {
        Header: t('common.more'),
        Cell: (cell: Cell) => (
          <Dropdown>
            <DropdownItemWrapper
              onClick={() => {
                setUserId(cell.row.values.id);
                toggleInfoModal();
              }}
            >
              {t('common.edit')}
            </DropdownItemWrapper>

            <DropdownItemWrapper
              onClick={() => {
                setUserId(cell.row.values.id);
                toggleDeleteModal();
              }}
            >
              {t('common.delete')}
            </DropdownItemWrapper>
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <UserSettingsPageWrapper>
      <PrimaryButton onClick={toggleAddModal}>
        <IconAdd /> {t('user.add_user')}
      </PrimaryButton>

      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        columns={columns}
        hiddenColumns={['id']}
        data={data}
        showPageSizes={true}
      />

      <AddUserModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        handleAdd={refetchData}
      />

      {userId && (
        <>
          <UserInfoModal
            userId={userId}
            isOpen={isInfoModalOpen}
            setIsOpen={setInfoModalOpen}
            toggle={toggleInfoModal}
            size={'md'}
            editableOnOpen={true}
            handleUpdate={refetchData}
          />

          <ConfirmDeleteModal
            userId={userId}
            isOpen={isDeleteModalOpen}
            setIsOpen={setDeleteModalOpen}
            toggle={toggleDeleteModal}
            handleDelete={refetchData}
          />
        </>
      )}
    </UserSettingsPageWrapper>
  );
};

export default Users;
