import { FileUpload, hexToRGB, PoweredBySeeNext } from '@btc-snxt/survey';
import { CheckboxInput, IconRemove, PrimaryButton, success, warning } from '@btc-snxt/ui';
import { Api } from 'api';
import { useSurveyEditor } from 'pages/Common/Survey/SurveyEditor/SurveyEditorContext';
import React, { useEffect, useState } from 'react';
import CSVReader from 'react-csv-reader';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import {
  AddContactsCSVWrapper,
  AddEmailText,
  CSVReaderWrapper,
  EmailBody,
  EmailFooter,
  EmailLogoUpload,
  EmailStyleWrapper,
  SendButtonWrapper,
  Table,
  TableWrapper,
  TextArea,
} from './ShareByEmail.style';

export interface EmailItem {
  id: number;
  email: string;
  name: string;
  checked: boolean;
}

const AddContactsCSV: React.FC = () => {
  const { t } = useTranslation();
  const context = useSurveyEditor();
  const [emailList, setEmailList] = useState<EmailItem[]>(context.receivers);
  const [globalCheck, setGlobalCheck] = useState<boolean>(true);
  const [text, setText] = useState<string>(t('email_share.text'));
  const [logo, setLogo] = useState<any>(undefined);

  const handleSubmit = async () => {
    const checkedEmailList: EmailItem[] = [];
    emailList.forEach((email) => {
      if (email.checked) {
        checkedEmailList.push(email);
      }
    });
    if (context.status != 'active') {
      warning(t('email_share.not_active'));
      return;
    }
    if (checkedEmailList.length > 0) {
      await Api.survey.survey
        .sendLinkToEmails(context.id!, checkedEmailList, text, context.color)
        .then(() => success(t('email_share.success')));
    } else {
      warning(t('email_share.no_emails'));
    }
  };

  useEffect(() => {
    context.setReceivers(emailList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailList]);

  const uploadLogo = async () => {
    await Api.common.common
      .uploadEmailLogo(context.id!, logo)
      .then(() => success(t('email_share.success')));
  };

  useEffect(() => {
    if (logo) {
      uploadLogo();
      context.setEmailLogo(logo[0].preview);
    } else if (logo == '') {
      context.setEmailLogo('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const parseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
  };

  const openFileSelect = (): void => {
    const CSVReaderButton = document!.getElementById('fileSelect');
    if (CSVReaderButton) {
      CSVReaderButton.click();
    }
  };

  const addEmailField = () => {
    const id = emailList.length;
    const newElement: EmailItem = { id: id, email: '', name: '', checked: true };
    const copyEmailList = [...emailList];
    copyEmailList.unshift(newElement);
    setEmailList(copyEmailList);
  };

  const addElementsFromFile = (data: Array<Array<any>>) => {
    data.shift();
    setEmailList((prevState) => [
      ...data.map((item) => ({
        id: item[0] ?? 0,
        email: item[1] ?? '',
        name: item[2] ?? '',
        checked: true,
      })),
      ...prevState,
    ]);
  };

  const setAllEmailsChecked = () => {
    const checked = !globalCheck;
    setGlobalCheck(checked);
    const copyEmailList = [...emailList];
    copyEmailList.forEach((email, index) => {
      copyEmailList[index].checked = checked;
    });

    setEmailList(copyEmailList);
  };

  const changeEmailInputValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const copyEmailList = [...emailList];
    copyEmailList[index].email = event.target.value;
    setEmailList(copyEmailList);
  };

  const changeNameInputValue = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const copyEmailList = [...emailList];
    copyEmailList[index].name = event.target.value;
    setEmailList(copyEmailList);
  };

  const changeCheckboxInputValue = (currentValue: boolean, index: number) => {
    const copyEmailList = [...emailList];
    copyEmailList[index].checked = !currentValue;
    setEmailList(copyEmailList);
  };

  const removeEmail = (index: number): void => {
    const emailListCopy = [...emailList];
    emailListCopy.splice(index, 1);
    setEmailList(emailListCopy);
  };

  const processData = (): JSX.Element =>
    emailList && (
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <CheckboxInput checked={globalCheck} onChange={setAllEmailsChecked} />
              </th>
              <th>{'#'}</th>
              <th>{t('email_share.email')}</th>
              <th>{t('email_share.name')}</th>
              <th>{t('email_share.remove')}</th>
            </tr>
          </thead>
          <tbody>
            {emailList.map((value: EmailItem, index) => (
              <tr key={index}>
                <td>
                  <CheckboxInput
                    checked={value.checked}
                    onChange={() => {
                      changeCheckboxInputValue(value.checked, index);
                    }}
                  />
                </td>
                <td>{index + 1}</td>
                <td>
                  <Input
                    value={value.email}
                    onChange={(event) => {
                      changeEmailInputValue(event, index);
                    }}
                  ></Input>
                </td>
                <td>
                  <Input
                    value={value.name}
                    onChange={(event) => {
                      changeNameInputValue(event, index);
                    }}
                  ></Input>
                </td>
                <td>
                  <IconRemove onClick={() => removeEmail(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    );

  return (
    <>
      <AddContactsCSVWrapper>
        <CSVReaderWrapper>
          <AddEmailText onClick={addEmailField}>+ {t('email_share.add')}</AddEmailText>
          <CSVReader
            inputId="fileSelect"
            parserOptions={parseOptions}
            onFileLoaded={addElementsFromFile}
          />
          <PrimaryButton onClick={openFileSelect}>{t('email_share.upload')}</PrimaryButton>
        </CSVReaderWrapper>

        {processData()}
      </AddContactsCSVWrapper>

      <div>
        <EmailStyleWrapper>
          <EmailLogoUpload $backgroundColor={hexToRGB(context.color)}>
            <FileUpload type={'medium'} emailLogo={context.emailLogo} uploadedFile={setLogo} />
          </EmailLogoUpload>
          <EmailBody $backgroundColor={context.color}>
            <TextArea onChange={(e) => setText(e.target.value)} value={text} />
            <PrimaryButton>{t('email_share.participate')} </PrimaryButton>
          </EmailBody>
          <EmailFooter $backgroundColor={context.color}>
            <div>{t('email_share.copyright')}</div>
            <PoweredBySeeNext />
          </EmailFooter>
        </EmailStyleWrapper>
        <SendButtonWrapper>
          <PrimaryButton onClick={handleSubmit}>{t('email_share.send')}</PrimaryButton>
        </SendButtonWrapper>
      </div>
    </>
  );
};

export default AddContactsCSV;
