import React, { Ref, useEffect, useState } from 'react';
import { H5, H6, PrimaryButton, success } from '@btc-snxt/ui';
import { useTranslation } from 'react-i18next';
import { Link } from '../ShareMode';
import {
  ShareLinkWrapper,
  StyledShareLink,
  StyledShareLinkItem,
  StyledSuccessMessage,
} from './ShareLink.style';

interface Props {
  links: any;
}

const ShareLink: React.FC<Props> = (props) => {
  const [links, setLinks] = useState<Link[]>(props.links);
  const { t } = useTranslation();
  const inputRefs = links.map(() => React.createRef());

  useEffect(() => {
    setLinks(props.links);
  }, [props.links]);

  const onButtonClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
    success(<StyledSuccessMessage>{t('survey.link_share.copy_success')}</StyledSuccessMessage>);
  };

  const returnLinks = () => {
    return (
      <>
        <H5>{t('survey.link_share.link')}</H5>
        {links.map((link: Link, index: number) => (
          <H6 key={link.name}>
            {link.name}
            <ShareLinkWrapper>
              <StyledShareLinkItem
                id={link.name}
                value={link.url}
                innerRef={
                  inputRefs[index] as Ref<HTMLInputElement | HTMLTextAreaElement> | undefined
                }
                readOnly
              />
              <PrimaryButton
                onClick={() => onButtonClick(inputRefs[index] as React.RefObject<HTMLInputElement>)}
              >
                {t('survey.link_share.copy')}
              </PrimaryButton>
            </ShareLinkWrapper>
          </H6>
        ))}
      </>
    );
  };

  return <StyledShareLink>{returnLinks()}</StyledShareLink>;
};

export default ShareLink;
