import { create } from 'zustand';
import { GenericLayoutStoreActions, GenericLayoutStoreObject } from './GenericLayout.types';

type Store = GenericLayoutStoreObject & GenericLayoutStoreActions;

const useGenericLayoutStore = create<Store>((set) => {
  return {
    isSidebarOpen: true,
    setIsSidebarOpen: ({ state }: { state: boolean }) => set(() => ({ isSidebarOpen: state })),
    reset: () => set({ isSidebarOpen: true }),
  };
});

export { useGenericLayoutStore };
