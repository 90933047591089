import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { PrivacyPolicyResponse } from 'api/types/privacy-policy/privacy-policy';

const usePrivacyPolicyQuery = (
  queryKey: number,
  options?: UseQueryOptionsObject<PrivacyPolicyResponse>,
) =>
  useMakeQuery<PrivacyPolicyResponse>(
    [queries.privacy_policy, 'get', queryKey],
    () => Api.privacyPolicy.privacyPolicy.get(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { usePrivacyPolicyQuery };
