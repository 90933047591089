import styled from 'styled-components';
import { Icon } from '@btc-snxt/ui';

export const StyledLogin = styled.div``;

export const StyledForgotPassword = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 0.375rem;

  a {
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
  }
`;

export const StyledSocialIconsBlock = styled.div`
  display: flex;
  justify-content: center;

  ${Icon} {
    height: 1rem;
    width: 1rem;
    margin: 0.75rem 0.5rem 0;
  }
`;

export const StyledSocialButton = styled.button`
  background-color: transparent;
  border: none;
`;
