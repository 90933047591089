import Client from '../client';
import { PaginationData, PaginationDataFilter } from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';
import {
  CreateUserRequest,
  UpdateUserRequest,
  UserResponse,
  UsersTableResponse,
} from 'api/types/user/user';

class User extends Client {
  api = {
    USER_LIST: `${this.http.baseUrl}/users`,
    USER_CREATE: `${this.http.baseUrl}/users/user`,
    USER: `${this.http.baseUrl}/users/user/{user}`,
  };

  add = (data: CreateUserRequest): Promise<UserResponse> => {
    return this.http.post(this.api.USER_CREATE, data);
  };

  fetch = (user: string): Promise<UserResponse> => {
    return this.http.get(this.buildUrl(this.api.USER, { user }));
  };

  filterUsers = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<UsersTableResponse>> => {
    return this.http.get(this.api.USER_LIST, { params: request });
  };

  edit = (user: string, data: UpdateUserRequest): Promise<UserResponse> => {
    return this.http.put(this.buildUrl(this.api.USER, { user }), data);
  };

  delete = (user: string): Promise<EmptyResponse> => {
    return this.http.delete(this.buildUrl(this.api.USER, { user }));
  };
}

export default User;
