import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { UserResponse } from 'api/types/user/profile';

const useUserQuery = (userId: string, options?: UseQueryOptionsObject<UserResponse>) =>
  useMakeQuery<UserResponse>([queries.user, userId], () => Api.user.user.fetch(userId), {
    cacheTime: 1000,
    retry: false,
    ...defaultOptions,
    ...(options ?? {}),
  });

export { useUserQuery };
