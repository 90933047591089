import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, FormModal, BaseModalProps, Row, Label } from '@btc-snxt/ui';
import { CreateCategoryRequest } from 'api/types/category/category';
import { BodyWrapper } from 'pages/Modal/Users/AddUserModal.style';
import { useSurveyEditor } from 'pages/Common/Survey/SurveyEditor/SurveyEditorContext';
import { useNavigate } from 'react-router-dom';
import { SurveyTemplateInfo } from 'pages/Common/Survey/SelectSurveyType/SelectSurveyType';
import { PrimaryButton } from '@btc-snxt/ui';

interface Props extends BaseModalProps {
  selectedSurveyType?: SurveyTemplateInfo;
}

const SurveyNameModal: React.FC<Props> = ({ selectedSurveyType, ...props }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = useSurveyEditor();
  const [formData] = useState<CreateCategoryRequest>({
    name: '',
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(t('survey.create.name_required')),
  });

  const handleSubmit = async <T extends CreateCategoryRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    if (selectedSurveyType?.path) {
      try {
        context.setName(values.name);
        props.setIsOpen(false);
        navigate(selectedSurveyType.path, { state: { name: values.name } });
      } catch (e: any) {
        helper.setErrors(e.response.errors);
      }
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.add_survey_name')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
      components={{
        Footer: ({ isSubmitting }) => {
          return (
            <>
              <PrimaryButton disabled={isSubmitting} type={'submit'}>
                {isSubmitting ? `${t('common.loading')}...` : t('survey.create.continue')}
              </PrimaryButton>
            </>
          );
        },
      }}
    >
      <BodyWrapper>
        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('survey.create.survey_name_label')} required={false} />
            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('survey.create.survey_name_placeholder')}
              className={'form-control'}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default SurveyNameModal;
