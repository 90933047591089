import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModalProps, LoadingState, Modal, PrimaryButton, withLoader } from '@btc-snxt/ui';
import { Api } from 'api';
import { BillingFrequency } from 'api/types/membership/membership';
import { navigateToExternalUrl } from 'helpers/common';
import { MembershipSlider } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipSlider';
import { useMembershipContext } from 'pages/Common/Settings/tabs/MembershipSettings/MembershipSettingsContext';
import Icons from 'pages/Common/Settings/tabs/MembershipSettings/services/icon';
import {
  StyledCircle,
  StyledNameText,
  StyledIcon,
  StyledPrice,
  StyledPriceBlock,
  StyledPriceText,
  StyledSubscribeBody,
  StyledBillingText,
  StyledLabel,
} from './MembershipSubscribeModal.style';

interface SelectableBilling {
  price: number;
  priceText: string;
  labelText: string;
  name: BillingFrequency;
}

const MembershipSubscribeModal: React.FC<BaseModalProps & LoadingState> = (props) => {
  const { t, i18n } = useTranslation();

  const currentMembership = useMembershipContext();
  const membershipIcons = new Icons();

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedBillingIndex, setSelectedBilling] = useState<number[]>([0]);

  const selectableBillings: SelectableBilling[] = useMemo(() => {
    const newSelectableBillings: SelectableBilling[] = [];

    if (currentMembership.monthly_price != 0) {
      newSelectableBillings.push({
        price: currentMembership.monthly_price,
        priceText: t('membership.subscribe.price_month'),
        labelText: t('membership.subscribe.label_month'),
        name: BillingFrequency.Monthly,
      });
    }

    if (currentMembership.quarterly_price != 0) {
      newSelectableBillings.push({
        price: currentMembership.quarterly_price,
        priceText: t('membership.subscribe.price_quarter'),
        labelText: t('membership.subscribe.label_quarter'),
        name: BillingFrequency.Quarterly,
      });
    }

    if (currentMembership.semi_yearly_price != 0) {
      newSelectableBillings.push({
        price: currentMembership.semi_yearly_price,
        priceText: t('membership.subscribe.price_semi_year'),
        labelText: t('membership.subscribe.label_semi_yearly'),
        name: BillingFrequency.SemiYearly,
      });
    }

    if (currentMembership.yearly_price != 0) {
      newSelectableBillings.push({
        price: currentMembership.yearly_price,
        priceText: t('membership.subscribe.price_year'),
        labelText: t('membership.subscribe.label_yearly'),
        name: BillingFrequency.Yearly,
      });
    }

    return newSelectableBillings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMembership]);

  const selectedBilling = selectableBillings[selectedBillingIndex[0]];

  const handleButtonClick = async () => {
    setLoading(true);
    const response = await Api.membership.membership.subscribe(currentMembership.id, {
      billing_frequency: selectedBilling.name,
    });
    navigateToExternalUrl(response.payment_url);
  };

  return (
    <Modal
      {...props}
      title={t('membership.subscribe.title')}
      size={'sm'}
      components={{
        Footer: () => {
          return (
            <PrimaryButton onClick={handleButtonClick} disabled={loading}>
              {loading ? t('common.loading') : t('membership.subscribe.confirm_button')}
            </PrimaryButton>
          );
        },
      }}
    >
      <StyledSubscribeBody>
        <StyledNameText>{currentMembership.name[i18n.language]}</StyledNameText>

        <StyledIcon>
          <StyledCircle>{membershipIcons.getIcon(currentMembership.icon_id)?.icon}</StyledCircle>
        </StyledIcon>

        <StyledPriceBlock>
          <StyledPrice>€{selectedBilling.price}</StyledPrice>

          <StyledPriceText>{selectedBilling.priceText}</StyledPriceText>
        </StyledPriceBlock>

        <StyledBillingText>{t('membership.subscribe.billing_frequency')}</StyledBillingText>

        {selectableBillings.length > 1 && (
          <MembershipSlider
            max={selectableBillings.length - 1}
            values={selectedBillingIndex}
            setValues={setSelectedBilling}
          />
        )}

        <StyledLabel>{selectedBilling.labelText}</StyledLabel>
      </StyledSubscribeBody>
    </Modal>
  );
};

export default withLoader(MembershipSubscribeModal);
