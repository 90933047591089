import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { RoleResponse } from 'api/types/role/role';

const useRoleQuery = (roleId: string, options?: UseQueryOptionsObject<RoleResponse>) =>
  useMakeQuery<RoleResponse>([queries.role, roleId], () => Api.role.role.fetch(roleId), {
    staleTime: Infinity,
    retry: false,
    ...defaultOptions,
    ...(options ?? {}),
  });

export { useRoleQuery };
