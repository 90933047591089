import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Col,
  PrimaryButton,
  BaseModalProps,
  FormModal,
  ModalH3,
  Row,
  LoadingState,
  withLoader,
  success,
  BadgeSelect,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { useRolesQuery } from 'api/queries/role/useRolesQuery';
import { useCountriesQuery } from 'api/queries/common/useCountriesQuery';
import { CreateClientRequest } from 'api/types/user/user';
import { BodyWrapper } from './ClientInfoModal.style';

interface Props extends BaseModalProps, LoadingState {
  handleAdd?: () => void;
}

type Option = { label: string; value: any };

const ClientAddModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();

  const countries = useCountriesQuery();
  const roles = useRolesQuery();

  setTimeout(() => props.setIsLoading(roles.isLoading || countries.isLoading), 0);

  const options: Option[] =
    roles.data?.map((v) => ({
      value: v.name,
      label: v.name,
    })) ?? [];

  const [formData] = useState<CreateClientRequest>({
    address: '',
    category: '',
    city: '',
    client_email: '',
    client_mobile: '',
    company_name: '',
    country: '',
    legal_code: '',
    name: '',
    position: [''],
    surname: '',
    user_email: '',
    user_mobile: '',
    vat: '',
  });

  const FormSchema = Yup.object().shape({
    address: Yup.string().required(),
    category: Yup.string().required(),
    city: Yup.string().required(),
    client_email: Yup.string().email().required(),
    client_mobile: Yup.string().required(),
    company_name: Yup.string().required(),
    country: Yup.string().required(),
    legal_code: Yup.string().required(),
    name: Yup.string().required(),
    position: Yup.array().required(),
    surname: Yup.string().required(),
    user_email: Yup.string().email().required(),
    user_mobile: Yup.string().required(),
    vat: Yup.string().nullable(),
  });

  const handleSubmit = async <T extends CreateClientRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    helper.setSubmitting(true);
    try {
      await Api.admin.company.create(values);
      props.setIsOpen(false);
      props.handleAdd && props.handleAdd();
      success(t('client.create_success'));
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.create_client')}
      initialValues={formData}
      handleSubmit={handleSubmit}
      validationSchema={FormSchema}
      components={{
        Footer: () => {
          return <PrimaryButton type={'submit'}>{t('common.add')}</PrimaryButton>;
        },
      }}
    >
      <BodyWrapper>
        <Row>
          <Col xs={12} sm={6}>
            <Label htmlFor={'company_name'} text={t('label.company_name')} required={true} />

            <Field
              align={'left'}
              type={'text'}
              name={'company_name'}
              id={'company_name'}
              placeholder={t('label.company_name')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'company_name'} />
          </Col>

          <Col xs={12} sm={6}>
            <Label htmlFor={'category'} text={t('label.category')} required={true} />

            <Field
              type={'text'}
              name={'category'}
              id={'category'}
              placeholder={t('label.category')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'category'} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={4}>
            <Label htmlFor={'legal_code'} text={t('label.legal_code')} required={true} />

            <Field
              type={'text'}
              name={'legal_code'}
              id={'legal_code'}
              placeholder={t('label.legal_code')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'legal_code'} />
          </Col>

          <Col xs={6} sm={4}>
            <Label htmlFor={'country'} text={t('label.country')} required={true} />

            <Field as={'select'} name={'country'} id={'country'} className={'form-control'}>
              {countries.data &&
                Object.entries(countries.data).map(([code, translation]) => (
                  <option key={code} value={code}>
                    {translation}
                  </option>
                ))}
            </Field>

            <ErrorMessage component={'div'} className={'text-danger'} name={'country'} />
          </Col>

          <Col xs={6} sm={4}>
            <Label htmlFor={'city'} text={t('label.city')} required={true} />

            <Field
              type={'text'}
              name={'city'}
              id={'city'}
              placeholder={t('label.city')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'city'} />
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <Label htmlFor={'vat'} text={t('label.vat')} />

            <Field
              type={'text'}
              name={'vat'}
              id={'vat'}
              placeholder={t('label.vat')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'vat'} />
          </Col>

          <Col>
            <Label htmlFor={'address'} text={t('label.address')} required={true} />

            <Field
              type={'text'}
              name={'address'}
              id={'address'}
              placeholder={t('label.address')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'address'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'client_email'} text={t('label.email')} required={true} />

            <Field
              type={'text'}
              name={'client_email'}
              id={'client_email'}
              placeholder={t('label.email')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'client_email'} />
          </Col>

          <Col>
            <Label htmlFor={'client_mobile'} text={t('label.mobile')} required={true} />

            <Field
              type={'text'}
              name={'client_mobile'}
              id={'client_mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'client_mobile'} />
          </Col>
        </Row>

        <ModalH3>{t('label.responsible_person')}</ModalH3>

        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />

            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>

          <Col>
            <Label htmlFor={'surname'} text={t('label.surname')} required={true} />

            <Field
              type={'text'}
              name={'surname'}
              id={'surname'}
              placeholder={t('label.surname')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'surname'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'position'} text={t('label.position')} required={true} />

            <Field
              name={'position'}
              id={'position'}
              as={BadgeSelect}
              options={options}
              isMulti
              defaultValue={formData.position[0]}
              placeholder={t('label.position')}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'position'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'user_email'} text={t('label.email')} required={true} />

            <Field
              type={'email'}
              name={'user_email'}
              id={'user_email'}
              placeholder={t('label.email')}
              className={'form-control'}
            />
            <ErrorMessage component={'div'} className={'text-danger'} name={'user_email'} />
          </Col>

          <Col>
            <Label htmlFor={'user_mobile'} text={t('label.mobile')} required={true} />

            <Field
              type={'text'}
              name={'user_mobile'}
              id={'user_mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'user_mobile'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(ClientAddModal);
