import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AvailableLanguagesSelect,
  LanguageDropdown,
  PrimaryButton,
  success,
  Locale,
} from '@btc-snxt/ui';
import {
  Designer,
  SurveyItemType,
  ColorPickerInput,
  LoadingOverlay,
  getSurveyItemIndex,
  getSurveyItemsSize,
} from '@btc-snxt/survey';
import { Api } from 'api';
import { SurveyEditorMode, SurveyStatus } from 'helpers/enums';
import { useSurveyEditor } from '../../SurveyEditorContext';
import {
  StyledActionButtons,
  StyledAddIcon,
  StyledAddItemButton,
  StyledButtonsHeader,
  StyledButtonsSubheader,
  StyledItems,
  StyledAvailableLanguages,
  StyledAvailableLanguagesWrapper,
} from '../../SurveyEditor.style';
import { getUrl } from '../../SurveyEditor.utils';
import { AdditionalQuestionPicker } from './items';
import AddSurveyItemModal from 'pages/Modal/Survey/AddSurveyItemModal';

const DesignMode: React.FC = () => {
  const context = useSurveyEditor();
  const [locale, setLocale] = useState<string>(
    context.availableLanguages.includes(Locale.LT) ? Locale.LT : context.availableLanguages[0],
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [color, setColor] = useState<string>(context.color);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * initial index is set to 1 because by default index 0 belongs to intro page
   */
  const [itemIndex, setItemIndex] = useState<number>(1);
  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const [, setRender] = useState<number>(0);

  useEffect(() => {
    setLocale(
      context.availableLanguages.includes(Locale.LT) ? Locale.LT : context.availableLanguages[0],
    );
  }, [context.availableLanguages]);

  useEffect(() => {
    context.setGlobalColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    context.setLogo(context.logo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.logo]);

  useEffect(() => {
    if (context.status != SurveyStatus.Draft) {
      const url = getUrl(SurveyEditorMode.Preview, context.surveyType, context.id);
      navigate(url);
      context.setMode(SurveyEditorMode.Preview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.status]);

  useEffect(() => {
    if (context.availableLanguages) {
      setLocale(
        context.availableLanguages.includes(Locale.LT) ? Locale.LT : context.availableLanguages[0],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.availableLanguages]);

  const saveItem = async () => {
    const surveyId = context.id;
    const now = new Date();
    const year = now.getFullYear();
    const time = now.toLocaleTimeString();
    const formattedName = context.name || `${context.surveyType} ${year} ${time}`;
    setIsLoading(true);
    try {
      const formedData = {
        type: context.surveyType,
        status: 'draft',
        name: formattedName,
        items: context.items,
        available_languages: context.availableLanguages,
      };

      let response;
      if (surveyId == undefined) {
        response = await Api.survey.survey.createSurvey(formedData);
        if (context.logoObject.length > 0)
          await Api.common.common.uploadFile(response.id, context.logoObject);
      } else {
        response = await Api.survey.survey.updateSurvey(surveyId, formedData);
        if (context.logoObject.length > 0)
          await Api.common.common.uploadFile(response.id, context.logoObject);
      }
      success(t('survey.create.save_success'));
      context.setMode(SurveyEditorMode.Preview);
      const url = getUrl(SurveyEditorMode.Preview, context.surveyType, response.id);
      navigate(url);
    } catch (e: any) {}
  };

  return (
    <>
      <StyledActionButtons>
        <StyledButtonsHeader>
          <StyledButtonsSubheader>
            <PrimaryButton onClick={saveItem} disabled={context.status != SurveyStatus.Draft}>
              {t('common.save')}
            </PrimaryButton>

            <ColorPickerInput value={context.color} setGlobalColor={setColor} onChange={() => null}>
              {t('common.color')}
            </ColorPickerInput>

            <AdditionalQuestionPicker />
          </StyledButtonsSubheader>

          <StyledButtonsSubheader>
            <StyledAvailableLanguagesWrapper>
              <StyledAvailableLanguages>
                {t('builder.available_languages')}
              </StyledAvailableLanguages>

              <AvailableLanguagesSelect
                availableLanguages={context.availableLanguages}
                setAvailableLanguages={context.setAvailableLanguages}
                isLoading={context.isAvailableLanguegesLoading}
              />
            </StyledAvailableLanguagesWrapper>

            <LanguageDropdown
              setLocale={setLocale}
              availableLanguages={context.availableLanguages}
            />
          </StyledButtonsSubheader>
        </StyledButtonsHeader>
      </StyledActionButtons>

      <StyledItems>
        {context.items.map((item, index) => (
          <Fragment key={item.id}>
            {context.isEditable && item.type != SurveyItemType.Intro && (
              <StyledAddItemButton
                onClick={(event: MouseEvent) => {
                  (event.target as HTMLElement).blur();
                  setItemIndex(index);
                  setAddModalOpen(true);
                }}
              >
                <StyledAddIcon /> {t('builder.add')}
              </StyledAddItemButton>
            )}
            <Designer
              id={item.id}
              values={item.values ?? item.initialValues}
              type={item.type}
              onMoveUp={context.isEditable ? context.onMoveUp : undefined}
              onMoveDown={context.isEditable ? context.onMoveDown : undefined}
              onRemove={context.isEditable ? context.onRemove : undefined}
              setType={context.isEditable ? context.setItemType : undefined}
              onValuesChanged={context.onValuesChanged}
              locale={locale}
              color={context.color}
              size={getSurveyItemsSize(context.items)}
              questionIndex={index - getSurveyItemIndex(context.items, index)}
              itemIndex={index}
              isEditable={context.isEditable}
              setLogo={context.setLogo}
              logo={context.logo}
              setLogoObject={context.setLogoObject}
              logoObject={context.logoObject}
              surveyType={context.surveyType}
            />
          </Fragment>
        ))}
      </StyledItems>

      <AddSurveyItemModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        itemIndex={itemIndex}
        size={'md'}
        handleAdd={() => setRender((prevState) => prevState + 1)}
      />
      <LoadingOverlay isOpen={isLoading} />
    </>
  );
};

export default DesignMode;
