import { DataForSurveyController } from 'pages/Common/ResultAnalysis/ResultAnalysis';
import { FilterResponse } from 'api/types/filter/filter';
import Http from 'api/http';
import Client from 'api/services/client';

class Filter extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FILTERS_POST: `${this.http.baseUrl}/filter`,
    };
  }

  sendFilters = (data: DataForSurveyController): Promise<FilterResponse> => {
    return this.http.post(this.api.FILTERS_POST, data);
  };
}
export default Filter;
