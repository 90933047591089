import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import { DropdownWrapper, IconProfile } from '@btc-snxt/ui';
import ProfileModal from 'pages/Modal/Profile/ProfileModal';
import {
  StyledDropdownItem,
  StyledProfileButton,
  StyledProfileDropdown,
} from './ProfileDropdown.style';
import { useAuth } from 'helpers/useAuth/useAuth';
import { Api } from 'api';

const ProfileDropdown: React.FC = () => {
  const { t } = useTranslation();

  const { logout } = useAuth();

  const handleLogout = () => logout(Api.user.auth.logout);

  const [isOpen, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen(!isOpen);

  const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const toggleProfileModal = () => setProfileModalOpen(!isProfileModalOpen);

  return (
    <StyledProfileDropdown>
      <DropdownWrapper>
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <StyledProfileButton>
            <IconProfile />
          </StyledProfileButton>

          <DropdownMenu>
            <DropdownItem onClick={toggleProfileModal}>
              <StyledDropdownItem>{t('header.dropdown_profile')}</StyledDropdownItem>
            </DropdownItem>

            <DropdownItem>
              <StyledDropdownItem onClick={handleLogout}>
                {t('header.dropdown_logout')}
              </StyledDropdownItem>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </DropdownWrapper>

      <ProfileModal
        isOpen={isProfileModalOpen}
        setIsOpen={setProfileModalOpen}
        toggle={toggleProfileModal}
      />
    </StyledProfileDropdown>
  );
};

export default ProfileDropdown;
