import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, FormikHelpers } from 'formik';
import {
  BadgeSelect,
  BaseModalProps,
  Col,
  FormModal,
  Row,
  LoadingState,
  withLoader,
  PrimaryButton,
  SecondaryButton,
  success,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { UpdateUserRequest } from 'api/types/user/user';
import { useRolesQuery } from 'api/queries/role/useRolesQuery';
import { useUserQuery } from 'api/queries/user/useUserQuery';
import { BodyWrapper, FooterWrapper } from './AddUserModal.style';

interface Props extends BaseModalProps {
  userId: string;
  onUpdate?: () => void;
  editableOnOpen?: boolean;
}

type Option = { label: string; value: any };

const UserInfoModal: React.FC<Props & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  enum State {
    Default,
    Edit,
  }

  const [modalState, setModalState] = useState<State>(
    props.editableOnOpen ? State.Edit : State.Default,
  );

  const user = useUserQuery(props.userId);
  const roles = useRolesQuery();

  const options: Option[] | undefined =
    roles.data &&
    roles.data.map((v) => ({
      value: v.name,
      label: v.name,
    }));

  setTimeout(() => props.setIsLoading(user.isLoading || roles.isLoading), 0);

  const formData: UpdateUserRequest = {
    id: user.data?.id ?? '',
    name: user.data?.name ?? '',
    surname: user.data?.surname ?? '',
    position: user.data?.position ?? [],
    email: user.data?.email ?? '',
    mobile: user.data?.mobile ?? '',
  };

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
    surname: Yup.string().required(),
    position: Yup.array().required(),
    email: Yup.string().email().required(),
    mobile: Yup.string().required(),
  });

  const isDisabled = () => modalState == State.Default;

  const isEditable = () => modalState == State.Edit;

  const handleSubmit = async <T extends UpdateUserRequest>(values: T, helper: FormikHelpers<T>) => {
    helper.setSubmitting(true);
    try {
      await Api.user.user.edit(props.userId, values);
    } finally {
      props.setIsOpen(false);
      props.handleUpdate && props.handleUpdate();
      success(t('user.update_success'));
      setModalState(State.Default);
      helper.setSubmitting(false);
      user.setQueryData(values);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.user_info')}
      initialValues={formData}
      handleSubmit={handleSubmit}
      validationSchema={FormSchema}
      components={{
        Footer: ({ setIsOpen }) => {
          return (
            <FooterWrapper>
              <PrimaryButton
                type={isEditable() ? 'submit' : 'button'}
                onClick={() => {
                  !isEditable() && setModalState(State.Edit);
                }}
              >
                {isEditable() ? 'Save' : 'Edit'}
              </PrimaryButton>
              <SecondaryButton
                onClick={() => {
                  setIsOpen(false);
                  isEditable() && setModalState(State.Default);
                }}
              >
                {t('common.cancel')}
              </SecondaryButton>
            </FooterWrapper>
          );
        },
      }}
    >
      <BodyWrapper>
        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />

            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
              disabled={isDisabled()}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'surname'} text={t('label.surname')} required={true} />

            <Field
              type={'text'}
              name={'surname'}
              id={'surname'}
              placeholder={t('label.surname')}
              className={'form-control'}
              disabled={isDisabled()}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'surname'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'position'} text={t('label.position')} required={true} />

            <Field
              name={'position'}
              id={'position'}
              as={BadgeSelect}
              options={options ?? []}
              defaultValue={formData.position}
              placeholder={t('label.position')}
              isDisabled={isDisabled()}
              isMulti
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'position'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'email'} text={t('label.email')} required={true} />

            <Field
              type={'email'}
              name={'email'}
              id={'email'}
              placeholder={t('label.email')}
              className={'form-control'}
              disabled={isDisabled()}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'email'} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label htmlFor={'mobile'} text={t('label.mobile')} required={true} />

            <Field
              type={'text'}
              name={'mobile'}
              id={'mobile'}
              placeholder={t('label.mobile')}
              className={'form-control'}
              disabled={isDisabled()}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'mobile'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(UserInfoModal);
