import styled from 'styled-components';

export const StyledPrivacyPolicy = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledLanguagesDropdownWrapper = styled.div`
  align-self: start;
`;

export const StyledSaveButtonWrapper = styled.div`
  align-self: end;
`;
