import styled from 'styled-components';
import { Button, NavLink } from 'reactstrap';
import { ThemeType, SecondaryButton, breakpointDown, breakpointUp, Icon } from '@btc-snxt/ui';

export const BurgerButtonWrapper = styled.span``;

export const HeaderBlockWrapper = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${SecondaryButton} {
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const DarkToggle = styled.button`
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  opacity: 0.5;
  z-index: 8;

  &:hover {
    cursor: unset;
  }
`;

export const HeaderBody = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 100%;
`;

export const StyledContactText = styled.div``;

export const StyledNavHeaderButton = styled(Button)`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.blue4};

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.blue4};
  }

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.blue4};

    ${Icon} {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const StyledIconButton = styled.button`
  align-self: center;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  line-height: 0;
  padding: 0.5rem;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.grey200};
  }

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900};
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const StyledHeader = styled.header<{ theme: ThemeType }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9;
  transition: all 0.5s;
  width: 100%;
  font-weight: 400;

  ${breakpointUp.MD} {
    ${BurgerButtonWrapper} {
      display: none;
    }

    ${SecondaryButton} {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      height: 2.5rem;
    }

    &.open {
      transition: 0.5s;
      width: 100%;
      left: -16rem;
    }
  }

  ${breakpointDown.MD} {
    position: fixed;
    border-radius: 0.25rem;

    &.open {
      width: 100%;
      ${DarkToggle} {
        display: inherit;
      }
    }
  }
`;

export const StyledButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;
`;

export const LogoWrapper = styled.div`
  img {
    height: 3rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const BurgerButtonFullscreen = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900};
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.grey200};
  }
  &.left {
    transform: rotateY(180deg);
  }
`;

export const CreateButton = styled(NavLink)`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
  font-size: 0.875rem;
  width: 100%;
  padding: 0.625rem 1.5rem 0.625rem 1rem;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  height: 2.5rem;
  margin-right: 0.5rem;

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.white};
    height: 1.125rem;
    width: 1.125rem;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.blue4};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }

  ${breakpointDown.MD} {
    display: none;
  }
`;
