import React, { useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { PrimaryButton, IconAdd, Table, Dropdown, DropdownItemWrapper } from '@btc-snxt/ui';
import { Api } from 'api';
import { PaginationDataFilter } from 'api/types/app/pagination';
import { RouteList } from 'routeList';
import CategoryAddModal from 'pages/Modal/Category/CategoryAddModal';
import CategoryDeleteModal from 'pages/Modal/Category/CategoryDeleteModal';
import CategoryEditModal from 'pages/Modal/Category/CategoryEditModal';
import { SaveButtonsWrapper } from './CategoryTree.style';
import { useTableQuery } from 'api/queries/table/useTableQuery';

const Categories: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();
  const [shouldFetchData, setShouldFetchData] = useState<number>(0);
  const refetchData = () => setShouldFetchData((prevState: number) => prevState + 1);

  const { data, isLoading } = useTableQuery(
    ['categories-list', tableQueryRequest, shouldFetchData],
    () => Api.category.category.fetchCategories(tableQueryRequest),
  );

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);
  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);

  const [category, setCategory] = useState<number>(0);

  const handleSubmit = async (id: string) => {
    const path = generatePath(RouteList.CATEGORY.TREE.path, { category: id });
    navigate(path);
  };

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('common.number'),
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('label.alias'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('label.updated_at'),
        accessor: 'updated_at',
        sortType: 'string',
        Cell: (cell: Cell) => {
          return t('common.date_full', {
            date: cell.row.values.updated_at,
          });
        },
      },
      {
        Header: t('common.more'),
        Cell: (cell: Cell) => {
          return (
            <Dropdown>
              <DropdownItemWrapper
                onClick={() => {
                  setCategory(cell.row.values.id);
                  handleSubmit(cell.row.values.id);
                }}
              >
                {t('category.update_category')}
              </DropdownItemWrapper>
              <DropdownItemWrapper
                onClick={() => {
                  setCategory(cell.row.values.id);
                  toggleEditModal();
                }}
              >
                {t('category.rename')}
              </DropdownItemWrapper>
              <DropdownItemWrapper
                onClick={() => {
                  setCategory(cell.row.values.id);
                  toggleDeleteModal();
                }}
              >
                {t('common.delete')}
              </DropdownItemWrapper>
            </Dropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <>
      <SaveButtonsWrapper>
        <PrimaryButton onClick={toggleAddModal}>
          <IconAdd /> {t('category.add_category')}
        </PrimaryButton>
      </SaveButtonsWrapper>

      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        columns={columns}
        hiddenColumns={['id']}
        data={data}
        showPageSizes={true}
      />

      <CategoryAddModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        handleAdd={refetchData}
      />

      <CategoryEditModal
        categoryId={category}
        isOpen={isEditModalOpen}
        setIsOpen={setEditModalOpen}
        toggle={toggleEditModal}
        size={'md'}
        handleEdit={refetchData}
      />

      <CategoryDeleteModal
        categoryId={category}
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        toggle={toggleDeleteModal}
        size={'sm'}
        handleDelete={refetchData}
      />
    </>
  );
};

export default Categories;
