import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormikHelpers } from 'formik';

import {
  Col,
  FormModal,
  BaseModalProps,
  Row,
  LoadingState,
  withLoader,
  success,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { BodyWrapper } from '../Users/AddUserModal.style';
import { CreateRoleRequest } from 'api/types/role/role';

interface Props extends BaseModalProps, LoadingState {
  handleAdd?: () => void;
}

const AddRoleModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<CreateRoleRequest>({
    name: '',
    description: '',
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
  });

  const clearValues = () => {
    setFormData({
      name: '',
      description: '',
    });
  };

  const handleSubmit = async <T extends CreateRoleRequest>(values: T, helper: FormikHelpers<T>) => {
    helper.setSubmitting(true);
    try {
      await Api.role.role.add(values);
      props.setIsOpen(false);
      props.handleAdd && props.handleAdd();
      success(t('role.add_success'));
      clearValues();
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.add_role')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
    >
      <BodyWrapper>
        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.name')} required={true} />

            <Field
              type={'text'}
              name={'name'}
              id={'name'}
              placeholder={t('label.name')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label htmlFor={'description'} text={t('label.description')} required={true} />

            <Field
              type={'text'}
              name={'description'}
              id={'description'}
              placeholder={t('label.description')}
              className={'form-control'}
            />

            <ErrorMessage component={'div'} className={'text-danger'} name={'description'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(AddRoleModal);
