import styled from 'styled-components';
import { Icon, IconAdd } from '@btc-snxt/ui';

export const StyledDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledName = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  width: 4.375rem;
  margin: 1rem;
  border-radius: 3rem;
  background-color: rgba(0, 130, 184, 0.15);

  ${Icon} {
    height: 2rem;
    width: 2rem;
  }
`;

export const StyledMonthlyPriceBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMonthlyPrice = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.25rem;
  line-height: 2.125rem;
`;

export const StyledMonthlyPriceText = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.75rem;
`;

export const StyledYearlyPrice = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledDescription = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledFeaturesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const StyledFeaturesTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: bold;
`;

export const StyledFeaturesWrapper = styled.div`
  margin: 0.75rem 0;
`;

export const StyledFeature = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 1.25rem;

  ${Icon} {
    height: 1rem;
    width: 1rem;
    margin: 0.125rem;
  }

  ${IconAdd} {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;
