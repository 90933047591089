import styled from 'styled-components';

export const StyledScrollbar = styled.div`
  scrollbar-width: thin;
  *::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }

  *:hover::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray1};
    border-radius: 1.25rem;
  }
`;

export const BodyWrapper = styled.div`
  overflow: overlay;

  @-moz-document url-prefix() {
    overflow: auto;
  }
`;
