import styled from 'styled-components';
import { Icon, IconRemove, SecondaryButton } from '@btc-snxt/ui';

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.add-button {
    align-items: center;
  }

  ${SecondaryButton} {
    width: 8rem;
  }
`;

export const StyledInputRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
`;

export const StyledLabelInput = styled(StyledInput)`
  input {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: bold;
    text-transform: uppercase;

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray};
      font-weight: normal;
      text-transform: capitalize;
    }
  }
`;

export const StyledDropdown = styled.div`
  margin-left: 1rem;

  button {
    text-transform: uppercase;
  }
`;

export const StyledDeleteButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  margin-left: 1rem;

  &:hover {
    ${IconRemove} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const StyledBenefitsLabel = styled.h4`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: bold;
  margin: 0 0 1rem 0;
`;

export const StyledIconButtonRow = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

export const StyledSelectedIconButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
  background-color: rgba(0, 130, 184, 0.15);
  margin: 0.5rem;

  ${Icon} {
    height: 3rem;
    width: 3rem;
  }
`;

export const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
`;

export const StyledSwitchLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
  font-size: 1rem;
`;

export const StyledPricesBlock = styled.div`
  margin-bottom: 1rem;

  ${StyledInputRow} {
    gap: 1rem;
  }
`;
