import { AxiosRequestConfig } from 'axios';
import Client from 'api/services/client';
import { PaginationDataFilter as Filter } from 'api/types/app/pagination';
import { EmptyResponse } from 'api/types/common';
import {
  PrivacyPolicyRequest,
  PrivacyPolicyResponse,
} from 'api/types/privacy-policy/privacy-policy';

class PrivacyPolicy extends Client {
  public readonly api = {
    GET: `${this.http.baseUrl}/privacy-policy`,
    PUT: `${this.http.baseUrl}/privacy-policy`,
    POST_DOWNLOAD: `${this.http.baseUrl}/privacy-policy`,
  };

  get = (): Promise<PrivacyPolicyResponse> => this.http.get(this.api.GET);

  update = (data: PrivacyPolicyRequest): Promise<EmptyResponse> =>
    this.http.put(this.api.PUT, data);

  downloadPrivacyPolicy = (data: Filter = {}, config: AxiosRequestConfig = {}): Promise<Blob> =>
    this.http.post(this.api.POST_DOWNLOAD, data, {
      ...config,
      responseType: 'blob',
    });
}

export default PrivacyPolicy;
