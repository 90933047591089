import styled from 'styled-components';

export const StyledAdditionalQuestionPicker = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.black};
`;
