import styled from 'styled-components';
import { Icon, PrimaryButton, Input, H4, Theme, IconArrowLeft } from '@btc-snxt/ui';

interface ColorProps {
  $index?: number;
}

const colors: string[] = [
  Theme.colors.red,
  Theme.colors.yellow,
  Theme.colors.blue3,
  Theme.colors.green1,
  Theme.colors.brown,
  Theme.colors.green,
];

export const InputWithColor = styled(Input)<ColorProps>`
  padding: 0.375rem;
  background: ${(props: any) => {
    return colors[props.$index];
  }};
  &:focus {
    background-color: ${(props: any) => {
      return colors[props.$index];
    }};
  }
`;
export const InputWithBorderColor = styled(Input)<ColorProps>`
  border: 0.188rem solid;
  border-color: ${(props: any) => {
    return colors[props.$index];
  }};

  &:focus,
  &:hover {
    border: 0.188rem solid;
    border-color: ${(props: any) => {
      return colors[props.$index];
    }};
  }
`;

export const TreeWrapper = styled.div<ColorProps>`
  position: relative;
  width: 100%;
  height: 100%;

  ${PrimaryButton} {
    display: flex;

    ${Icon} {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  ${H4} {
    margin-top: 1rem;
  }

  .rst__toolbarButton:not(:last-child) {
    margin-right: 0.375rem;
  }

  .rst__moveHandle {
    min-width: 2.75rem;
  }

  .rst__rowLabel {
    padding-right: 0.375rem;
  }
`;

export const SaveButtonsWrapper = styled.div`
  ${PrimaryButton} {
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: none;
  }
`;

export const TreePageWrapper = styled.div`
  display: inline-grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr 1fr 12fr;
  grid-template-columns: 1fr;
`;

export const StyledIconArrowLeft = styled(IconArrowLeft)`
  scale: 0.75;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;
`;

export const InputsWrapper = styled.div`
  width: 13rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;
