import styled from 'styled-components';
import { Icon, IconAdd } from '@btc-snxt/ui';

export const StyledIconButton = styled.button`
  position: relative;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray5};
  }

  &.selected {
    border-color: ${({ theme }) => theme.colors.blue};
  }

  ${IconAdd} {
    position: absolute;
    display: none;
    height: 1rem;
    width: 1rem;
    bottom: 0.125rem;
    right: 0.125rem;
    background-color: ${({ theme }) => theme.colors.blue};

    &.selected {
      display: inline-block;
    }
  }
`;

export const StyledIconsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  width: 4.375rem;
  border-radius: 3rem;
  background-color: rgba(0, 130, 184, 0.15);
  margin: 0.5rem;

  ${Icon} {
    height: 2rem;
    width: 2rem;
  }
`;
