import React, { useCallback, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteList } from 'routeList';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { EmailInput, LanguageDropdown, Logo } from '@btc-snxt/ui';
import { Api } from 'api';
import { ResetPasswordRequest } from 'api/types/user/auth';
import {
  StyledAuthBlock,
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledInput,
  StyledInputBlock,
  StyledLanguageSelect,
  StyledLink,
  StyledLogo,
  StyledSuggestion,
  StyledText,
  StyledTextBlock,
  StyledTextBody,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import {
  StyledForgetPasswordPage,
  StyledResetButton,
  StyledSuccessMessage,
} from './ForgotPassword.style';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  const [success, setSuccess] = useState<boolean>(false);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const [resetPasswordRequest] = useState({
    email: '',
  });

  const onSubmit = useCallback(
    async (request: ResetPasswordRequest, helpers: FormikHelpers<ResetPasswordRequest>) => {
      try {
        await Api.user.auth.resetPassword(request);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      } finally {
        setSuccess(true);
      }
    },
    [],
  );

  return (
    <StyledAuthBlock>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextBody>{t('auth.reset_password.title')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>

      <StyledAuthBody>
        <StyledForgetPasswordPage>
          {success ? (
            <>
              <StyledSuccessMessage>
                {t('auth.reset_password.sent_instructions')}
              </StyledSuccessMessage>

              <StyledButtonWrapper>
                <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                  <StyledAuthButton type={'button'}>{t('auth.login.login')}</StyledAuthButton>
                </NavLink>
              </StyledButtonWrapper>
            </>
          ) : (
            <Formik
              initialValues={resetPasswordRequest}
              validationSchema={ResetPasswordSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form onSubmit={handleSubmit}>
                  <StyledInputBlock>
                    <StyledInput>
                      <StyledText>{t('auth.login.enter_email')}</StyledText>

                      <EmailInput
                        id={'email'}
                        name={'email'}
                        placeholder={t('label.email')}
                        onChange={handleChange}
                        value={values.email}
                      />
                    </StyledInput>
                  </StyledInputBlock>

                  <StyledButtonWrapper>
                    <StyledResetButton type={'submit'}>
                      {t('auth.reset_password.reset')}
                    </StyledResetButton>
                  </StyledButtonWrapper>

                  <StyledSuggestion>
                    <StyledText>{t('auth.reset_password.go_back')}</StyledText>

                    <StyledLink>
                      <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                        {t('auth.reset_password.login')}
                      </NavLink>
                    </StyledLink>
                  </StyledSuggestion>
                </Form>
              )}
            </Formik>
          )}
        </StyledForgetPasswordPage>
      </StyledAuthBody>
    </StyledAuthBlock>
  );
};

export default ForgotPassword;
