import { ValueOfObject } from 'types/types';

const jwtStorageKey = 'jwt';

type JwtStorageKey = typeof jwtStorageKey;

const authEvent = {
  login: 'login',
  refresh: 'refresh',
  logout: 'logout',
} as const;

type AuthEvent = ValueOfObject<typeof authEvent>;

interface JwtInterface {
  user_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  sub: string;
}

type AuthJwtObject = {
  token: string;
  expires: number;
};

export type { JwtStorageKey, JwtInterface, AuthJwtObject, AuthEvent };
export { authEvent, jwtStorageKey };
