import React, { useEffect, useState } from 'react';
import { GaugeBarChart, SurveyQuestionResult } from '@btc-snxt/graph';
import { calculateNpsData } from 'pages/Common/ResultAnalysis/SurveyTemplates/NPS/components/helpers';

// react-d3-speedometer bug, if maxValue/minValue is set to 100 and gets 3 labels, their position breaks
const GAUGE_MAX_VALUE = 100.0000000001;
const GAUGE_MIN_VALUE = -100.0000000001;
const GAUGE_COLORS_COUNT = 8;

const NPSGaugeBarChart: React.FC<SurveyQuestionResult> = (props) => {
  const [npsValue, setNpsValue] = useState<number>(0);

  const [totalRespondentsCount, setTotalRespondentsCount] = useState<number>(0);

  useEffect(() => {
    setTotalRespondentsCount(
      props.answers.reduce((current, next) => {
        current += next.count;
        return current;
      }, 0),
    );
  }, [props]);

  useEffect(() => {
    setNpsValue(calculateNpsData(props.answers, totalRespondentsCount).npsValue);
  }, [totalRespondentsCount, props.answers]);

  return (
    <GaugeBarChart
      currentValue={npsValue}
      colorsCount={GAUGE_COLORS_COUNT}
      maxValue={GAUGE_MAX_VALUE}
      minValue={GAUGE_MIN_VALUE}
    />
  );
};

export default NPSGaugeBarChart;
