import styled from 'styled-components';
import { PrimaryButton, breakpointDown } from '@btc-snxt/ui';

export const StyledAuthenticationLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.gray10};
`;

export const StyledAuthBlock = styled.div`
  width: 25rem;
  border-radius: 1rem;

  ${breakpointDown.SM} {
    width: 100%;
  }
`;

export const StyledAuthBlockWide = styled(StyledAuthBlock)`
  width: 48rem;
`;

export const StyledAuthHeader = styled.div`
  padding: 2rem 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  ${breakpointDown.SM} {
    border-radius: 0;
  }
`;

export const StyledLogo = styled.div`
  width: 100%;
  padding-bottom: 2rem;
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTextBlock = styled.span`
  display: flex;
  flex-wrap: wrap;
  line-height: 2.375rem;
  padding-bottom: 0.375rem;
`;

export const StyledTextHeader = styled.div``;

export const StyledTextBody = styled.div``;

export const StyledLanguageSelect = styled.span`
  display: flex;
  align-items: end;
`;

export const StyledAuthBody = styled.div`
  padding: 2rem 4rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.gray6};

  ${breakpointDown.SM} {
    padding-left: 2.625rem;
    padding-right: 2.625rem;
    border-radius: 0;
  }
`;

export const StyledInputBlock = styled.div`
  padding-bottom: 0.75rem;
`;

export const StyledInput = styled.div`
  :not(:last-child) {
    padding-bottom: 0.75rem;
  }
`;

export const StyledText = styled.span``;

export const StyledCheckboxBlock = styled.div``;

export const StyledCheckbox = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 0.625rem;

  :last-child {
    padding-bottom: 1.5rem;
  }
`;

export const StyledCheckboxText = styled.span`
  padding-left: 0.25rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledAuthButton = styled(PrimaryButton)`
  width: 9.375rem;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${breakpointDown.SM} {
    width: 100%;
  }
`;

export const StyledConfirmButton = styled(StyledAuthButton)`
  margin-top: 1rem;
`;

export const StyledSuggestion = styled.div`
  display: flex;
  justify-content: center;

  span {
    padding: 0.25rem;
  }
`;

export const StyledLink = styled.span`
  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
  }
`;

export const StyledLogoutButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
  padding: 0;
`;
