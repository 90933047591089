import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';
import { LanguageDropdown, Logo, PasswordInput } from '@btc-snxt/ui';
import { RouteList } from 'routeList';
import { Api } from 'api';
import { ConfirmResetPasswordRequest } from 'api/types/user/auth';
import {
  StyledAuthBlock,
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledInput,
  StyledInputBlock,
  StyledLanguageSelect,
  StyledLink,
  StyledLogo,
  StyledSuggestion,
  StyledText,
  StyledTextBlock,
  StyledTextBody,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import {
  StyledForgetPasswordPage,
  StyledResetButton,
  StyledSuccessMessage,
} from 'pages/Authentication/ForgotPassword/ForgotPassword.style';

const ConfirmPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);
  const [token] = useQueryParam('token', StringParam);

  useEffect(() => {
    if (token) {
      (async () => {
        await Api.user.auth
          .validatePasswordToken({ token: token })
          .catch(() => navigate(RouteList.AUTHENTICATION.LOGIN.path));
      })();
    } else {
      navigate(RouteList.AUTHENTICATION.LOGIN.path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required(),
    password_confirmation: Yup.string().required(),
    token: Yup.string().required(),
  });

  const [confirmResetPasswordRequest] = useState({
    password: '',
    password_confirmation: '',
    token: token,
  });

  const onSubmit = useCallback(
    async (
      request: ConfirmResetPasswordRequest,
      helpers: FormikHelpers<ConfirmResetPasswordRequest>,
    ) => {
      try {
        await Api.user.auth.confirmResetPassword(request);
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      } finally {
        setSuccess(true);
      }
    },
    [],
  );

  return (
    <StyledAuthBlock>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextBody>{t('auth.reset_password.title')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>
      <StyledAuthBody>
        <StyledForgetPasswordPage>
          {success ? (
            <>
              <StyledSuccessMessage>{t('auth.reset_password.reset_success')}</StyledSuccessMessage>

              <StyledButtonWrapper>
                <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                  <StyledAuthButton type={'button'}>{t('auth.login.login')}</StyledAuthButton>
                </NavLink>
              </StyledButtonWrapper>
            </>
          ) : (
            <Formik
              initialValues={confirmResetPasswordRequest}
              validationSchema={ConfirmResetPasswordSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form onSubmit={handleSubmit}>
                  <StyledInputBlock>
                    <StyledInput>
                      <StyledText>{t('auth.login.enter_password')}</StyledText>

                      <PasswordInput
                        id={'password'}
                        name={'password'}
                        placeholder={t('label.password')}
                        onChange={handleChange}
                        value={values.password}
                      />
                    </StyledInput>

                    <StyledInput>
                      <StyledText>{t('auth.register.password_confirm')}</StyledText>

                      <PasswordInput
                        id={'password_confirmation'}
                        name={'password_confirmation'}
                        placeholder={t('label.password')}
                        onChange={handleChange}
                        value={values.password_confirmation}
                      />
                    </StyledInput>
                  </StyledInputBlock>

                  <StyledButtonWrapper>
                    <StyledResetButton type={'submit'}>
                      {t('auth.reset_password.reset')}
                    </StyledResetButton>
                  </StyledButtonWrapper>

                  <StyledSuggestion>
                    <StyledText>{t('auth.reset_password.go_back')}</StyledText>

                    <StyledLink>
                      <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                        {t('auth.reset_password.login')}
                      </NavLink>
                    </StyledLink>
                  </StyledSuggestion>
                </Form>
              )}
            </Formik>
          )}
        </StyledForgetPasswordPage>
      </StyledAuthBody>
    </StyledAuthBlock>
  );
};

export default ConfirmPassword;
