export const navigateToExternalUrl = (externalUrl: string) => {
  window.location.replace(externalUrl);
};

export const convertStringToUrlFriendly = (value: string) => {
  return value.replace('_', '-');
};

export const convertStringToApiFriendly = (value: string) => {
  return value.replace('-', '_');
};
