import styled from 'styled-components';
import { breakpointDown, H4 } from '@btc-snxt/ui';
import { StyledDropdown } from 'components/TabsDropdown/TabsDropdown.style';

export const StyledShareMode = styled.div`
  width: 100%;
  padding: 1.5rem;
`;

export const StyledTitle = styled.div`
  display: flex;
  font-size: 1.25rem;
  margin-left: 1.25rem;
  vertical-align: middle;
  align-items: center;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  gap: 1.5rem;

  ${H4} {
    margin-bottom: 0;
  }

  ${breakpointDown.MD} {
    align-items: start;
    flex-direction: column;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledPublishArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 33%;
  gap: 0.5rem;

  ${StyledDropdown} {
    width: 100%;
  }

  ${breakpointDown.XL} {
    width: 100%;
  }
`;
