import { LogoResponse } from './../types/survey';
import Client from './client';
import { ContactResponse } from 'api/types/common';
import { FontFamily } from '@btc-snxt/survey';
import { FileUploadRequest } from 'api/types/user/user';
import { FontFamiliesResponse } from '../types/survey';

class Common extends Client {
  api = {
    COUNTRIES: `${this.http.baseUrl}/common/countries`,
    CONTACT_INFO: `${this.http.baseUrl}/contact`,
    UPLOAD_FILES: `${this.http.baseUrl}/surveys/upload/{survey}`,
    UPLOAD_EMAIL_FILES: `${this.http.baseUrl}/surveys/upload/emails/{survey}`,
  };

  getCountries = (): Promise<Record<string, string>> => {
    return this.http.get(this.api.COUNTRIES);
  };

  getContactInfo = (): Promise<ContactResponse> => {
    return this.http.get(this.api.CONTACT_INFO);
  };

  getFontFamilies = (): Promise<FontFamiliesResponse> => {
    return new Promise((resolve) => resolve(FontFamily));
  };

  uploadFile = (survey: string, request: FileUploadRequest): Promise<LogoResponse> => {
    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      const k = key as keyof FileUploadRequest;
      const file = request[k] as File;
      formData.append(key, file);
    });
    return this.http.post(this.buildUrl(this.api.UPLOAD_FILES, { survey }), formData);
  };

  uploadEmailLogo = (survey: string, request: FileUploadRequest): Promise<LogoResponse> => {
    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      const k = key as keyof FileUploadRequest;
      const file = request[k] as File;
      formData.append(key, file);
    });
    return this.http.post(this.buildUrl(this.api.UPLOAD_EMAIL_FILES, { survey }), formData);
  };
}

export default Common;
