import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { PrimaryButton, IconAdd, Table, Dropdown, DropdownItemWrapper } from '@btc-snxt/ui';
import { Api } from 'api';
import { PaginationDataFilter } from 'api/types/app/pagination';
import AddRoleModal from 'pages/Modal/Roles/AddRoleModal';
import DeleteRoleModal from 'pages/Modal/Roles/DeleteRoleModal';
import EditRoleModal from 'pages/Modal/Roles/EditRoleModal';
import { RolePageWrapper } from './Roles.style';
import { useTableQuery } from 'api/queries/table/useTableQuery';

const Roles: React.FC = () => {
  const { t } = useTranslation();

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();
  const [shouldFetchData, setShouldFetchData] = useState<number>(0);
  const refetchData = () => setShouldFetchData((prevState: number) => prevState + 1);

  const { data, isLoading } = useTableQuery(
    ['roles-list', tableQueryRequest, shouldFetchData],
    () => Api.role.role.filterRoles(tableQueryRequest),
  );

  const [role, setRole] = useState<string>('');

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);
  const toggleInfoModal = () => setInfoModalOpen(!isInfoModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('common.number'),
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('label.name'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('role.is_system_role'),
        accessor: 'is_system_role',
        sortType: 'boolean',
      },
      {
        Header: t('label.description'),
        accessor: 'description',
        sortType: 'string',
      },
      {
        Header: t('label.updated_at'),
        accessor: 'updated_at',
        sortType: 'string',
        Cell: (cell: Cell) => {
          return t('common.date_full', {
            date: cell.row.values.updated_at,
          });
        },
      },
      {
        Header: t('common.more'),
        Cell: (cell: Cell) => {
          return (
            <Dropdown>
              <DropdownItemWrapper
                onClick={() => {
                  setRole(cell.row.values.id);
                  toggleEditModal();
                }}
              >
                {t('common.edit')}
              </DropdownItemWrapper>

              <DropdownItemWrapper
                onClick={() => {
                  setRole(cell.row.values.id);
                  toggleDeleteModal();
                }}
              >
                {t('common.delete')}
              </DropdownItemWrapper>
            </Dropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, toggleDeleteModal, toggleEditModal, toggleInfoModal],
  );

  return (
    <RolePageWrapper>
      <PrimaryButton onClick={toggleAddModal}>
        <IconAdd /> {t('role.add_role')}
      </PrimaryButton>

      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        columns={columns}
        hiddenColumns={['id']}
        data={data}
        showPageSizes={true}
      />

      <AddRoleModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        handleAdd={refetchData}
      />

      {role && (
        <>
          <EditRoleModal
            roleId={role}
            isOpen={isEditModalOpen}
            setIsOpen={setEditModalOpen}
            toggle={toggleEditModal}
            size={'md'}
            handleEdit={refetchData}
          />

          <DeleteRoleModal
            roleId={role}
            isOpen={isDeleteModalOpen}
            setIsOpen={setDeleteModalOpen}
            toggle={toggleDeleteModal}
            size={'sm'}
            handleDelete={refetchData}
          />
        </>
      )}
    </RolePageWrapper>
  );
};

export default Roles;
