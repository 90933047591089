import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseModalProps,
  Col,
  Modal,
  PrimaryButton,
  Row,
  SecondaryButton,
  success,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { StyledFooter } from './MembershipUnsubscribeModal.style';
import { useAppState } from 'modules/appState';

const MembershipUnsubscribeModal: React.FC<BaseModalProps> = (props) => {
  const { t } = useTranslation();

  const { client, setClient } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);

  const handleUnsubscribe = async () => {
    try {
      setLoading(true);
      await Api.membership.membership.unsubscribe();
    } finally {
      client && setClient({ ...client, membership: { ...client.membership, auto_renew: false } });
      props.setIsOpen(false);
      setLoading(false);
      success(t('membership.unsubscribe_text'));
    }
  };

  return (
    <Modal
      {...props}
      title={t('common.confirmation')}
      size={'sm'}
      components={{
        Footer: () => {
          return (
            <StyledFooter>
              <Row>
                <Col>
                  <SecondaryButton onClick={handleUnsubscribe} disabled={loading}>
                    {t('common.yes')}
                  </SecondaryButton>
                </Col>

                <Col>
                  <PrimaryButton onClick={props.toggle} disabled={loading}>
                    {t('common.no')}
                  </PrimaryButton>
                </Col>
              </Row>
            </StyledFooter>
          );
        },
      }}
    >
      <Row>
        <Col>{t('membership.unsubscribe_confirm')}</Col>
      </Row>
    </Modal>
  );
};

export default MembershipUnsubscribeModal;
