import styled from 'styled-components';
import { IconAdd, IconArrowDown, IconClose } from '@btc-snxt/ui';

export const StyledDropdown = styled.div`
  button {
    display: flex;
    align-items: center;

    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray2};
    color: ${({ theme }) => theme.colors.gray2};

    ${IconArrowDown} {
      height: 1rem;
      width: 1rem;
      margin-left: 0.5rem;
    }

    &:hover {
      ${IconArrowDown} {
        background-color: ${({ theme }) => theme.colors.white};
        transition: 0.15s;
      }

      ${IconClose} {
        background-color: ${({ theme }) => theme.colors.white};
        transition: 0.15s;
      }
    }
  }

  ${IconAdd} {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .dropdown-menu {
    min-width: 0.25rem !important;

    button {
      padding: 0;

      div {
        display: flex;
        justify-content: center;
        padding: 0.5rem 1rem;
      }
    }

    &:hover {
      ${IconClose} {
        background-color: ${({ theme }) => theme.colors.gray2};
        transition: 0.15s;
      }
    }
  }
`;
