import styled from 'styled-components';

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin: 1rem 0;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
