import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  LoadingOverlay,
  Previewer,
  TemplateItem,
  getSurveyItemIndex,
  getSurveyItemsSize,
} from '@btc-snxt/survey';
import { error, Locale, PrimaryButton, success, Switch } from '@btc-snxt/ui';
import { Api } from 'api';
import { SurveyEditorMode, SurveyStatus } from 'helpers/enums';
import { useSurveyEditor } from '../../SurveyEditorContext';
import {
  StyledActionButtons,
  StyledMobileWrapper,
  StyledItems,
  StyledPreviewDeviceBlock,
} from '../../SurveyEditor.style';
import { getUrl } from '../../SurveyEditor.utils';

enum PreviewModeDevice {
  Desktop,
  Mobile,
}

interface PreviewModeState {
  device: PreviewModeDevice;
  isFullscreen: boolean;
  isInitialState: boolean;
}

const PreviewMode: React.FC = () => {
  const context = useSurveyEditor();
  const { t } = useTranslation();
  const [locale, setLocale] = useState<string>(
    context.availableLanguages.includes(Locale.LT) ? Locale.LT : context.availableLanguages[0],
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [previewModeState, setPrevieModeState] = useState<PreviewModeState>({
    device: PreviewModeDevice.Desktop,
    isFullscreen: false,
    isInitialState: true,
  });

  const isMobileChecked = previewModeState.device === PreviewModeDevice.Mobile;

  const toggleDevice = (device?: PreviewModeDevice) =>
    setPrevieModeState((prev) => ({
      ...prev,
      device:
        device ?? prev.device === PreviewModeDevice.Desktop
          ? PreviewModeDevice.Mobile
          : PreviewModeDevice.Desktop,
    }));

  const toggleFullscreen = (isFullscreen?: boolean) =>
    setPrevieModeState((prev) => ({
      ...prev,
      isFullscreen: isFullscreen ?? !prev.isFullscreen,
    }));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullscreen(false);
      }
    };

    ref.current?.addEventListener('fullscreenchange', handleFullscreenChange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => ref.current?.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  useEffect(() => {
    setLocale(
      context.availableLanguages.includes(Locale.LT) ? Locale.LT : context.availableLanguages[0],
    );
  }, [context.availableLanguages]);

  useEffect(() => {
    if (previewModeState.isFullscreen) {
      ref.current?.requestFullscreen();

      if (!previewModeState.isInitialState) {
        setPrevieModeState((prev) => ({ ...prev, isInitialState: true }));
      }
    } else if (!previewModeState.isInitialState) {
      document.exitFullscreen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewModeState.isFullscreen]);

  const saveItem = async () => {
    const surveyId = context.id;
    setIsLoading(true);
    try {
      const formedData = {
        type: context.surveyType,
        status: 'draft',
        name: `${context.surveyType} ${new Date().toJSON()}`,
        items: context.items,
        available_languages: context.availableLanguages,
      };

      let response;
      if (surveyId == undefined) {
        response = await Api.survey.survey.createSurvey(formedData);
      } else {
        response = await Api.survey.survey.updateSurvey(surveyId, formedData);
      }
      success(t('survey.create.save_success'));
      context.setMode(SurveyEditorMode.Preview);
      const url = getUrl(SurveyEditorMode.Preview, context.surveyType, response.id);
      navigate(url);
    } catch (e: any) {
      error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPreviewer = (item: TemplateItem, index: number): JSX.Element => (
    <Previewer
      id={item.id}
      values={item.values ?? item.initialValues}
      value={item.value ?? item.initialValues}
      type={item.type}
      surveyType={context.surveyType}
      key={item.id}
      locale={locale}
      color={context.color}
      size={getSurveyItemsSize(context.items)}
      questionIndex={index - getSurveyItemIndex(context.items, index)}
      itemIndex={index}
      logo={context.logo}
      logoObject={context.logoObject}
      setLocale={setLocale}
      availableLanguages={context.availableLanguages}
      mobileModeEnabled={isMobileChecked}
      demoMode={true}
    />
  );

  return (
    <>
      <StyledActionButtons>
        <PrimaryButton onClick={saveItem} disabled={context.status != SurveyStatus.Draft}>
          {t('common.save')}
        </PrimaryButton>

        <StyledPreviewDeviceBlock>
          <div>{t('common.desktop')}</div>
          <Switch checked={isMobileChecked} onChange={toggleDevice} />
          <div>{t('common.mobile')}</div>
        </StyledPreviewDeviceBlock>

        <PrimaryButton onClick={toggleFullscreen}>{t('builder.fullscreen_mode')}</PrimaryButton>
      </StyledActionButtons>

      <StyledItems ref={ref} $isMobile={isMobileChecked}>
        {isMobileChecked ? (
          <StyledMobileWrapper>
            {context.items?.map((item, index) => renderPreviewer(item, index))}
          </StyledMobileWrapper>
        ) : (
          context.items?.map((item, index) => renderPreviewer(item, index))
        )}
      </StyledItems>
      <LoadingOverlay isOpen={isLoading} />
    </>
  );
};

export default PreviewMode;
