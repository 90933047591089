import React from 'react';
import { StyledCalendarInput, StyledCalendarInputIcon } from './CalendarInput.style';
import { IconCalendar, Input } from '@btc-snxt/ui';

interface Props {
  value: string;
  onClick?: any;
  onChange?: any;
}

const CalendarInput: React.FC<Props> = (props) => {
  return (
    <StyledCalendarInput>
      <Input
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        readOnly
        disabled
      />

      <StyledCalendarInputIcon>
        <IconCalendar />
      </StyledCalendarInputIcon>
    </StyledCalendarInput>
  );
};

export default CalendarInput;
