import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  error,
  H4,
  IconEmailAlt,
  IconLink,
  IconQRCode,
  MultiTab,
  PrimaryButton,
  Select,
  success,
  Tab,
  warning,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { SurveySelectResponse } from 'api/types/survey';
import { SurveyEditorMode, SurveyStatus } from 'helpers/enums';
import { StyledDropdown } from 'components/TabsDropdown/TabsDropdown.style';
import CategoryAddModal from 'pages/Modal/Category/CategoryAddModal';
import { useSurveyEditor } from '../../SurveyEditorContext';
import { getUrl } from '../../SurveyEditor.utils';
import { ShareByEmail, ShareLink, ShareQrCode } from './shareMethods';
import { StyledLabel, StyledPublishArea, StyledTitleWrapper } from './ShareMode.style';
import CategoryTreeModal from 'pages/Modal/Category/CategoryTreeModal';

export interface Link {
  name: string;
  url: string;
  qrcode: any;
}

const ShareMode: React.FC = () => {
  const { t } = useTranslation();
  const context = useSurveyEditor();
  const navigate = useNavigate();

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [categoryList, setCategoryList] = useState<SurveySelectResponse[]>([]);
  const [categoryLinkList, setCategoryLinkList] = useState<Link[]>([]);

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);
  const toggleCategoryModal = () => setIsCategoryModalOpen(!isCategoryModalOpen);

  useEffect(() => {
    if (context.id == undefined) {
      context.setMode(SurveyEditorMode.Design);
      const url = getUrl(SurveyEditorMode.Design, context.surveyType, context.id);
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.status]);

  const fetchCategories = async () => {
    const data = await Api.survey.survey.getAllCategories();
    const categoryListOptions: SurveySelectResponse[] = [
      {
        id: 0,
        name: t('survey.share.create'),
        links: [],
        value: 'create',
        label: t('survey.share.create'),
      },
    ];
    Object.values(data).map((categories) => {
      categoryListOptions.push({
        id: categories.id,
        name: categories.name,
        links: categories.links,
        value: categories.name,
        label: categories.name,
      });
    });

    return categoryListOptions;
  };

  useEffect(() => {
    (async () => {
      if (context.category != undefined) {
        const currentCategory = { ...context.category };
        setCategoryLinkList(currentCategory.links);
      }
      const categories = await fetchCategories();
      setCategoryList(categories);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.category]);

  const getCategoryLinkList = (category: SurveySelectResponse) => {
    const categoryLinklist: Link[] = [];
    Object.entries(category.links).forEach(([, link]) => {
      categoryLinklist.push({ name: link['name'], url: link['url'], qrcode: link['qrcode'] });
    });
    return categoryLinklist;
  };

  const handleSurveyCategoryClick = (surveyCategory: SurveySelectResponse) => {
    if (surveyCategory.id === 0) {
      setIsAddModalOpen(true);
      return;
    }

    const newCategoryLinkList = getCategoryLinkList(surveyCategory);
    setCategoryLinkList(newCategoryLinkList);
    context.setCategory(surveyCategory);
  };

  const publish = async () => {
    if (context.category) {
      try {
        await Api.survey.survey.surveyPublish(
          context.id,
          context.category.id.toString(),
          context.category.name,
        );
        context.setStatus(SurveyStatus.Active);
        success(t('survey.share.success'));
      } catch (e) {
        error(t('survey.share.error'));
      }
    } else {
      warning(t('survey.share.warning'));
    }
  };

  const renderCategorySelection = () => (
    <StyledPublishArea>
      <StyledDropdown>
        <StyledLabel>{t('survey.share.label')}</StyledLabel>
        <Select
          classNamePrefix={'react-select'}
          placeholder={t('survey.share.survey')}
          onChange={(newValue) => {
            handleSurveyCategoryClick(newValue as unknown as SurveySelectResponse);
          }}
          value={context.category}
          options={categoryList}
          isDisabled={context.status != SurveyStatus.Draft}
        />
      </StyledDropdown>
      <div>
        <PrimaryButton onClick={publish} disabled={context.status != SurveyStatus.Draft}>
          {t('survey.share.publish_button')}
        </PrimaryButton>
      </div>
    </StyledPublishArea>
  );

  return (
    <>
      <CategoryAddModal
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        handleAdd={async () => {
          const categories = await fetchCategories();
          setCategoryList(categories);
          handleSurveyCategoryClick(categories[categories.length - 1]);
          forceUpdate();
          setIsCategoryModalOpen(true);
        }}
      />

      <CategoryTreeModal
        isOpen={isCategoryModalOpen}
        setIsOpen={setIsCategoryModalOpen}
        toggle={toggleCategoryModal}
        onClosed={async () => {
          if (context.category != undefined) {
            const categories = await fetchCategories();
            const newCategoryLinkList = getCategoryLinkList(categories[categories.length - 1]);
            setCategoryLinkList(newCategoryLinkList);
            context.setCategory(categories[categories.length - 1]);
          }
        }}
      />

      <StyledTitleWrapper>
        <H4>{t('survey.share.title')}</H4>

        {renderCategorySelection()}
      </StyledTitleWrapper>

      <MultiTab
        items={[
          {
            description: (
              <Tab>
                <ShareLink links={categoryLinkList} />
              </Tab>
            ),
            icon: <IconLink />,
            title: t('survey.share.title_link'),
          },
          {
            description: (
              <Tab>
                <ShareQrCode links={categoryLinkList} />
              </Tab>
            ),
            icon: <IconQRCode />,
            title: t('survey.share.title_qr_code'),
          },
          {
            description: (
              <Tab>
                <ShareByEmail />
              </Tab>
            ),
            icon: <IconEmailAlt />,
            title: t('survey.share.title_email'),
          },
        ]}
      />
    </>
  );
};

export default ShareMode;
