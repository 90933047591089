import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@btc-snxt/ui';
import { StackedPieChart, SurveyQuestionResult } from '@btc-snxt/graph';
import { Distribution } from 'pages/Common/ResultAnalysis/SurveyTemplates/NPS/components/types';
import { calculateNpsData } from 'pages/Common/ResultAnalysis/SurveyTemplates/NPS/components/helpers';
import {
  StyledExtraText,
  StyledPieChartBlock,
  StyledDistributionTextBlock,
  StyledMainText,
  StyledNPSDistributionBody,
  StyledTextWrapper,
  StyledTotalText,
} from './NPSDistributionPieChart.style';

const NPSDistributionPieChart: React.FC<SurveyQuestionResult> = (props) => {
  const { t } = useTranslation();

  const [respondentsDistribution, setRespondentsDistribution] = useState<Distribution>({
    npsValue: 0,
    promotersCount: 0,
    promotersPercentage: 0,
    passivesCount: 0,
    passivesPercentage: 0,
    detractorsCount: 0,
    detractorsPercentage: 0,
  });

  const [totalRespondentsCount, setTotalRespondentsCount] = useState<number>(0);

  useEffect(() => {
    setTotalRespondentsCount(
      props.answers.reduce((current, next) => {
        current += next.count;
        return current;
      }, 0),
    );
  }, [props]);

  useEffect(() => {
    setRespondentsDistribution(calculateNpsData(props.answers, totalRespondentsCount));
  }, [totalRespondentsCount, props.answers]);

  const renderText = (mainText: string, extraText: string, color: string) => {
    return (
      <StyledDistributionTextBlock>
        <StyledMainText style={{ color: color }}>{mainText}</StyledMainText>

        <StyledExtraText>{extraText}</StyledExtraText>
      </StyledDistributionTextBlock>
    );
  };

  return (
    <>
      <StyledNPSDistributionBody>
        <StyledPieChartBlock>
          <StackedPieChart
            title={t('graph.nps.chart')}
            value={respondentsDistribution.npsValue}
            stack={[
              { value: respondentsDistribution.promotersPercentage },
              { value: respondentsDistribution.passivesPercentage },
              { value: respondentsDistribution.detractorsPercentage },
            ]}
          />
        </StyledPieChartBlock>

        <StyledTextWrapper>
          {renderText(
            `${respondentsDistribution.promotersPercentage}% ${t('graph.nps.promoters')}`,
            `${respondentsDistribution.promotersCount} ${t('graph.nps.respondents')}`,
            Theme.colors.blue,
          )}

          {renderText(
            `${respondentsDistribution.passivesPercentage}% ${t('graph.nps.passives')}`,
            `${respondentsDistribution.passivesCount} ${t('graph.nps.respondents')}`,
            Theme.colors.yellow,
          )}

          {renderText(
            `${respondentsDistribution.detractorsPercentage}% ${t('graph.nps.detractors')}`,
            `${respondentsDistribution.detractorsCount} ${t('graph.nps.respondents')}`,
            Theme.colors.red,
          )}

          <StyledDistributionTextBlock>
            <StyledTotalText>
              {t('graph.nps.n_equals').concat(totalRespondentsCount.toString())}
            </StyledTotalText>
          </StyledDistributionTextBlock>
        </StyledTextWrapper>
      </StyledNPSDistributionBody>
    </>
  );
};

export default NPSDistributionPieChart;
