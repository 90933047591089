import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconEmail,
  IconHomeAlt,
  IconMobile,
  BaseModalProps,
  Modal,
  LoadingState,
  withLoader,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { ContactResponse } from 'api/types/common';
import {
  StyledBlueText,
  StyledContactPage,
  StyledGrayText,
  StyledIconBlock,
  StyledRow,
  StyledTextBlock,
} from './ContactInfoModal.style';

const ContactInfoModal: React.FC<BaseModalProps & LoadingState> = (props): JSX.Element => {
  const { t } = useTranslation();

  const [contactData, setContactData] = useState<ContactResponse>({
    mobile: '',
    email: '',
    address: '',
  });

  const handleOpen = async () => {
    props.setIsLoading(true);
    const data = await Api.common.common.getContactInfo();
    setContactData(data);
    props.setIsLoading(false);
  };

  return (
    <Modal {...props} title={t('contact.title')} onOpened={handleOpen} size={'sm'}>
      <StyledContactPage>
        <StyledRow>
          <StyledIconBlock>
            <IconMobile />
          </StyledIconBlock>

          <StyledTextBlock>
            <StyledGrayText>{t('contact.mobile_text')}</StyledGrayText>
            <StyledBlueText>{contactData.mobile}</StyledBlueText>
          </StyledTextBlock>
        </StyledRow>

        <StyledRow>
          <StyledIconBlock>
            <IconEmail />
          </StyledIconBlock>

          <StyledTextBlock>
            <StyledGrayText>{t('contact.email_text')}</StyledGrayText>
            <StyledBlueText>{contactData.email}</StyledBlueText>
          </StyledTextBlock>
        </StyledRow>

        <StyledRow>
          <StyledIconBlock>
            <IconHomeAlt />
          </StyledIconBlock>

          <StyledTextBlock>
            <StyledGrayText>{t('contact.address_text')}</StyledGrayText>
            <StyledBlueText>{contactData.address}</StyledBlueText>
          </StyledTextBlock>
        </StyledRow>
      </StyledContactPage>
    </Modal>
  );
};

export default withLoader(ContactInfoModal);
