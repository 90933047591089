import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { RouteList } from 'routeList';
import * as Yup from 'yup';
import {
  LanguageDropdown,
  Logo,
  EmailInput,
  PasswordInput,
  Label,
  PrimarySpinner,
  CheckboxInput,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { RegisterRequest } from 'api/types/user/auth';
import {
  StyledAuthBlock,
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledCheckbox,
  StyledCheckboxBlock,
  StyledCheckboxText,
  StyledInput,
  StyledInputBlock,
  StyledLanguageSelect,
  StyledLink,
  StyledLogo,
  StyledSuggestion,
  StyledText,
  StyledTextBlock,
  StyledTextBody,
  StyledTextHeader,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { StyledRegister, StyledRegisterSuccessText } from './Register.style';
import TermsOfServiceModal from 'pages/Modal/TermsOfService/TermsOfServiceModal';
const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isTosModelOpen, setTosModalOpen] = useState<boolean>(false);
  const toggleTostModal = () => setTosModalOpen(!isTosModelOpen);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    password_confirmation: Yup.string().required(),
    tos: Yup.boolean().oneOf([true]).required(),
    marketing_consent: Yup.boolean().oneOf([true, false]),
    remember_me: Yup.boolean().oneOf([true, false]),
  });

  const [registerRequest] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    tos: false,
    marketing_consent: false,
    remember_me: false,
  });

  const onSubmit = useCallback(
    async (request: RegisterRequest, helpers: FormikHelpers<RegisterRequest>) => {
      setLoading(true);
      let didCatchError = false;

      try {
        await Api.user.auth.register(request);
      } catch (e: any) {
        didCatchError = true;
        helpers.setErrors(e.response?.errors);
      } finally {
        !didCatchError && setRegisterSuccess(true);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, t],
  );

  return (
    <StyledAuthBlock>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextHeader>
              {registerSuccess
                ? t('auth.register.register_success_header')
                : t('auth.login.greeting')}
            </StyledTextHeader>

            {!registerSuccess && (
              <StyledTextBody>{t('auth.register.greeting_text')}</StyledTextBody>
            )}
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>

      <StyledAuthBody>
        <StyledRegister>
          {registerSuccess ? (
            <>
              <StyledRegisterSuccessText>
                {t('auth.register.register_success_body')}
              </StyledRegisterSuccessText>

              <StyledButtonWrapper>
                <StyledAuthButton
                  onClick={() => navigate(RouteList.AUTHENTICATION.LOGIN.path)}
                  type={'button'}
                >
                  {t('auth.login.login')}
                </StyledAuthButton>
              </StyledButtonWrapper>
            </>
          ) : (
            <Formik
              initialValues={registerRequest}
              validationSchema={RegisterSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, values }) => (
                <Form>
                  <StyledInputBlock>
                    <StyledInput>
                      <Label htmlFor={'email'} text={t('auth.login.enter_email')} required={true} />

                      <EmailInput
                        id={'email'}
                        name={'email'}
                        placeholder={t('label.email')}
                        onChange={handleChange}
                        value={values.email}
                      />

                      <ErrorMessage component={'div'} className={'text-danger'} name={'email'} />
                    </StyledInput>

                    <StyledInput>
                      <Label
                        htmlFor={'password'}
                        text={t('auth.login.enter_password')}
                        required={true}
                      />

                      <PasswordInput
                        id={'password'}
                        name={'password'}
                        placeholder={t('label.password')}
                        onChange={handleChange}
                        value={values.password}
                      />

                      <ErrorMessage component={'div'} className={'text-danger'} name={'password'} />
                    </StyledInput>

                    <StyledInput>
                      <Label
                        htmlFor={'password_confirmation'}
                        text={t('auth.register.password_confirm')}
                        required={true}
                      />

                      <PasswordInput
                        id={'password_confirmation'}
                        name={'password_confirmation'}
                        placeholder={t('label.password')}
                        onChange={handleChange}
                        value={values.password_confirmation}
                      />

                      <ErrorMessage
                        component={'div'}
                        className={'text-danger'}
                        name={'password_confirmation'}
                      />
                    </StyledInput>
                  </StyledInputBlock>

                  <StyledCheckboxBlock>
                    <StyledCheckbox>
                      <Field as={CheckboxInput} id={'tos'} name={'tos'} />

                      <StyledCheckboxText>
                        {t('auth.register.agree') + ' '}
                        <StyledLink>
                          <NavLink
                            to={''}
                            onClick={() => {
                              toggleTostModal();
                            }}
                          >
                            {t('auth.register.tos')}*
                          </NavLink>
                        </StyledLink>
                        <TermsOfServiceModal
                          isOpen={isTosModelOpen}
                          setIsOpen={setTosModalOpen}
                          toggle={toggleTostModal}
                        />
                      </StyledCheckboxText>

                      <ErrorMessage component={'div'} className={'text-danger'} name={'tos'} />
                    </StyledCheckbox>

                    <StyledCheckbox>
                      <Field as={CheckboxInput} name={'marketing_consent'} />

                      <StyledCheckboxText>
                        {t('auth.register.marketing_consent')}
                      </StyledCheckboxText>
                    </StyledCheckbox>

                    <StyledCheckbox>
                      <Field as={CheckboxInput} name={'remember_me'} />

                      <StyledCheckboxText>{t('auth.login.remember_me')}</StyledCheckboxText>
                    </StyledCheckbox>
                  </StyledCheckboxBlock>

                  <StyledButtonWrapper>
                    <StyledAuthButton disabled={isLoading} type={'submit'}>
                      {isLoading ? <PrimarySpinner /> : t('auth.login.sign_up')}
                    </StyledAuthButton>
                  </StyledButtonWrapper>

                  <StyledSuggestion>
                    <StyledText>{t('auth.register.bottom_text')}</StyledText>

                    <StyledLink>
                      <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
                        {t('auth.login.login')}
                      </NavLink>
                    </StyledLink>
                  </StyledSuggestion>
                </Form>
              )}
            </Formik>
          )}
        </StyledRegister>
      </StyledAuthBody>
    </StyledAuthBlock>
  );
};

export default Register;
