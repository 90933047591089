import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { SurveyTypeResponse } from 'api/types/survey';

const useSelectSurveyTypeQuery = (options?: UseQueryOptionsObject<SurveyTypeResponse[]>) =>
  useMakeQuery<SurveyTypeResponse[]>(
    [queries.survey, 'select-survey-type'],
    () => Api.survey.survey.getSurveyTypes(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useSelectSurveyTypeQuery };
