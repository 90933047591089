import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { PermissionListResponse } from 'api/types/role/role';

const usePermissionsQuery = (options?: UseQueryOptionsObject<PermissionListResponse[]>) =>
  useMakeQuery<PermissionListResponse[]>(
    [queries.role, 'permissions-list'],
    () => Api.role.role.fetchPermissions(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { usePermissionsQuery };
