import styled from 'styled-components';
import { breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledNPSWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StyledPersonPercentBarChart = styled.div`
  margin-bottom: 1rem;
  flex: 1 0 0;
`;

export const StyledNPSOverviewBlock = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${breakpointDown.XL} {
    flex-direction: column;
  }
`;

export const StyledNPSOverview = styled.div`
  flex: 1 0 0;
  margin-bottom: 1rem;

  ${breakpointDown.XL} {
    :first-child {
      margin-bottom: 1rem;
    }
  }

  ${breakpointUp.XL} {
    :first-child {
      margin-right: 1rem;
    }
  }
`;
