import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import {
  PrimaryButton,
  Dropdown,
  DropdownItemWrapper,
  IconAdd,
  Table,
  Filterable,
  error,
  success,
  ConfirmActionModal,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { useTableQuery } from 'api/queries/table/useTableQuery';
import { PaginationDataFilter } from 'api/types/app/pagination';
import { ClientAddModal, ClientEditModal, ClientInfoModal } from 'pages/Modal/Client';
import { ClientsPageWrapper } from './Clients.style';

const Clients: React.FC = () => {
  const { t } = useTranslation();

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();
  const [shouldFetchData, setShouldFetchData] = useState<number>(0);
  const refetchData = () => setShouldFetchData((prevState: number) => prevState + 1);

  const { data, isLoading } = useTableQuery(
    ['clients-list', tableQueryRequest, shouldFetchData],
    () => Api.admin.company.filterClients(tableQueryRequest),
  );

  const [client, setClient] = useState<string>('');

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const toggleAddModal = () => setAddModalOpen(!isAddModalOpen);
  const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);
  const toggleInfoModal = () => setInfoModalOpen(!isInfoModalOpen);
  const toggleConfirmModal = () => setConfirmModalOpen(!isConfirmModalOpen);

  const hiddenColumns: Array<string> = ['id'];
  const filterable: Array<Filterable> = [{ columnName: 'created_at', columnType: 'date' }];

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('common.number'),
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('label.company_name'),
        accessor: 'company_name',
        sortType: 'string',
      },
      {
        Header: t('label.category'),
        accessor: 'category',
        sortType: 'string',
      },
      {
        Header: t('label.country'),
        accessor: 'country',
        sortType: 'string',
      },
      {
        Header: t('label.email'),
        accessor: 'email',
        sortType: 'string',
      },
      {
        Header: t('label.mobile'),
        accessor: 'mobile',
        sortType: 'string',
      },
      {
        Header: t('label.responsible_person'),
        accessor: 'responsible_person',
        sortType: 'string',
      },
      {
        Header: t('label.updated_at'),
        accessor: 'updated_at',
        sortType: 'string',
        Cell: (cell: Cell) =>
          t('common.date_full', {
            date: cell.row.values.updated_at,
          }),
      },
      {
        Header: t('label.created_at'),
        accessor: 'created_at',
        sortType: 'string',
        Cell: (cell: Cell) =>
          t('common.date_full', {
            date: cell.row.values.created_at,
          }),
      },
      {
        Header: t('common.more'),
        Cell: (cell: Cell) => (
          <Dropdown>
            <DropdownItemWrapper
              onClick={() => {
                setClient(cell.row.values.id);
                toggleInfoModal();
              }}
            >
              {t('common.profile')}
            </DropdownItemWrapper>

            <DropdownItemWrapper
              onClick={() => {
                setClient(cell.row.values.id);
                toggleEditModal();
              }}
            >
              {t('common.edit')}
            </DropdownItemWrapper>

            <DropdownItemWrapper>{t('common.message')}</DropdownItemWrapper>

            <DropdownItemWrapper
              onClick={() => {
                setClient(cell.row.values.id);
                toggleConfirmModal();
              }}
            >
              {t('common.delete')}
            </DropdownItemWrapper>
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleDelete = async () => {
    let fail = false;
    await Api.admin.company
      .delete(client)
      .catch(() => {
        fail = true;
        error(t('client.delete_error'));
      })
      .finally(() => {
        if (!fail) {
          success(t('client.delete_success'));
          setConfirmModalOpen(false);
          refetchData();
          setClient('');
        }
      });
  };

  return (
    <ClientsPageWrapper>
      <PrimaryButton onClick={toggleAddModal}>
        <IconAdd /> {t('client.add_client')}
      </PrimaryButton>

      <Table
        setQueryRequest={setTableQueryRequest}
        isLoading={isLoading}
        columns={columns}
        data={data}
        showPageSizes={true}
        searchable={true}
        hiddenColumns={hiddenColumns}
        filterable={filterable}
      />

      <ClientAddModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        handleAdd={refetchData}
      />

      {client && (
        <>
          <ClientInfoModal
            clientId={client}
            isOpen={isInfoModalOpen}
            setIsOpen={setInfoModalOpen}
            toggle={toggleInfoModal}
            size={'md'}
            toggleRender={refetchData}
          />

          <ClientEditModal
            clientId={client}
            isOpen={isEditModalOpen}
            setIsOpen={setEditModalOpen}
            toggle={toggleEditModal}
            size={'md'}
            handleEdit={refetchData}
          />
        </>
      )}

      <ConfirmActionModal
        setIsOpen={setConfirmModalOpen}
        isOpen={isConfirmModalOpen}
        toggle={toggleConfirmModal}
        onConfirm={handleDelete}
      />
    </ClientsPageWrapper>
  );
};

export default Clients;
