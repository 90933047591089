import styled from 'styled-components';
import { Icon } from '@btc-snxt/ui';

export const StyledSurveyListPage = styled.div``;

export const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.5rem;

  ${Icon} {
    height: 0.875rem;
    width: 0.875rem;
  }
`;

export const StyledText = styled.div``;

export const StyledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.gray5};
`;
