import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';

const useTableQuery = <Data,>(
  queryKey: any[],
  promise: () => Promise<Data>,
  options?: UseQueryOptionsObject<Data>,
) =>
  useMakeQuery<Data>([queries.table, ...queryKey], promise, {
    cacheTime: 1000,
    retry: false,
    ...defaultOptions,
    ...(options ?? {}),
  });

export { useTableQuery };
