import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseModalProps, LoadingState, withLoader, Modal } from '@btc-snxt/ui';
import { CategoryTree } from 'pages/Common/Category';
import { useSurveyEditor } from 'pages/Common/Survey/SurveyEditor/SurveyEditorContext';
import { BodyWrapper, StyledScrollbar } from './CategoryTreeModal.style';

interface Props extends BaseModalProps, LoadingState {
  onAdd?: () => void;
}

const CategoryTreeModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();
  const context = useSurveyEditor();

  return (
    <Modal {...props} title={t('title.add_category')} size={'xl'}>
      <StyledScrollbar>
        <BodyWrapper>
          <CategoryTree categoryId={String(context.category?.id)} />
        </BodyWrapper>
      </StyledScrollbar>
    </Modal>
  );
};

export default withLoader(CategoryTreeModal);
