import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IconArrowDown } from '@btc-snxt/ui';
import { StyledDropdown } from './MembershipDropdown.style';

interface Props {
  currentText: string | JSX.Element;
}

const MembershipDropdown: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <StyledDropdown>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle>
          {props.currentText}

          <IconArrowDown />
        </DropdownToggle>

        <DropdownMenu>
          {React.Children.map(props.children, (menuItem, index) => (
            <DropdownItem key={index}>{menuItem}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </StyledDropdown>
  );
};

export default MembershipDropdown;
