import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteList } from 'routeList';
import usePermissions from 'helpers/usePermissions';
import { Permissions } from 'helpers/auth';
import Tabs, { TabProps } from 'components/Tabs/Tabs';
import { TabsProvider } from 'components/Tabs/TabsContext';
import { ContactSettings } from './tabs/ContactSettings';
import { EmailSettings } from './tabs/EmailSettings';
import { FaqSettings } from './tabs/FaqSettings';
import { InvoiceSettings } from './tabs/InvoiceSettings';
import { MembershipSettingsWrapper } from './tabs/MembershipSettings';
import { NewsSettings } from './tabs/NewsSettings';
import { NotificationSettings } from './tabs/NotificationSettings';
import { PrivacyPolicy } from './tabs/PrivacyPolicySettings';
import { TranslationsSettings } from './tabs/TranslationsSettings';
import SystemSettings from './tabs/SystemSettings/SystemSettings';

const Settings: React.FC = () => {
  const { t } = useTranslation();

  const tabs: TabProps[] = [
    {
      title: '',
      content: undefined,
      path: RouteList.SETTINGS.HOME.path,
    },
  ];

  usePermissions().hasAny(Permissions.NOTIFICATIONS.LIST) &&
    tabs.push({
      title: t('settings.notifications'),
      content: <NotificationSettings />,
      path: RouteList.SETTINGS.NOTIFICATIONS.path,
    });

  usePermissions().hasAny(Permissions.NEWS.LIST) &&
    tabs.push({
      title: t('settings.news'),
      content: <NewsSettings />,
      path: RouteList.SETTINGS.NEWS.path,
    });

  usePermissions().hasAny(Permissions.SYSTEM.LIST) &&
    tabs.push({
      title: t('settings.system'),
      content: <SystemSettings />,
      path: RouteList.SETTINGS.SYSTEM.path,
    });

  usePermissions().hasAny(Permissions.CONTACT.LIST) &&
    tabs.push({
      title: t('settings.contact_info'),
      content: <ContactSettings />,
      path: RouteList.SETTINGS.CONTACT_INFO.path,
    });

  usePermissions().hasAny(Permissions.PRIVACY_POLICY.UPDATE) &&
    tabs.push({
      title: t('settings.privacy_policy'),
      content: <PrivacyPolicy />,
      path: RouteList.SETTINGS.PRIVACY_POLICY.path,
    });

  usePermissions().hasAny(Permissions.FAQ.LIST) &&
    tabs.push({
      title: t('settings.faq'),
      content: <FaqSettings />,
      path: RouteList.SETTINGS.FAQ.path,
    });

  usePermissions().hasAny(Permissions.EMAILS.LIST) &&
    tabs.push({
      title: t('settings.emails'),
      content: <EmailSettings />,
      path: RouteList.SETTINGS.EMAILS.path,
    });

  usePermissions().hasAny(Permissions.TRANSLATIONS.LIST) &&
    tabs.push({
      title: t('settings.translations'),
      content: <TranslationsSettings />,
      path: RouteList.SETTINGS.TRANSLATIONS.path,
    });

  usePermissions().hasAny(Permissions.INVOICES.LIST) &&
    tabs.push({
      title: t('settings.invoices'),
      content: <InvoiceSettings />,
      path: RouteList.SETTINGS.INVOICES.path,
    });

  usePermissions().hasAny(Permissions.MEMBERSHIPS.LIST) &&
    tabs.push({
      title: t('settings.memberships'),
      content: <MembershipSettingsWrapper />,
      path: RouteList.SETTINGS.MEMBERSHIPS.path,
    });

  return (
    <TabsProvider>
      <Tabs tabs={tabs} />
    </TabsProvider>
  );
};

export default Settings;
