import styled from 'styled-components';
import { StyledAuthButton } from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { breakpointDown } from '@btc-snxt/ui';

export const StyledForgetPasswordPage = styled.div`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.gray10};
`;

export const StyledResetButton = styled(StyledAuthButton)`
  margin-top: 0.5rem;

  ${breakpointDown.SM} {
    width: 100%;
  }
`;

export const StyledSuccessMessage = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
`;
