import styled from 'styled-components';
import { breakpointDown, breakpointUp } from '@btc-snxt/ui';
import { StyledCalendarInput } from 'pages/Common/Dashboard/components/CalendarInput/CalendarInput.style';

export interface StyledDatePickerProps {
  $poppingDisabled: boolean;
  $monthsShown: number;
}

export const StyledDatePicker = styled.div<StyledDatePickerProps>`
  text-align: center;
  width: 100%;

  .react-datepicker-popper {
    ${(props) =>
      props.$poppingDisabled &&
      'position: unset !important; inset: unset !important; transform: none !important;'}
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__input-container {
    display: flex;
    gap: 1.5rem;
    width: 30rem;

    ${breakpointDown.LG} {
      flex-direction: column;
      gap: 0;
      width: 100%;
    }
  }

  .react-datepicker__triangle {
    display: ${(props) => (props.$poppingDisabled ? 'none' : '')};
    ${(props) =>
      !props.$poppingDisabled && `left: ${244}px !important; transform: none !important;`}
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.gray2};
    font-weight: bold;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.gray2};
    font-weight: bold;
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.gray2};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.blue} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }

  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white} !important;
  }

  .react-datepicker__day--today {
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 0.3rem;
    color: ${({ theme }) => theme.colors.blue};
    outline: none;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledCalendarInputsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  padding-bottom: 1rem;
  width: 100%;

  ${breakpointUp.SM} {
    ${StyledCalendarInput} {
      max-width: 15rem;
    }
  }

  ${breakpointDown.SM} {
    flex-direction: column;

    ${StyledCalendarInput} {
      width: 100%;
    }
  }
`;

export const InputWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
  }
`;
