import React, { useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import {
  H1,
  H6,
  Table,
  IconShareAlt,
  PrimaryButton,
  DropdownItemWrapper,
  error,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { PaginationDataFilter } from 'api/types/app/pagination';
import { InvoicesTableResponse, InvoiceStatus } from 'api/types/settings/settings';
import { navigateToExternalUrl } from 'helpers/common';
import { useTabsContext } from 'components/Tabs/TabsContext';
import StatusDropdown from './components';
import {
  StyledAmountCell,
  StyledIconShow,
  StyledInvoicesSettings,
  StyledInvoicesTable,
  StyledPayButton,
  StyledStatus,
  StyledStatusWrapper,
} from './InvoiceSettings.style';
import InvoiceModal from 'pages/Modal/Invoice/InvoiceModal';
import { useTableQuery } from 'api/queries/table/useTableQuery';

const InvoiceSettings: React.FC = () => {
  const { t } = useTranslation();

  const { setLoading } = useTabsContext();
  setTimeout(() => setLoading(false), 0);

  const [tableQueryRequest, setTableQueryRequest] = useState<PaginationDataFilter>();

  const { data, isLoading } = useTableQuery(['invoices-list', tableQueryRequest], () =>
    Api.settings.invoice.filterInvoices(tableQueryRequest),
  );

  const [invoice, setInvoice] = useState<string>('');
  const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
  const toggleProfileModal = () => setProfileModalOpen(!isProfileModalOpen);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: 'Nr.',
        accessor: 'id',
        sortType: 'number',
      },
      {
        Header: t('invoices.table.client'),
        accessor: 'company_name',
        sortType: 'string',
      },
      {
        Header: t('invoices.table.invoice_number'),
        accessor: 'invoice_number',
        sortType: 'string',
      },
      {
        Header: t('invoices.table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell) => {
          return <StyledAmountCell>{`€${cell.value}`}</StyledAmountCell>;
        },
      },
      {
        Header: t('invoices.table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<InvoicesTableResponse>) => {
          const [status, setStatus] = useState<InvoiceStatus>(cell.value);

          const change = (invoiceId: string, status: InvoiceStatus) => {
            const prevStatus = cell.value;

            setStatus(status);

            handleStatusUpdate(invoiceId, status).catch(() => {
              setStatus(prevStatus);
            });
          };

          return renderStatusDropdown(cell, change, status);
        },
      },
      {
        Header: t('invoices.table.generated'),
        accessor: 'generated_at',
        sortType: 'string',
        Cell: (cell: Cell) => {
          return formatDate(cell.value);
        },
      },
      {
        Header: t('invoices.table.download'),
        accessor: 'invoice_url',
        disableSortBy: true,
        Cell: (cell: Cell) => {
          return cell.value == '' ? (
            t('invoices.table.no_file')
          ) : (
            // TODO: update link to a specific invoice download
            <a
              href={'#'}
              style={{ textDecoration: 'none' }}
              onClick={() => {
                downloadInvoice(cell.row.values);
              }}
            >
              {t('invoices.table.download_pdf')}
            </a>
          );
        },
      },
      {
        Header: t('invoices.table.preview'),
        accessor: 'preview',
        disableSortBy: true,
        Cell: (cell: Cell) => {
          return (
            <StyledIconShow
              onClick={() => {
                setInvoice(cell.row.values.id);
                toggleProfileModal();
              }}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleStatusUpdate = (invoice: string, status: InvoiceStatus) => {
    return Api.settings.invoice.updateStatus(invoice, { status: status });
  };

  const handlePay = async (invoiceId: string) => {
    const response = await Api.settings.invoice.pay(invoiceId);
    response.payment_url && navigateToExternalUrl(response.payment_url);
  };

  const renderStatusDropdown = (
    cell: Cell<InvoicesTableResponse>,
    change: (id: string, status: InvoiceStatus) => void,
    currentStatus: InvoiceStatus,
  ) => {
    return (
      <StyledStatusWrapper>
        <StatusDropdown current={currentStatus}>
          {Object.values(InvoiceStatus).map((status, index) => (
            <DropdownItemWrapper key={index} onClick={() => change(cell.row.values.id, status)}>
              <StyledStatus className={status}>{t(`invoices.status.${status}`)}</StyledStatus>
            </DropdownItemWrapper>
          ))}
        </StatusDropdown>

        {currentStatus === InvoiceStatus.Issued && (
          <StyledPayButton>
            <PrimaryButton onClick={() => handlePay(cell.row.values.id)}>
              {t('common.pay')}
            </PrimaryButton>
          </StyledPayButton>
        )}
      </StyledStatusWrapper>
    );
  };

  const exportInvoices = async () => {
    await Api.settings.invoice
      .export()
      .then((response) =>
        fileDownload(response, `invoices_${formatDate(new Date().toISOString())}.xlsx`),
      )
      .catch(() => error(t('invoices.export_error')));
  };

  const downloadInvoice = async (row: Record<string, any>) => {
    await Api.settings.invoice
      .download(row.id)
      .then((response) =>
        fileDownload(
          response,
          `invoice_${row.invoice_number}_${row.generated_at.split('T')[0]}.pdf`,
        ),
      )
      .catch(() => error(t('invoices.export_error')));
  };

  const formatDate = (date: string) => {
    return date.split('T')[0];
  };
  return (
    <StyledInvoicesSettings>
      <H1>{t('invoices.title')}</H1>

      <H6>{t('invoices.title_extra')}</H6>

      <PrimaryButton onClick={exportInvoices}>
        <IconShareAlt />
        {t('invoices.export_button')}
      </PrimaryButton>

      <StyledInvoicesTable>
        <Table
          setQueryRequest={setTableQueryRequest}
          isLoading={isLoading}
          columns={columns}
          data={data}
          showPageSizes={true}
          searchable={true}
          stickyHeader={true}
        />
      </StyledInvoicesTable>

      <InvoiceModal
        isOpen={isProfileModalOpen}
        setIsOpen={setProfileModalOpen}
        toggle={toggleProfileModal}
        invoiceId={invoice}
      />
    </StyledInvoicesSettings>
  );
};

export default InvoiceSettings;
