import styled from 'styled-components';
import { PrimaryButton, Icon } from '@btc-snxt/ui';

export const ClientsPageWrapper = styled.div`
  ${PrimaryButton} {
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: none;

    ${Icon} {
      height: 1rem;
      width: 1rem;
    }
  }
`;
