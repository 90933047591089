import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconAdd, SecondaryButton } from '@btc-snxt/ui';
import usePermissions from 'helpers/usePermissions';
import { useTabsContext } from 'components/Tabs/TabsContext';
import { MembershipCard } from './components/MembershipCard';
import { MembershipCreateModal } from './components/MembershipCreateModal';
import { MembershipProvider, useMembershipSettingsContext } from './MembershipSettingsContext';
import {
  StyledCardsBlock,
  StyledBody,
  StyledHeaderText,
  StyledMembershipSettings,
  StyledHeader,
  StyledCardWrapper,
  StyledAddPlanButton,
} from './MembershipSettings.style';

const MembershipSettings: React.FC = () => {
  const { t } = useTranslation();

  const tabsContext = useTabsContext();

  const canCreateMembership = usePermissions().hasAny('memberships.create');

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen);

  const { data, isLoading } = useMembershipSettingsContext();

  setTimeout(() => tabsContext.setLoading(isLoading), 0);

  return (
    <StyledMembershipSettings>
      <StyledHeader>
        <StyledHeaderText>{t('membership.header')}</StyledHeaderText>
      </StyledHeader>

      <StyledBody>
        {canCreateMembership && (
          <StyledAddPlanButton>
            <SecondaryButton onClick={toggleCreateModal}>
              <IconAdd />
              {t('membership.add_new_button')}
            </SecondaryButton>
          </StyledAddPlanButton>
        )}

        <StyledCardsBlock>
          {data?.map((membership, index) => (
            <MembershipProvider {...membership} key={index}>
              <StyledCardWrapper>
                <MembershipCard />
              </StyledCardWrapper>
            </MembershipProvider>
          ))}
        </StyledCardsBlock>
      </StyledBody>

      <MembershipCreateModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        toggle={toggleCreateModal}
      />
    </StyledMembershipSettings>
  );
};

export default MembershipSettings;
