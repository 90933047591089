import styled from 'styled-components';
import { NavItem as ReactStrapNavItem } from 'reactstrap';

export const NavItem = styled(ReactStrapNavItem)`
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;
