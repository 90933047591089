import styled from 'styled-components';
import { Icon, ThemeType, breakpointDown, breakpointUp } from '@btc-snxt/ui';
import { NavLink } from 'reactstrap';

export const SidebarHr = styled.hr`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const StyledSidebar = styled.div<{ theme: ThemeType }>`
  position: fixed;
  height: 100%;
  width: 16.5rem;
  padding: 0.5rem 0.625rem;
  border: 0;
  overflow: overlay;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: 0.5s;
  display: flex;
  flex-direction: column;

  // if browser is firefox...
  @-moz-document url-prefix() {
    overflow: auto;
  }

  ${breakpointUp.MD} {
    box-shadow: none;
    left: -17rem;
    margin-top: 0.0625rem;

    &.open {
      left: 0;
    }
  }

  ${breakpointDown.MD} {
    box-shadow: none;
    left: -16.5rem;

    &.open {
      left: 0;
    }
  }

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.grey900};
  }
`;

interface MobileProps {
  $isMobile?: boolean;
}

export const StyledSidebarUpperBlock = styled.div<MobileProps>`
  height: ${(props) => (props.$isMobile ? '100%' : 'calc(100% - 4rem)')};
  overflow: auto;
`;

export const StyledSidebarLowerBlock = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: ${(props) => (props.$isMobile ? '0' : '4rem')};

  hr {
    margin: 0;
  }
`;

export const StyledHr = styled.hr`
  margin: 0 1rem 0.5rem;
`;

export const StyledPrivacyPolicyText = styled.span`
  font-size: 1rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledPrivacyPolicy = styled(NavLink)<MobileProps>`
  color: ${({ theme }) => theme.colors.grey600};
  display: flex;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: ${(props) => (props.$isMobile ? '1rem 1rem 0 1rem' : '1rem')};
`;

export const CreateButton = styled(NavLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 0.5rem;
  height: 2.5rem;
  justify-content: center;
  margin-inline: 0.625rem;
  padding: 0.625rem 1.5rem 0.625rem 1rem;
  text-align: center;

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.white};
    height: 1.125rem;
    width: 1.125rem;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.blue4};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;
