import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { enUS, lt, ru } from 'date-fns/locale';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BOOTSTRAP_SM_CONTAINER_WIDTH } from '@btc-snxt/ui';
import { CalendarInput } from 'pages/Common/Dashboard/components/CalendarInput';
import { StyledCalendarInputsWrapper, StyledDatePicker } from './DashboardCalendar.style';

export interface CalendarDatePickerProps {
  startDate: Date | null | undefined;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null | undefined;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
}

const DashboardCalendar: React.FC<CalendarDatePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const { i18n } = useTranslation();
  const [innerWidth, setInnerWidth] = useState<number>(0);

  useEffect(() => {
    switch (i18n.language) {
      case 'en': {
        registerLocale('en', enUS);
      }
      case 'lt': {
        registerLocale('lt', lt);
      }
      case 'ru': {
        registerLocale('ru', ru);
      }
    }
  }, [i18n.language]);

  const handleChange = (date: [Date | null, Date | null]) => {
    const [start, end] = date;

    setStartDate(start);
    setEndDate(end);
  };

  const formatDate = (date: Date | null | undefined): string => {
    if (date) {
      return format(date, 'yyyy-MM-dd');
    }

    return '';
  };

  const isScreenTabletOrLower = () => {
    return innerWidth < BOOTSTRAP_SM_CONTAINER_WIDTH;
  };

  const ref = useRef(null);

  const handleResize = () => setInnerWidth((ref.current as unknown as HTMLDivElement).offsetWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setInnerWidth((ref.current as unknown as HTMLDivElement).offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const renderCalendarInputs = () => (
    <StyledCalendarInputsWrapper>
      <CalendarInput value={formatDate(startDate)} />

      <CalendarInput value={formatDate(endDate)} />
    </StyledCalendarInputsWrapper>
  );

  return (
    <StyledDatePicker ref={ref} $poppingDisabled={true} $monthsShown={2}>
      {renderCalendarInputs()}

      <ReactDatePicker
        onChange={handleChange}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange={true}
        open={true}
        monthsShown={isScreenTabletOrLower() ? 1 : 2}
        locale={i18n.language}
        inline={true}
      />
    </StyledDatePicker>
  );
};

export default DashboardCalendar;
