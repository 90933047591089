import React from 'react';
import { StyledAuthenticationLayout } from './AuthenticationLayout.style';
import { Theme, GlobalBody } from '@btc-snxt/ui';

const AuthenticationLayout: React.FC = ({ children }) => (
  <>
    <GlobalBody color={Theme.colors.blue} />

    <StyledAuthenticationLayout>{children}</StyledAuthenticationLayout>
  </>
);

export default AuthenticationLayout;
