import React from 'react';
import { GlobalBody, Theme } from '@btc-snxt/ui';
import { StyledSimpleLayout } from './SimpleLayout.style';

const SimpleLayout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalBody color={Theme.colors.white} />

      <StyledSimpleLayout>{children}</StyledSimpleLayout>
    </>
  );
};

export default SimpleLayout;
