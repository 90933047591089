import styled from 'styled-components';
import { IconRemove, SecondaryButton } from '@btc-snxt/ui';

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${SecondaryButton} {
    justify-self: center;
    min-width: 8rem;
  }
`;

export const StyledInputRow = styled.div`
  display: flex;
  justify-content: space-between;

  :first-child {
    justify-content: center;
  }
`;

export const StyledBenefitsWrapper = styled.div`
  ${StyledInputRow}:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
`;

export const StyledDeleteButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  margin-left: 1rem;

  &:hover {
    ${IconRemove} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;
