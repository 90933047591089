import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  IconFilterList,
  IconHome,
  IconInsights,
  IconList,
  IconManageAccounts,
  IconSettings,
} from '@btc-snxt/ui';
import { Permissions } from 'helpers/auth';
import usePermissions from 'helpers/usePermissions';
import { useGenericLayoutStore } from 'layouts/GenericLayout/GenericLayout.store';
import { RouteList } from 'routeList';
import { Nav } from './components/Nav';
import { NavItem } from './components/NavItem';
import { NavLink } from './components/NavLink';
import { SubMenu } from './components/SubMenu';
import {
  StyledHr,
  StyledPrivacyPolicy,
  StyledSidebar,
  StyledSidebarLowerBlock,
  StyledSidebarUpperBlock,
} from './Sidebar.style';

const SidebarFullScreen: React.FC = () => {
  const { t } = useTranslation();

  const remotePermissions = usePermissions();
  const sidebarState = useGenericLayoutStore();

  const renderSubMenu = () => {
    if (
      remotePermissions.hasAny('roles.list_permission') ||
      remotePermissions.hasAny('roles.list')
    ) {
      return <SubMenu />;
    }

    if (remotePermissions.hasAny('users.list')) {
      return (
        <NavItem>
          <NavLink to={RouteList.USERS.path} tag={RouterNavLink}>
            <IconManageAccounts />

            {t('sidebar.users')}
          </NavLink>
        </NavItem>
      );
    }

    return <></>;
  };

  const renderSettings = (): JSX.Element => {
    if (
      remotePermissions.hasAny(Permissions.NOTIFICATIONS.LIST) ||
      remotePermissions.hasAny(Permissions.NEWS.LIST) ||
      remotePermissions.hasAny(Permissions.CONTACT.LIST) ||
      remotePermissions.hasAny(Permissions.PRIVACY_POLICY.LIST) ||
      remotePermissions.hasAny(Permissions.FAQ.LIST) ||
      remotePermissions.hasAny(Permissions.EMAILS.LIST) ||
      remotePermissions.hasAny(Permissions.TRANSLATIONS.LIST) ||
      remotePermissions.hasAny(Permissions.INVOICES.LIST) ||
      remotePermissions.hasAny(Permissions.MEMBERSHIPS.LIST)
    ) {
      return (
        <NavItem>
          <NavLink to={RouteList.SETTINGS.HOME.path} tag={RouterNavLink}>
            <IconSettings />

            {t('sidebar.settings')}
          </NavLink>
        </NavItem>
      );
    }

    return <></>;
  };

  return (
    <StyledSidebar className={sidebarState.isSidebarOpen ? 'open' : ''}>
      <StyledSidebarUpperBlock>
        <Nav vertical>
          <NavItem>
            <NavLink to={RouteList.DASHBOARD.path} tag={RouterNavLink}>
              <IconHome />

              {t('sidebar.dashboard')}
            </NavLink>
          </NavItem>

          {remotePermissions.hasAny('surveys.list') && (
            <NavItem>
              <NavLink to={RouteList.SURVEYS.SURVEY_LIST.path} tag={RouterNavLink}>
                <IconList />

                {t('sidebar.my_surveys')}
              </NavLink>
            </NavItem>
          )}

          {remotePermissions.hasAny('categories.list') && (
            <>
              <NavItem>
                <NavLink to={RouteList.SURVEYS.RESULT_ANALYSIS.path} tag={RouterNavLink}>
                  <IconInsights />

                  {t('sidebar.result_analysis')}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to={RouteList.CATEGORY.CATEGORIES.path} tag={RouterNavLink}>
                  <IconFilterList />

                  {t('sidebar.categories')}
                </NavLink>
              </NavItem>
            </>
          )}

          <StyledHr />

          {renderSubMenu()}

          {renderSettings()}
        </Nav>
      </StyledSidebarUpperBlock>

      <StyledSidebarLowerBlock>
        <StyledPrivacyPolicy to={RouteList.PRIVACY_POLICY.path} tag={RouterNavLink}>
          {t('sidebar.privacy_policy')}
        </StyledPrivacyPolicy>
      </StyledSidebarLowerBlock>
    </StyledSidebar>
  );
};

export default SidebarFullScreen;
