import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Icon } from '@btc-snxt/ui';

export const StyledDropdown = styled(Dropdown)``;

export const StyledDropdownToggle = styled(DropdownToggle)`
  align-items: center;
  box-shadow: ${({ theme }) => theme.boxShadow} !important;
  color: ${({ theme }) => theme.colors.blue};
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${Icon} {
    height: 1rem;
    width: 1rem;
    transition: 0.15s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue} !important;

    ${Icon} {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;

  button {
    div {
      display: flex;
      font-size: 1rem;
      padding: 0.5rem;
      width: 100%;
    }
  }
`;

export const StyledTitleBlock = styled.div`
  font-size: 1rem;
  width: 100%;
`;

export const StyledIconBlock = styled.div``;
