import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IconArrowDown, DropdownWrapper } from '@btc-snxt/ui';
import { InvoiceStatus } from 'api/types/settings/settings';
import { StyledStatus } from 'pages/Common/Settings/tabs/InvoiceSettings/InvoiceSettings.style';

interface Props {
  current: InvoiceStatus;
}

const StatusDropdown: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!isOpen);

  return (
    <DropdownWrapper>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle>
          <StyledStatus className={props.current}>
            {t(`invoices.status.${props.current}`)}
          </StyledStatus>

          <IconArrowDown />
        </DropdownToggle>

        <DropdownMenu>
          {React.Children.map(props.children, (menuItem, index) => (
            <DropdownItem key={index}>{menuItem}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </DropdownWrapper>
  );
};

export default StatusDropdown;
