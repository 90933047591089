import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteList } from 'routeList';
import { Logo, LanguageDropdown } from '@btc-snxt/ui';
import {
  StyledAuthBlock,
  StyledAuthBody,
  StyledAuthButton,
  StyledAuthHeader,
  StyledButtonWrapper,
  StyledLanguageSelect,
  StyledLogo,
  StyledTextBlock,
  StyledTextBody,
  StyledTitle,
} from 'layouts/AuthenticationLayout/AuthenticationLayout.style';
import { StyledSuccessMessage } from './VerifyEmail.style';

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAuthBlock>
      <StyledAuthHeader>
        <StyledLogo>
          <Link to={'/'}>
            <Logo />
          </Link>
        </StyledLogo>

        <StyledTitle>
          <StyledTextBlock>
            <StyledTextBody>{t('auth.register.email_verification')}</StyledTextBody>
          </StyledTextBlock>

          <StyledLanguageSelect>
            <LanguageDropdown />
          </StyledLanguageSelect>
        </StyledTitle>
      </StyledAuthHeader>
      <StyledAuthBody>
        <StyledSuccessMessage>{t('auth.register.verify_success')}</StyledSuccessMessage>

        <StyledButtonWrapper>
          <NavLink to={RouteList.AUTHENTICATION.LOGIN.path}>
            <StyledAuthButton type={'button'}>{t('auth.login.login')}</StyledAuthButton>
          </NavLink>
        </StyledButtonWrapper>
      </StyledAuthBody>
    </StyledAuthBlock>
  );
};

export default VerifyEmail;
