import styled from 'styled-components';
import { Icon, Input, breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledGraphTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StyledGraph = styled.div`
  margin-bottom: 1rem;
  flex: 1 0 0;
`;

export const StyledGraphOverview = styled.div`
  flex: 1 0 0;
  margin-bottom: 1rem;

  ${breakpointDown.XL} {
    :first-child {
      margin-bottom: 1rem;
    }
  }

  ${breakpointUp.XL} {
    :first-child {
      margin-right: 1rem;
    }
  }
`;

export const StyledFilterIconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  &:hover {
    ${Icon} {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${Icon} {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const StyledFilterBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledSelectableFilter = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;

  ${Input} {
    margin: 0;
  }
`;
