const Permissions = {
  USERS: {
    LIST: 'users.list',
    GET: 'users.get',
    CREATE: 'users.create',
    UPDATE: 'users.update',
    DELETE: 'users.delete',
  },
  CLIENTS: {
    LIST: 'clients.list',
    GET: 'clients.get',
    CREATE: 'clients.create',
    UPDATE: 'clients.update',
    DELETE: 'clients.delete',
    UPDATE_ACTIVE: 'clients.update_active',
  },
  ROLES: {
    LIST: 'roles.list',
    GET_ROLE: 'roles.get_role',
    ADD_ROLE: 'roles.add_role',
    UPDATE_ROLE: 'roles.update_role',
    DELETE_ROLE: 'roles.delete_role',
    LIST_PERMISSION: 'roles.list_permission',
    ADD_PERMISSION: 'roles.add_permission',
    DELETE_PERMISSION: 'roles.delete_permission',
  },
  INVOICES: {
    LIST: 'invoices.list',
    EXPORT: 'invoices.export',
    UPDATE: 'invoices.update',
  },
  SURVEYS: {
    LIST: 'surveys.list',
    GET: 'surveys.get',
    CREATE: 'surveys.create',
    EDIT: 'surveys.edit',
  },
  TRANSLATIONS: {
    LIST: 'translations.list',
    UPDATE: 'translations.update',
  },
  MEMBERSHIPS: {
    LIST: 'memberships.list',
    GET: 'memberships.get',
    CREATE: 'memberships.create',
    UPDATE: 'memberships.update',
    DELETE: 'memberships.delete',
    VIEW_INACTIVE: 'memberships.view_inactive',
  },
  CATEGORIES: {
    LIST: 'categories.list',
    CREATE: 'categories.create',
    GET: 'categories.get',
    DELETE: 'categories.delete',
    UPDATE: 'categories.update',
  },
  ANALYSIS: {
    LIST: 'analysis.list',
  },
  NOTIFICATIONS: {
    LIST: 'notifications.list',
  },
  NEWS: {
    LIST: 'news.list',
  },
  SYSTEM: {
    LIST: 'system.list',
  },
  CONTACT: {
    LIST: 'contact.list',
  },
  PRIVACY_POLICY: {
    LIST: 'privacy_policy.list',
    UPDATE: 'privacy_policy.update',
  },
  FAQ: {
    LIST: 'faq.list',
  },
  EMAILS: {
    LIST: 'emails.list',
  },
};

export default Permissions;
