import styled from 'styled-components';
import { Button } from 'reactstrap';
import { breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledTabHeader = styled.div`
  margin-bottom: 1rem;

  ${breakpointDown.XL} {
    width: 100%;
  }

  ${breakpointUp.XL} {
    display: flex;
    gap: 0.5rem;
  }
`;

export const StyledTabButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;

  &.active {
    background-color: ${({ theme }) => theme.colors.blue};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue3} !important;
  }
`;

export const StyledTabBody = styled.div``;

export const StyledDropdownItem = styled.div``;
