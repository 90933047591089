import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { buildYupLocale, buildYupValidations } from '@btc-snxt/system';
import { format, parseISO } from 'date-fns';
import { Http } from 'api';
import { Locale } from '@btc-snxt/ui';

const languageMapping: Record<string, Locale> = {
  'en-US': Locale.EN,
  'lt-LT': Locale.LT,
  'ru-RU': Locale.RU,
};

const determineLanguage = (): string => {
  const storedLanguage: string | null = localStorage.getItem('i18nextLng');

  if (storedLanguage !== null && storedLanguage in languageMapping) {
    return languageMapping[storedLanguage];
  }

  return Locale.EN;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: `${Http.baseUrl}/common/language/{{lng}}`,
        allowMultiLoading: false,
      },
      load: 'languageOnly',
      preload: ['en'],
      fallbackLng: ['en', 'lt', 'ru'],
      supportedLngs: ['en', 'lt', 'ru'],
      saveMissing: true,
      missingKeyHandler: (lngs, ns, key) => {
        console.info('Missing key translation key', key, lngs);
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format: function (value, formatting, lng, options) {
          if (formatting && options && 'date' in options && options['date']) {
            const date = options['date'];
            return format(parseISO(date), formatting);
          }

          return value;
        },
      },
      detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'i18nextLng',
      },
    },
    () => {
      const lang = determineLanguage();
      i18n.changeLanguage(lang);

      buildYupLocale();
      buildYupValidations();
    },
  );

export default i18n;
