import { UseQueryOptions } from 'react-query';

type UseQueryOptionsObject<TQueryFnData = unknown, TError = unknown> = Omit<
  UseQueryOptions<TQueryFnData, TError>,
  'queryKey' | 'queryFn'
>;

const defaultOptions: Omit<UseQueryOptions<any, any, any, any>, 'queryKey' | 'queryFn'> = {
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
  retryDelay: 1000,
};

export type { UseQueryOptionsObject };
export { defaultOptions };
