import { CenteredSpinner, Col, Row, Select } from '@btc-snxt/ui';
import { Api } from 'api';
import { NodeResponse, ResultAnalysisResponseDto, SurveyTableResponse } from 'api/types/survey';
import React, { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { Label, MultiSelectDropdownWrapper } from './ResultAnalysis.style';

interface Props {
  survey: SurveyTableResponse | undefined;
  setCategoryListData: React.Dispatch<React.SetStateAction<number[]>>;
  setFilterRootCategory: React.Dispatch<React.SetStateAction<boolean>>;
}

const SurveyController: React.FC<Props> = (props) => {
  const [categoryList, setCategoryList] = useState<ResultAnalysisResponseDto[]>([]);
  const [initialCategoryList, setInitialCategoryList] = useState<ResultAnalysisResponseDto[]>([]);
  const [availableOptions, setAvailableOptions] = useState<any[]>([]);
  const [initialavailableOptions, setInitialavailableOptions] = useState<any[]>([]);
  const [selectedAvailableOptions, setSelectedAvailableOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Initial selection of survey, that fetch data and loads categories
  const handleSurveyClick = async () => {
    try {
      setIsLoading(true);
      const data = await Api.survey.survey.getSurvey(props.survey?.id as unknown as string);
      formSelect(data);
      setCategoryList(structuredClone(data));
      setInitialCategoryList(structuredClone(data));
      setIsLoading(false);
    } catch {
      setCategoryList([]);
    }
  };

  useEffect(() => {
    handleSurveyClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This must be done because multi select needs to have value and label properties, for each category
  const formSelect = (data: ResultAnalysisResponseDto[]) => {
    const categoryNodesAvailableOptions: any[] = [];
    Object.values(data).map((categories, index) => {
      const formOptionArray: NodeResponse[] = [];
      const newSelectedAvailableOptions = selectedAvailableOptions;
      newSelectedAvailableOptions[index] = [];
      setSelectedAvailableOptions(newSelectedAvailableOptions);
      Object.values(categories.nodes).map((node) => {
        const { id, category_collection_id, name } = node;

        formOptionArray.push({
          id,
          category_collection_id,
          name,
          value: name,
          label: name,
        });
      });
      categoryNodesAvailableOptions.push(formOptionArray);
    });
    setAvailableOptions(categoryNodesAvailableOptions);
    setInitialavailableOptions(structuredClone(categoryNodesAvailableOptions));
  };

  const remakeChildCategoriesRecursively = (
    iteration: number,
    categoryIndex: number,
    listOfParents: NodeResponse[],
  ) => {
    const childCategoryNodeList: NodeResponse[] = [];
    const childNodeList = structuredClone(initialCategoryList[iteration]);
    const partentIndex = categoryIndex + 1;

    if (listOfParents.length == 0 || !childNodeList) {
      return;
    }

    // Collect child category nodes
    listOfParents.forEach((item: NodeResponse) => {
      childNodeList.nodes.forEach((currentElement: NodeResponse) => {
        if (currentElement.parent_id == item.id) {
          currentElement.value = currentElement.name;
          currentElement.label = currentElement.name;
          childCategoryNodeList.push(currentElement);
        }
      });
    });

    const newCategoryList = categoryList;
    newCategoryList[partentIndex].nodes = childCategoryNodeList;
    setCategoryList([...newCategoryList]);

    const newAvailableOptions = availableOptions;
    newAvailableOptions[partentIndex] = childCategoryNodeList;
    setAvailableOptions([...newAvailableOptions]);

    iteration++;

    remakeChildCategoriesRecursively(iteration, partentIndex, childCategoryNodeList);
  };

  // Current filter selected
  const handleFilterClick = (dropdownIndex: number, newValue: NodeResponse[]) => {
    props.setFilterRootCategory(false);
    const categories = categoryList;
    categories[dropdownIndex].nodes = newValue;
    setCategoryList([...categories]);

    remakeChildCategoriesRecursively(dropdownIndex + 1, dropdownIndex, newValue);

    checkChildCategoriesSelectedNodes(dropdownIndex);

    const filteredIds = formDataToExport(dropdownIndex);
    props.setCategoryListData(filteredIds);
  };

  const formDataToExport = (dropdownIndex: number): number[] => {
    let isCategorySet = false;
    let filteredIds: number[] = [];
    for (let index = dropdownIndex; index < categoryList.length; index++) {
      if (selectedAvailableOptions[index].length != 0) {
        isCategorySet = true;
        filteredIds = [];
        selectedAvailableOptions[index].forEach((value: any) => {
          filteredIds.push(value.id);
        });
      } else {
        availableOptions[index].forEach((value: any) => {
          filteredIds.push(value.id);
        });
      }
    }

    // If there are no seleceted categories we send all of them
    if (!isCategorySet) {
      initialCategoryList.forEach((value) => {
        value.nodes.forEach((val) => {
          filteredIds.push(val.id);
        });
      });
    }
    return filteredIds;
  };

  // Logic to handle selected availableOptions
  const checkChildCategoriesSelectedNodes = (dropdownIndex: number) => {
    const childNode = dropdownIndex + 1;
    for (let index = childNode; index < categoryList.length; index++) {
      if (selectedAvailableOptions[index]) {
        const filteredNodes: NodeResponse[] = [];
        selectedAvailableOptions[index].forEach((option: any) => {
          const filteredNode = categoryList[index].nodes.filter(
            (element) => element.id == option.id,
          );
          if (filteredNode.length > 0) {
            filteredNodes.push(filteredNode[0]);
          }
        });
        const newSelectedAvailableOptions = selectedAvailableOptions;
        newSelectedAvailableOptions[index] = filteredNodes;
        setSelectedAvailableOptions(newSelectedAvailableOptions);
      }
    }
  };

  // Current filter selected
  const setSelectedOption = (newValue: MultiValue<any>, dropdownIndex: number) => {
    const availableOptions = selectedAvailableOptions;
    availableOptions[dropdownIndex] = newValue;
    setSelectedAvailableOptions([...availableOptions]);
  };

  // If node is unselected, we make all the child categories option values check
  const handleRemovedOption = (dropdownIndex: number) => {
    // Handling root category
    if (dropdownIndex === 0 && selectedAvailableOptions[dropdownIndex].length === 0) {
      checkChildCategoriesSelectedNodes(dropdownIndex);
      const newInitialavailableOptions = initialavailableOptions;
      setAvailableOptions([...newInitialavailableOptions]);
      props.setFilterRootCategory(true);
      return;
    }
    // Check if category dropdown is not empty
    if (selectedAvailableOptions[dropdownIndex].length !== 0) {
      handleFilterClick(dropdownIndex, selectedAvailableOptions[dropdownIndex]);
      // Find parent category list that is not empty to form selectable child categories node list
    } else {
      for (let index = dropdownIndex; index > 0; index--) {
        if (
          selectedAvailableOptions[index].length === 0 &&
          selectedAvailableOptions[index - 1].length !== 0
        ) {
          handleFilterClick(index - 1, selectedAvailableOptions[index - 1]);
          return;
        }
      }
      // If last category filter is removed, update child nodes based on available current category options
      handleFilterClick(dropdownIndex, availableOptions[dropdownIndex]);
    }
  };

  if (isLoading) {
    return <CenteredSpinner></CenteredSpinner>;
  }
  return (
    <Row>
      {Object.values(categoryList).map((category, dropdownIndex) => (
        <Col xs={12} md={6} lg={3} key={dropdownIndex}>
          <MultiSelectDropdownWrapper>
            <Label>{category.name}</Label>
            <Select
              classNamePrefix={'react-select'}
              isMulti={true}
              closeMenuOnSelect={false}
              placeholder={category.name + '...'}
              onChange={(newValue, actionMeta) => {
                setSelectedOption(newValue as MultiValue<any>, dropdownIndex);
                handleFilterClick(dropdownIndex, newValue as unknown as NodeResponse[]);
                if (actionMeta.removedValue || actionMeta.removedValues) {
                  handleRemovedOption(dropdownIndex);
                }
              }}
              value={selectedAvailableOptions[dropdownIndex]}
              options={availableOptions[dropdownIndex]}
            />
          </MultiSelectDropdownWrapper>
        </Col>
      ))}
    </Row>
  );
};

export default SurveyController;
