import {
  OpinionScaleQuestionItemDesignerValues,
  OpinionScaleType,
  SingleSelectionQuestionItemDesignerValues,
  LikertItemDesignerValues,
  SurveyItemType,
  TemplateItem,
  TextQuestionItemDesignerValues,
} from '@btc-snxt/survey';
import { SurveyEditorMode } from 'helpers/enums';
import { SurveyType } from '@btc-snxt/survey';
import { convertStringToUrlFriendly } from 'helpers/common';

interface TemplateSettings {
  isEditable: boolean;
  items: TemplateItem[];
}

export const getTemplateSettings = (type: SurveyType): TemplateSettings => {
  switch (type) {
    case SurveyType.CustomerSatisfaction:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'How satisfied are you about the following?',
                  lt: 'Kaip patenkinti esate šiais dalykais?',
                  ru: 'Насколько вы удовлетворены следующим?',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I was satisfied with the service provided',
                          lt: 'Likau patenkinta/as suteikta paslauga',
                          ru: 'Я был (-а) удовлетворен (-а) оказанными услугами',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I was delighted with the service provided',
                          lt: 'Likau visiškai patenkintas (-a) suteikta paslauga ',
                          ru: 'Я был (-а) в восторге от оказанных услуг',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I was unhappy with the service provided',
                          lt: 'Buvau nepatenkintas suteikta paslauga',
                          ru: 'Я был (-а) недоволен (-а) оказанными услугами',
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  {
                    en: 'Strongly disagree',
                    lt: 'Labai nesutinku',
                    ru: 'Категорически не согласен',
                  },
                  { en: 'Disagree', lt: 'Nesutinku', ru: 'Не согласен' },
                  { en: 'Neutral', lt: 'Neutralu', ru: 'Нейтральный' },
                  { en: 'Agree', lt: 'Sutinku', ru: 'Соглашаться' },
                  { en: 'Strongly agree', lt: 'Labai sutinku', ru: 'Полностью согласен' },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '2',
            type: SurveyItemType.Outro,
          },
        ],
      };
    case SurveyType.CustomerEffort:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'Effort to submit a request',
                  lt: 'Pastangos užklausai pateikti ',
                  ru: 'Усилия при запросе',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'How much effort did you personally have to put forth to handle your request?',
                          lt: 'Kiek pastangų Jūs asmeniškai turėjote įdėti, kad Jūsų prašymas būtų įvykdytas?',
                          ru: 'Сколько усилий пришлось приложить лично Вам, чтобы Ваш запрос был рассмотрен? ',
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  { en: 'Very low', lt: 'Labai mažai', ru: 'Очень мало' },
                  { en: 'Low', lt: 'Mažai', ru: 'Мало' },
                  { en: 'Neither low nor high', lt: 'Nei daug nei mažai', ru: 'Ни мало, ни много' },
                  { en: 'High', lt: 'Daug', ru: 'Много' },
                  { en: 'Very high', lt: 'Labai daug', ru: 'Очень много' },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '2',
            type: SurveyItemType.Outro,
          },
        ],
      };
    case SurveyType.ProductExperience:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'Please indicate how much you agree with the following statements',
                  lt: 'Nurodykite, kiek sutinkate su toliau pateiktais teiginiais',
                  ru: 'Укажите, насколько Вы согласны со следующими утверждениями',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' has a good reputation.",
                          lt: '„ĮMONĖ“ turi gerą reputaciją.',
                          ru: "'КОМПАНИЯ' имеет хорошую репутацию.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "I am confident in 'COMPANY’s' expertise.",
                          lt: 'Pasitikiu „ĮMONĖS“ kompetencija',
                          ru: "Я уверен (-а) в компетентности 'КОМПАНИИ'.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' gives independent advice (on which product/service will best suit my needs).",
                          lt: '„ĮMONĖ” pateikia nepriklausomą patarimą(koks produktas/paslauga man tinkamiausi).',
                          ru: "'КОМПАНИЯ' предоставляет беспристрастные консультации (о том, какой товар и (или) услуга лучше всего подойдет для моих нужд).",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "I choose 'COMPANY' not because of the price alone.",
                          lt: 'Renkuosi „ĮMONĘ" ne vien dėl kainos.',
                          ru: "Я выбираю эту 'КОМПАНИЮ' не только из-за цены.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "The people who work at 'COMPANY' represent the COMPANY brand well.",
                          lt: 'Žmonės dirbantys „ĮMONĖJE“ gerai atstovauja prekės ženklą.',
                          ru: "Сотрудники 'КОМПАНИИ' хорошо представляют бренд 'КОМПАНИИ'.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' offerings have the best quality.",
                          lt: '„ĮMONĖS“ pasiūlymai yra aukščiausios kokybės',
                          ru: "Предложения 'КОМПАНИИ' отличаются хорошим качеством.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' offerings are superior.",
                          lt: '„ĮMONĖS“ pasiūlymai yra pranašesni.',
                          ru: "Предложения 'КОМПАНИИ' превосходят все остальные.",
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  {
                    en: 'Strongly disagree',
                    lt: 'Labai nesutinku',
                    ru: 'Полностью не согласен (-а)',
                  },
                  { en: 'Disagree', lt: 'Nesutinku', ru: 'Не согласен (-а)' },
                  {
                    en: 'Neither agree nor disagree',
                    lt: 'Nei sutinku nei nesutinku',
                    ru: 'Придерживаюсь нейтрального мнения',
                  },
                  { en: 'Agree', lt: 'Sutinku', ru: 'Согласен (-а)' },
                  { en: 'Strongly agree', lt: 'Labai sutinku', ru: 'Полностью согласен (-а)' },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '8',
            type: SurveyItemType.Outro,
          },
        ],
      };
    case SurveyType.CustomerExperience:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'Please indicate how much you agree with the following statements',
                  lt: 'Nurodykite, kiek sutinkate su toliau pateiktais teiginiais',
                  ru: 'Укажите, насколько Вы согласны со следующими утверждениями',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' advises(d) me throughout the process.",
                          lt: '„ĮMONĖ“ duoda(davė) patarimus proceso metu.',
                          ru: "'КОМПАНИЯ' консультирует (-овала) меня на протяжении всего процесса",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "Dealing with 'COMPANY' is easy.",
                          lt: 'Turėti reikalų su „ĮMONĖ“ yra paprasta.',
                          ru: "Иметь дело с 'КОМПАНИЕЙ' легко.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' keeps me informed.",
                          lt: '„ĮMONĖ“ nuolatos mane informuoja.',
                          ru: "'КОМПАНИЯ' держит меня в курсе.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' demonstrates flexibility in dealing with me.",
                          lt: '„ĮMONĖ“ yra lanksti mano atžvilgiu.',
                          ru: "'КОМПАНИЯ' проявляет гибкость в общении со мной.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "At 'COMPANY' I always deal with the same forms and/or same people.",
                          lt: '„Įmonėje“ visada bendrauju tais pačiais kanalais ir (arba) su tais pačiais žmonėmis',
                          ru: "В 'КОМПАНИИ' я всегда имею дело с одними и теми же формами взаимодействия и (или) общаюсь с одними и теми же людьми.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' personnel relate to my wishes and concerns.",
                          lt: '„Įmonės“ darbuotojai atsižvelgia į mano norus ir rūpesčius',
                          ru: "Сотрудники 'КОМПАНИИ' учитывают мои пожелания и обеспокоенность.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' offerings are superior.",
                          lt: '„Įmonės“ teikiami pasiūlymai yra aukštos kokybės',
                          ru: "Предложения 'КОМПАНИИ' превосходят все остальные",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "The people I am dealing with (at 'COMPANY') have good people skills.",
                          lt: 'Žmonės, su kuriais bendrauju („įmonėje“), turi gerus bendravimo įgūdžius',
                          ru: "Сотрудники, с которыми я имею дело (в 'КОМПАНИИ'), обладают хорошими навыками общения с людьми.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY' delivers a good customer service.",
                          lt: '„Įmonės“ klientų aptarnavimo lygis yra aukštas',
                          ru: "'КОМПАНИЯ' обеспечивает хорошее обслуживание клиентов.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "I have built a personal relationship with the people at 'COMPANY'.",
                          lt: 'Užmezgiau asmeninius santykius su „įmonės“ darbuotojais',
                          ru: "Я установил (-а) личные отношения с сотрудниками 'КОМПАНИИ'.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' facilities are better designed to fulfill my needs than their competitors.",
                          lt: '„Įmonės“ patalpos yra geriau pritaikytos mano poreikiams patenkinti nei jų konkurentų',
                          ru: "Возможности 'КОМПАНИИ' лучше приспособлены для удовлетворения моих потребностей, чем у конкурентов.",
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: "'COMPANY’s' (online and/or offline) facilities are designed to be as efficient as possible (for me).",
                          lt: '„Įmonės“ paslaugos (internetinės ir fizinės) yra sukurtos taip, kad būtų kuo veiksmingesnės kliento atžvilgiu',
                          ru: "Возможности 'КОМПАНИИ' (онлайн и (или) офлайн) разработаны таким образом, чтобы быть максимально эффективными (для меня).",
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  {
                    en: 'Strongly disagree',
                    lt: 'Labai nesutinku',
                    ru: 'Полностью не согласен (-а)',
                  },
                  { en: 'Disagree', lt: 'Nesutinku', ru: 'Не согласен (-а)' },
                  {
                    en: 'Neither agree nor disagree',
                    lt: 'Nei sutinku nei nesutinku',
                    ru: 'Придерживаюсь нейтрального мнения',
                  },
                  { en: 'Agree', lt: 'Sutinku', ru: 'Согласен (-а)' },
                  { en: 'Strongly agree', lt: 'Labai sutinku', ru: 'Полностью согласен (-а)' },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '13',
            type: SurveyItemType.Outro,
          },
        ],
      };
    case SurveyType.EmployeeSatisfaction:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'Please indicate how much you agree with the following statements',
                  lt: 'Nurodykite, kiek sutinkate su toliau pateiktais teiginiais',
                  ru: 'Укажите, насколько Вы согласны со следующими утверждениями',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I enjoy my work most days.',
                          lt: 'Didžiąją dalį dienų mėgaujuosi savo darbu',
                          ru: 'В большинство дней я получаю удовольствие от работы.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I do interesting and challenging work.',
                          lt: 'Dirbu įdomų ir kupiną iššūkių darbą',
                          ru: 'Я выполняю интересную и сложную работу.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I am satisfied with my job.',
                          lt: 'Aš patenkintas (-a) savo darbu',
                          ru: 'Я доволен (-на) своей работой.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I am noticed when I do a good job.',
                          lt: 'Būnu pastebėtas (-a), kai gerai atlieku savo darbą',
                          ru: 'Меня замечают, когда я хорошо делаю свою работу.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I get full credit for the work I do.',
                          lt: 'Aš prisiimu visišką atsakomybę už atliekamą darbą',
                          ru: 'Я получаю полное признание за проделанную работу.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'There is a lot of variety in my job.',
                          lt: 'Mano darbe yra daug įvairovės',
                          ru: 'В моей работе много разнообразия.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel the level of responsibility I am given is acceptable.',
                          lt: 'Manau, kad man tenkančios atsakomybės lygis yra priimtinas',
                          ru: 'Я считаю, что уровень возложенной на меня ответственности является приемлемым.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en:
                            'I have a clear understanding of my job responsibilities and \n' +
                            'what is expected of me.\n',
                          lt: 'Aiškiai suprantu savo darbo pareigas ir ko iš manęs yra tikimąsi',
                          ru: 'Я четко понимаю свои должностные обязанности и чего от меня ожидают',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'The major satisfaction in my life comes from my job.',
                          lt: 'Didžiąją dalį pasitenkinimo man gyvenime suteikia darbas',
                          ru: 'Главное удовлетворение в жизни мне приносит моя работа. ',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I often think about leaving (r)',
                          lt: 'Dažnai galvoju apie išėjimą iš darbo',
                          ru: 'Я часто думаю об уходе. (r)',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I know the standards of work expected of me.',
                          lt: 'Žinau, kokių darbo standartų yra tikimąsi iš manęs',
                          ru: 'Я знаю, каких стандартов работы от меня ждут.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel my opinion counts in the organisation.',
                          lt: 'Manau, kad mano nuomonė organizacijoje yra svarbi',
                          ru: 'Я чувствую, что с моим мнением в организации считаются.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I know where to get help if I have a problem at work.',
                          lt: 'Žinau, kur kreiptis pagalbos, jei kiltų problemų darbe',
                          ru: 'Я знаю, куда обратиться за помощью, если на работе у меня возникнут проблемы.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel my colleagues treat me with respect.',
                          lt: 'Jaučiu, kad kolegos su manimi elgiasi pagarbiai',
                          ru: 'Я чувствую, что мои коллеги относятся ко мне с уважением.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel my views count in my section.',
                          lt: 'Jaučiu, kad mano nuomonė yra svarbi mano skyriuje',
                          ru: 'Я чувствую, что с моим мнением в подразделении считаются.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'My job fully uses my skills.',
                          lt: 'Darbe panaudoju visus savo įgūdžius',
                          ru: 'Мой работа позволяет в полной мере использовать мои навыки.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I have skills that are not used in my job (r)',
                          lt: 'Turiu įgūdžių, kurie nėra tinkamai panaudojami darbe',
                          ru: 'У меня есть навыки, которые не используются в моей работе. (r)',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel I am doing a worthwhile job.',
                          lt: 'Jaučiu, kad atlieku vertingą darbą',
                          ru: 'Я чувствую, что занимаюсь достойным делом.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I get a feeling of accomplishment from my job.',
                          lt: 'Iš savo darbo gaunu pasitenkinimo jausmą',
                          ru: 'Я получаю удовлетворение от своей работы.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I feel valued by senior management.',
                          lt: 'Jaučiuosi vertinamas (-a) vadovybės',
                          ru: 'Я чувствую, что старшее руководство меня ценит.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I get full credit for the work I do.',
                          lt: 'Už atliktą darbą gaunu visus nuopelnus.',
                          ru: 'Я получаю полное признание за проделанную работу.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'I am noticed when I do a good job.',
                          lt: 'Esu pastebėtas, kai atlieku gerą darbą',
                          ru: 'Меня замечают, когда я хорошо делаю свою работу.',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'My immediate manager lets me know how I am doing.',
                          lt: 'Mano tiesioginis vadovas informuoja, kaip man sekasi',
                          ru: 'Мой непосредственный руководитель дает мне знать, как я работаю.',
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  {
                    en: 'Strongly disagree',
                    lt: 'Labai nesutinku',
                    ru: 'Полностью не согласен (-а)',
                  },
                  { en: 'Disagree', lt: 'Nesutinku', ru: 'Не согласен (-а)' },
                  {
                    en: 'Neither agree nor disagree',
                    lt: 'Nei sutinku nei nesutinku',
                    ru: 'Придерживаюсь нейтрального мнения',
                  },
                  { en: 'Agree', lt: 'Sutinku', ru: 'Согласен (-а)' },
                  { en: 'Strongly agree', lt: 'Labai sutinku', ru: 'Полностью согласен (-а)' },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '24',
            type: SurveyItemType.Outro,
          },
        ],
      };
    case SurveyType.LoyaltyIntentions:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.Likert,
            initialValues: {
              color: '#FFFFFF',
              question_text: {
                data: {
                  en: 'Please indicate how much you agree with the following statements',
                  lt: 'Nurodykite, kiek sutinkate su toliau pateiktais teiginiais',
                  ru: 'Укажите, насколько Вы согласны со следующими утверждениями',
                },
              },
              questions: [
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: '_______________________ again',
                          lt: '____________________________ rinktumėtės dar kartą',
                          ru: '_________________ еще раз',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'Recommend this _____________ to others',
                          lt: 'Rekomenduotumėte šį _____________ kitiems',
                          ru: 'Рекомендуете компанию _____________ другим',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'Consider this _________ your first choice',
                          lt: 'Apsvarstytumėte šį _____________ kaip savo pirmąjį pasirinkimą',
                          ru: 'Рассмотрите компанию _________ в качестве первого выбора ',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'Continue to __________ if prices increase somewhat',
                          lt: 'Tęstumėte naudojimąsi __________, jei kainos šiek tiek padidėtų',
                          ru: 'Останетесь клиентом компании __________, даже если цены немного вырастут',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'Pay a higher price than competitors charge to _____________________',
                          lt: 'Mokėtumėte didesnę nei rinkos kainą_________________________',
                          ru: 'Будете готовы платить более высокую цену, чем у конкурентов _____________________',
                        },
                      },
                    },
                    required: true,
                  },
                },
                {
                  design_traits: {
                    data: {
                      color: '#FFFFFF',
                      question_text: {
                        data: {
                          en: 'Discourage others from patronizing _________________. (r)',
                          lt: 'Atkalbinėtumėte kitus asmenis nuo rekomendavimo _________________',
                          ru: 'Будете отговаривать других от лояльности к _________________. (r)',
                        },
                      },
                    },
                    required: true,
                  },
                },
              ],
              answers: {
                data: [
                  {
                    en: 'Very dissatisfied',
                    lt: 'Labai nepatenkintas',
                    ru: 'Очень неудовлетворен (-а)',
                  },
                  { en: 'Dissatisfied', lt: 'Nepatenkintas', ru: 'Неудовлетворен (-а)' },
                  {
                    en: 'Neither dissatisfied nor satisfied',
                    lt: 'Nei patenkintas nei nepatenkintas',
                    ru: 'Отношусь нейтрально',
                  },
                  { en: 'Satisfied', lt: 'Patenkintas', ru: 'Удовлетворен (-а)' },
                  {
                    en: 'Very satisfied',
                    lt: 'Labai patenkintas',
                    ru: 'Очень удовлетворен (-а) ',
                  },
                ],
              },
              required: true,
            } as LikertItemDesignerValues,
          },
          {
            id: '7',
            type: SurveyItemType.Outro,
          },
        ],
      };
    // No translation
    case SurveyType.NPS:
      return {
        isEditable: false,
        items: [
          {
            id: '0',
            type: SurveyItemType.Intro,
          },
          {
            id: '1',
            type: SurveyItemType.OpinionScaleQuestion,
            initialValues: {
              color: '#0000000',
              question_text: {
                data: {
                  en: 'How likely are you to recommend us to a friend or colleague?',
                  lt: 'Ar rekomenduotumėte mus draugui ar kolegai?',
                  ru: 'Насколько вероятно, что вы порекомендуете нас другу или коллеге?',
                },
              },
              answers: {
                data: {
                  count: 11,
                  type: OpinionScaleType.Number,
                },
                value: undefined,
              },
              required: true,
              value: undefined,
            } as OpinionScaleQuestionItemDesignerValues,
          },
          {
            id: '2',
            type: SurveyItemType.SingleSelectionQuestion,
            initialValues: {
              color: '#0000000',
              question_text: {
                data: {
                  en: 'Why did you give us this score?',
                  lt: 'Kodėl mums skyrėte tokį balą?',
                  ru: 'Почему вы дали нам этот балл',
                },
              },
              answers: {
                data: [
                  {
                    en: 'Features and functionality',
                    lt: 'Funkcijos ir funkcionalumas',
                    ru: 'Особенности и функциональность',
                  },
                  {
                    en: 'Customer services experience',
                    lt: 'Klientų aptarnavimo patirtis',
                    ru: 'Опыт обслуживания клиентов',
                  },
                  {
                    en: 'Product implementation',
                    lt: 'Produkto įgyvendinimas',
                    ru: 'Реализация продукта',
                  },
                  { en: 'Pricing', lt: 'Kaina', ru: 'Цены' },
                  { en: 'Other', lt: 'Kita', ru: 'Другой' },
                ],
                value: undefined,
              },

              required: true,
            } as SingleSelectionQuestionItemDesignerValues,
          },
          {
            id: '3',
            type: SurveyItemType.TextQuestion,
            initialValues: {
              color: '#0000000',
              question_text: {
                data: {
                  en: 'Is there anything else you would like to share?',
                  lt: 'Ar yra dar kas nors, kuo norėtumėte pasidalinti?',
                  ru: 'Есть что-нибудь еще, чем вы хотели бы поделиться?',
                },
              },
              answers: {
                data: {
                  en: 'Answer',
                  lt: 'Atsakymas',
                  ru: 'отвечать',
                },
                value: undefined,
              },
              required: true,
            } as TextQuestionItemDesignerValues,
          },
          {
            id: '4',
            type: SurveyItemType.Outro,
          },
        ],
      };
  }

  return {
    isEditable: true,
    items: [
      {
        id: '0',
        type: SurveyItemType.Intro,
      },
      {
        id: '1',
        type: SurveyItemType.Outro,
      },
    ],
  };
};

export const getUrl = (
  mode: SurveyEditorMode,
  surveyType: SurveyType,
  surveyId: string | undefined,
) => {
  let url = `/survey/${convertStringToUrlFriendly(surveyType)}/${mode}`;

  if (surveyId) {
    url = `${url}/${surveyId}`;
  }

  return url;
};
