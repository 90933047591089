interface ConsoleHandlerFunction {
  (...data: any[]): void;
  (message?: any, ...optionalParams: any[]): void;
}

interface ConsoleOverrides {
  originalConsoleError: ConsoleHandlerFunction;
  originalConsoleWarn: ConsoleHandlerFunction;
}

const filterConsoleErrors = (): void => {
  const consoleError: ConsoleHandlerFunction = console.error;
  const SUPPRESSED_ERRORS: string[] = [
    'prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase',
    'which is inside StrictMode. Instead, add a ref directly to the element you want to reference',
  ];

  console.error = function filterErrors(msg, ...args) {
    if (!SUPPRESSED_ERRORS.some((entry) => msg.includes(entry))) {
      consoleError(msg, ...args);
    }
  };
};

const filterConsoleWarnings = (): void => {
  const consoleWarning: ConsoleHandlerFunction = console.warn;
  const SUPPRESSED_WARNINGS: string[] = [
    'findDOMNode is deprecated in StrictMode',
    'minHeight(undefined) or use aspect(undefined) to control',
  ];

  console.warn = function filterWarnings(msg, ...args): void {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleWarning(msg, ...args);
    }
  };
};

const setupErrorAndWarningFilters = (): ConsoleOverrides => {
  const originalConsoleError = console.error;
  const originalConsoleWarn = console.warn;

  filterConsoleErrors();
  filterConsoleWarnings();

  return { originalConsoleError, originalConsoleWarn };
};

export { setupErrorAndWarningFilters };
