import styled from 'styled-components';

export const StyledContactPage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 1rem;

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const StyledIconBlock = styled.div``;

export const StyledTextBlock = styled.div`
  font-size: 1rem;
`;

export const StyledGrayText = styled.div``;

export const StyledBlueText = styled.div`
  color: ${({ theme }) => theme.colors.blue};
`;
