import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { MembershipListResponse } from 'api/types/membership/membership';

const useMembershipQuery = (options?: UseQueryOptionsObject<MembershipListResponse>) =>
  useMakeQuery<MembershipListResponse>(
    [queries.privacy_policy, 'get'],
    () => Api.membership.membership.fetchList(),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useMembershipQuery };
