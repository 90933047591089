import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'api';
import { RoleMatrixResponse } from 'api/types/role/role';
import { CheckboxInput } from '@btc-snxt/ui';
import {
  StyledTable,
  IconAdd,
  PrimaryButton,
  error,
  success,
  FixedCenteredSpinner,
} from '@btc-snxt/ui';
import { useAuth } from 'helpers/useAuth/useAuth';
import { RolePageWrapper } from '../Roles/Roles.style';
import { useRolesQuery } from 'api/queries/role/useRolesQuery';
import { usePermissionsQuery } from 'api/queries/role/usePermissionsQuery';

const RoleAndPermissionMatrix: React.FC = () => {
  const { t } = useTranslation();

  const { refresh } = useAuth();

  const roles = useRolesQuery();
  const permissions = usePermissionsQuery();

  const onRoleChange = useCallback(
    (roleId: string, permissionId: string) => {
      const roleIndex = roles.data?.findIndex((r) => r.id === roleId);
      const permission = permissions.data?.find((p) => p.id === permissionId);

      if (roleIndex == -1 || !permission) return;

      const newRoles = roles.data ? [...roles.data] : [];
      const selectedRole = roleIndex && newRoles[roleIndex];

      if (selectedRole) {
        const selectedRolePermissionIndex = selectedRole.permissions.findIndex(
          (p) => p.id === permissionId,
        );

        // permission not found in current role, adding it
        if (selectedRolePermissionIndex === -1) {
          selectedRole.permissions.push(permission);
        } else {
          selectedRole.permissions.splice(selectedRolePermissionIndex, 1);
        }

        roles.setQueryData((prev) => {
          return prev;
        });
      }
    },
    [permissions, roles],
  );

  const onSubmit = useCallback(
    async (roles: RoleMatrixResponse[]) => {
      let didFail = false;
      await Api.role.role
        .updateRolesPermissions({ roles })
        .catch(() => {
          didFail = true;
          error(t('permissions.error'));
        })
        .finally(() => {
          if (!didFail) {
            success(t('permissions.success'));
            refresh(Api.user.auth.refreshToken);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  if (roles.isLoading || permissions.isLoading) {
    return <FixedCenteredSpinner />;
  }
  return (
    <RolePageWrapper>
      <PrimaryButton
        onClick={() => {
          roles.data && onSubmit(roles.data);
        }}
      >
        <IconAdd /> {t('common.save')}
      </PrimaryButton>
      <StyledTable>
        <thead>
          <tr>
            <th colSpan={2} />
            {roles.data?.map((role, key) => (
              <th key={key}>{role.name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {permissions.data?.map((permission, key) => {
            const [currentSubsystem] = permission.name.split('.', 2);
            let previousScope = null;

            if (key > 0) {
              [previousScope] = permissions.data[key - 1].name.split('.', 2);
            }

            return (
              <React.Fragment key={key}>
                {currentSubsystem !== previousScope && roles.data && (
                  <tr>
                    <th colSpan={2 + roles.data.length}>{t('subsystem.' + currentSubsystem)}</th>
                  </tr>
                )}
                <tr key={key}>
                  <th></th>
                  <th className={'w-25'}>{t('permissions.' + permission.name)}</th>
                  {roles.data?.map((role, key) => (
                    <th key={key}>
                      <CheckboxInput
                        checked={!!role.permissions.find((r: any) => r.id === permission.id)}
                        onChange={() => onRoleChange(role.id, permission.id)}
                      />
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </StyledTable>
      <PrimaryButton
        onClick={() => {
          roles.data && onSubmit(roles.data);
        }}
      >
        <IconAdd /> {t('common.save')}
      </PrimaryButton>
    </RolePageWrapper>
  );
};

export default RoleAndPermissionMatrix;
