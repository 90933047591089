import styled from 'styled-components';
import { breakpointDown, PrimaryButton } from '@btc-snxt/ui';

export const StyledDropdown = styled.div`
  width: 100%;
`;

export const SurveySelectAndCalendarWrapper = styled.div`
  display: flex;
  gap: 1rem;

  ${breakpointDown.LG} {
    flex-direction: column;
  }
`;

export const CategoriesWrapper = styled.div`
  display: inline-grid;
  margin-top: 1rem;
  width: 100%;

  ${breakpointDown.LG} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FilterButtonWrapper = styled.div`
  ${PrimaryButton} {
    margin-bottom: 1rem;
  }
  display: flex;
`;

export const EmptyCategoriesButton = styled.div`
  ${PrimaryButton} {
    margin-bottom: 1rem;
  }
  display: flex;
`;

export const MultiSelectDropdownWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const AnalysisCalendarWrapper = styled.div`
  width: 100%;
  ${breakpointDown.LG} {
    width: 100%;
    .react-calendar__viewContainer {
      flex-direction: column;

      .react-calendar__month-view {
        padding-bottom: 1rem;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
`;
