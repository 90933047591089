import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Collapse, IconArrowDown, IconArrowUp, IconManageAccounts } from '@btc-snxt/ui';
import usePermissions from 'helpers/usePermissions';
import { NavItem } from 'layouts/GenericLayout/components/Sidebar/components/NavItem';
import { NavLink } from 'layouts/GenericLayout/components/Sidebar/components/NavLink';
import { RouteList } from 'routeList';
import { SubMenuNavItem, StyledSubMenu } from './SubMenu.style';

interface Props {
  onItemClick?: () => void;
}

const SubMenu: React.FC<Props> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const remotePermissions = usePermissions();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const toggle = () => setCollapsed(!collapsed);

  return (
    <>
      <NavItem onClick={toggle}>
        <NavLink className={collapsed ? '' : 'open'}>
          <IconManageAccounts />
          {t('sidebar.user_management')}
          {collapsed ? <IconArrowDown /> : <IconArrowUp />}
        </NavLink>
      </NavItem>

      <StyledSubMenu>
        <Collapse isOpen={!collapsed}>
          {remotePermissions.hasAny('clients.list') && (
            <SubMenuNavItem>
              <NavLink to={RouteList.CLIENTS.path} tag={RouterNavLink}>
                {t('sidebar.clients')}
              </NavLink>
            </SubMenuNavItem>
          )}

          {remotePermissions.hasAny('users.list') && (
            <SubMenuNavItem>
              <NavLink to={RouteList.USERS.path} tag={RouterNavLink} onClick={onItemClick}>
                {t('sidebar.users')}
              </NavLink>
            </SubMenuNavItem>
          )}

          {remotePermissions.hasAny('roles.list') && (
            <SubMenuNavItem>
              <NavLink to={RouteList.ROLE.ROLES.path} tag={RouterNavLink} onClick={onItemClick}>
                {t('sidebar.roles')}
              </NavLink>
            </SubMenuNavItem>
          )}

          {remotePermissions.hasAny('roles.list_permission') && (
            <SubMenuNavItem>
              <NavLink to={RouteList.ROLE.MATRIX.path} tag={RouterNavLink} onClick={onItemClick}>
                {t('sidebar.matrix')}
              </NavLink>
            </SubMenuNavItem>
          )}
        </Collapse>
      </StyledSubMenu>
    </>
  );
};

export default SubMenu;
