import React from 'react';
import {
  StyledBlockHeader,
  StyledDashboardCard,
  StyledDashboardCardBody,
  StyledDashboardCardHeader,
  StyledHeaderExtraText,
  StyledHeaderTextBlock,
} from './DashboardCard.style';

interface Props {
  title: string;
  extraText?: string;
  icon?: JSX.Element;
  size: 'sm' | 'md' | 'lg';
}

const DashboardCard: React.FC<Props> = (props) => {
  return (
    <StyledDashboardCard className={props.size}>
      <StyledDashboardCardHeader>
        <StyledHeaderTextBlock>
          <StyledBlockHeader>{props.title}</StyledBlockHeader>

          {props.extraText && <StyledHeaderExtraText>{props.extraText}</StyledHeaderExtraText>}
        </StyledHeaderTextBlock>

        {props.icon}
      </StyledDashboardCardHeader>

      <StyledDashboardCardBody>{props.children}</StyledDashboardCardBody>
    </StyledDashboardCard>
  );
};

export default DashboardCard;
