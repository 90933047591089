import styled from 'styled-components';
import { Icon, IconGroup, ProgressBarWrapper, breakpointDown, breakpointUp } from '@btc-snxt/ui';

export const StyledBigNumber = styled.div`
  line-height: 0;
  font-size: 6rem;
  font-weight: bold;
`;

export const StyledDashboardCardBody = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const StyledDashboardCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.625rem ${({ theme }) => theme.colors.gray11};
  color: ${({ theme }) => theme.colors.blue};
  margin: 1rem;

  &.sm {
    height: 13.875rem;

    ${breakpointUp.SM} {
      flex: 1 0 0;
    }
  }

  &.md {
    ${breakpointDown.SM} {
      ${ProgressBarWrapper} {
        .CircularProgressbar {
          height: 6rem;
        }
      }
    }

    ${breakpointUp.SM} {
      height: 20rem;
    }
  }

  &.lg {
    ${StyledDashboardCardBody} {
      align-items: start;
    }

    ${breakpointUp.LG} {
      height: 26.125rem;
    }
  }
`;

export const StyledDashboardCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.125rem;

  ${breakpointDown.SM} {
    padding-bottom: 0;
  }

  ${Icon} {
    background-color: ${({ theme }) => theme.colors.blue};
    height: 2rem;
    width: 2rem;
  }

  ${IconGroup} {
    width: 3rem;
  }
`;

export const StyledHeaderTextBlock = styled.div``;

export const StyledBlockHeader = styled.div``;

export const StyledHeaderExtraText = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray1};
`;

export const StyledCalendar = styled.div`
  ${breakpointDown.LG} {
    .react-calendar__viewContainer {
      flex-direction: column;

      .react-calendar__month-view {
        padding-bottom: 1rem;
      }
    }
  }
`;

export const StyledCalendarInputBlock = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpointUp.SM} {
    column-gap: 1.5rem;
  }

  input {
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.colors.gray1};
  }

  ${breakpointDown.SM} {
    flex-direction: column;
  }
`;
