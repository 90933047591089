import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { H1 } from '@btc-snxt/ui';
import { useTabsContext } from 'components/Tabs/TabsContext';
import { StyledContactSettings } from './ContactSettings.style';

const ContactSettings: React.FC = () => {
  const { t } = useTranslation();

  const tabsContext = useTabsContext();

  useEffect(() => {
    tabsContext.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledContactSettings>
        <H1>{t('settings.contact_info')}</H1>
      </StyledContactSettings>
    </>
  );
};

export default ContactSettings;
