import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink, useLocation, useNavigate } from 'react-router-dom';
import { IconAdd, IconBurger, IconContactSupport, Logo } from '@btc-snxt/ui';
import usePermissions from 'helpers/usePermissions';
import { useGenericLayoutStore } from 'layouts/GenericLayout/GenericLayout.store';
import ContactInfoModal from 'pages/Modal/Contact/ContactInfoModal';
import { RouteList } from 'routeList';
import { ProfileDropdown } from './components/ProfileDropdown';
import {
  DarkToggle,
  HeaderBlockWrapper,
  HeaderBody,
  StyledHeader,
  StyledButtonsBlock,
  HeaderButtons,
  LogoWrapper,
  BurgerButtonFullscreen,
  CreateButton,
  StyledIconButton,
} from './Header.style';

const Header: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [isContactModelOpen, setContactModalOpen] = useState<boolean>(false);
  const toggleContactModal = () => setContactModalOpen(!isContactModelOpen);

  const sidebarState = useGenericLayoutStore();
  const remotePermissions = usePermissions();

  const isCurrentPageCreateSurvey = () => location.pathname === RouteList.SURVEYS.SELECT_TYPE.path;

  return (
    <StyledHeader className={sidebarState.isSidebarOpen ? 'open' : ''}>
      <HeaderBody>
        <DarkToggle
          onClick={() => {
            sidebarState.setIsSidebarOpen({ state: false });
          }}
        />

        <HeaderBlockWrapper>
          <HeaderButtons>
            <BurgerButtonFullscreen
              className={sidebarState.isSidebarOpen ? '' : 'left'}
              onClick={() => {
                sidebarState.setIsSidebarOpen({ state: !sidebarState.isSidebarOpen });
              }}
            >
              <IconBurger />
            </BurgerButtonFullscreen>

            <LogoWrapper onClick={() => navigate(RouteList.DASHBOARD.path)}>
              <Logo />
            </LogoWrapper>
          </HeaderButtons>

          <StyledButtonsBlock>
            {remotePermissions.hasAny('surveys.create') && !isCurrentPageCreateSurvey() && (
              <CreateButton
                to={RouteList.SURVEYS.SELECT_TYPE.path}
                tag={RouterNavLink}
                onClick={() => {
                  sidebarState.setIsSidebarOpen({ state: false });
                }}
              >
                <IconAdd />

                {t('header.create_survey')}
              </CreateButton>
            )}

            <StyledIconButton onClick={toggleContactModal}>
              <IconContactSupport />
            </StyledIconButton>

            <ProfileDropdown />
          </StyledButtonsBlock>
        </HeaderBlockWrapper>
      </HeaderBody>

      <ContactInfoModal
        isOpen={isContactModelOpen}
        setIsOpen={setContactModalOpen}
        toggle={toggleContactModal}
      />
    </StyledHeader>
  );
};

export default Header;
