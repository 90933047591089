import { Icon, PrimaryButton, StyledTable } from '@btc-snxt/ui';
import styled from 'styled-components';

export const EmailWrapper = styled.div`
  margin-top: 2rem;
  font-size: 1.25rem;
`;

export const AddEmailText = styled.div`
  width: fit-content;
  cursor: pointer;
`;

export const SendButton = styled(PrimaryButton)`
  margin-top: 0.625rem;
  margin-bottom: 2.5rem;
  float: right;
`;

export const TableWrapper = styled.div`
  max-height: 22.5rem;
  overflow: overlay;
  @-moz-document url-prefix() {
    overflow: auto;
  }

  ${Icon} {
    cursor: pointer;
  }
`;

export const CSVReaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .csv-input {
    display: none;
  }
`;

export const AddContactsCSVWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  input {
    float: left;
  }
`;

export const Table = styled(StyledTable)`
  th {
    font-size: 1rem;
    text-transform: none;
  }
  td {
    font-size: 0.875rem;
  }
`;

export const EmailStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 100%;
`;

export const EmailBody = styled.div<GlobalColor>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5.125rem;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  width: 100%;
  height: 100%;
  ${PrimaryButton} {
    background-color: ${(props) => props.$backgroundColor || props.theme.colors.blue};
    border: none;
    max-width: 13.25rem;
  }
`;

export const EmailFooter = styled.div<GlobalColor>`
  height: 5.75rem;
  width: 100%;
  background-color: ${(props) => props.$backgroundColor || props.theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  font-size: 0.75rem;
  margin-bottom: 1.875rem;

  div:first-of-type {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }

  div:nth-child(2) {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 2.25rem;
    color: ${({ theme }) => theme.colors.white};
    i {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TextArea = styled.textarea`
  width: 80%;
  font-size: 1.125rem;
  height: 22.25rem;
  margin: 2.25rem 2.25rem 3.125rem 3.125rem;
  background-color: ${({ theme }) => theme.colors.gray5};
  border: none;
`;

export const SendButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  padding-bottom: 1rem;
`;

interface GlobalColor {
  $backgroundColor?: string;
}

export const EmailLogoUpload = styled.div<GlobalColor>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 10rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${(props) => props.$backgroundColor || props.theme.colors.blue};
`;
