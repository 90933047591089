import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage, FormikHelpers } from 'formik';

import {
  Col,
  FormModal,
  BaseModalProps,
  Row,
  success,
  LoadingState,
  withLoader,
  Label,
} from '@btc-snxt/ui';
import { Api } from 'api';
import { CreateCategoryRequest } from 'api/types/category/category';
import { BodyWrapper } from 'pages/Modal/Users/AddUserModal.style';

interface Props extends BaseModalProps, LoadingState {
  handleAdd?: () => void;
}

const CategoryAddModal: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<CreateCategoryRequest>({
    name: '',
  });

  const FormSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const clearValues = () => {
    setFormData({
      name: '',
    });
  };

  const handleSubmit = async <T extends CreateCategoryRequest>(
    values: T,
    helper: FormikHelpers<T>,
  ) => {
    helper.setSubmitting(true);
    try {
      await Api.category.category.createCategory(values);
      props.setIsOpen(false);
      props.handleAdd && props.handleAdd();
      success(t('category.create_success'));
      clearValues();
    } catch (e: any) {
      helper.setErrors(e.response.errors);
    } finally {
      helper.setSubmitting(false);
    }
  };

  return (
    <FormModal
      {...props}
      title={t('title.add_category')}
      handleSubmit={handleSubmit}
      initialValues={formData}
      validationSchema={FormSchema}
    >
      <BodyWrapper>
        <Row>
          <Col>
            <Label htmlFor={'name'} text={t('label.alias')} required={true} />

            <Field type={'text'} name={'name'} id={'name'} className={'form-control'} />

            <ErrorMessage component={'div'} className={'text-danger'} name={'name'} />
          </Col>
        </Row>
      </BodyWrapper>
    </FormModal>
  );
};

export default withLoader(CategoryAddModal);
