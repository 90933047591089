import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { H1, LanguageDropdown } from '@btc-snxt/ui';
import { useTabsContext } from 'components/Tabs/TabsContext';
import {
  SelectLanguageLabel,
  SelectLanguageWrapper,
  StyledSystemSettings,
} from './SystemSettings.style';

const SystemSettings: React.FC = () => {
  const { t } = useTranslation();

  const tabsContext = useTabsContext();

  useEffect(() => {
    tabsContext.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledSystemSettings>
        <H1>{t('settings.system')}</H1>
        <SelectLanguageWrapper>
          <SelectLanguageLabel>{t('label.system_language')}</SelectLanguageLabel>
          <LanguageDropdown />
        </SelectLanguageWrapper>
      </StyledSystemSettings>
    </>
  );
};

export default SystemSettings;
