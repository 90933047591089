import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { CenteredSpinner } from '@btc-snxt/ui';
import { Api } from 'api';
import { SocialNetworkType } from 'api/types/user/auth';
import { useAuth } from 'helpers/useAuth/useAuth';
import { RouteList } from 'routeList';
import {
  StyledCallbackBody,
  StyledCallbackButton,
  StyledCallbackHeader,
  StyledFacebookCallbackPage,
  StyledSpinnerWrapper,
} from './SocialFacebook.style';

const SocialFacebook: React.FC = () => {
  const { t } = useTranslation();

  const { login } = useAuth();

  const navigate = useNavigate();

  const [needVerifyEmail, setNeedVerifyEmail] = useState<boolean>(false);

  const [code] = useQueryParam('code', StringParam);

  useEffect(() => {
    (async () => {
      if (code) {
        const response = await Api.user.auth.getSocialCallback({
          code: code,
          social_network_type: SocialNetworkType.Facebook,
        });

        if (response.access_token) {
          login(response.access_token, response.permissions);
        }

        setNeedVerifyEmail(true);
      }

      navigate(RouteList.AUTHENTICATION.LOGIN.path);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledFacebookCallbackPage>
      <StyledCallbackHeader>
        {needVerifyEmail
          ? t('auth.register.social_register_success_header')
          : t('auth.login.waiting_server_response_text')}
      </StyledCallbackHeader>

      {needVerifyEmail ? (
        <>
          <StyledCallbackBody>{t('auth.register.register_success_body')}</StyledCallbackBody>
          <StyledCallbackButton onClick={() => navigate(RouteList.AUTHENTICATION.LOGIN.path)}>
            {t('auth.login.login')}
          </StyledCallbackButton>
        </>
      ) : (
        <StyledSpinnerWrapper>
          <CenteredSpinner />
        </StyledSpinnerWrapper>
      )}
    </StyledFacebookCallbackPage>
  );
};

export default SocialFacebook;
