export enum InvoiceStatus {
  Paid = 'paid',
  Issued = 'issued',
  Cancelled = 'cancelled',
}

export type InvoicesTableResponse = {
  id: number;
  company_name: string;
  invoice_number: string;
  amount: number;
  status: InvoiceStatus;
  generated_at: any;
  invoice_url: string;
};

export type UpdateInvoiceStatusRequest = {
  status: InvoiceStatus;
};

export interface InvoicePaymentResponse {
  payment_url: string;
}

export type InvoicePreviewResponse = {
  invoice: InvoiceResponse;
  clientMembership: ClientMembershipResponse;
  membershipPlan: MembershipPlan;
};

export type InvoiceResponse = {
  companyName: string;
  invoiceNumber: string;
  amount: number;
  status: string;
  generatedAt: string;
};

export type ClientMembershipResponse = {
  billingFrequency: string;
  nextBillingDate: string;
  price: number;
  autoRenew: boolean;
};

export type MembershipPlan = {
  name: string;
};
