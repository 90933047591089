import styled from 'styled-components';
import { PrimaryButton, Icon, IconArrowDown, H1, H6, IconShow } from '@btc-snxt/ui';

export const StyledInvoicesSettings = styled.div`
  ${H1} {
    display: flex;
    justify-content: center;
  }

  ${H6} {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colors.gray3};
  }

  ${PrimaryButton} {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: none;

    ${Icon} {
      height: 1rem;
      width: 1rem;
    }
  }

  ${IconArrowDown} {
    margin-left: 0.5rem;
  }
`;

export const StyledInvoicesTable = styled.div`
  tbody {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const StyledAmountCell = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.25rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;

  &.paid {
    background-color: ${({ theme }) => theme.colors.green};
  }

  &.issued {
    background-color: ${({ theme }) => theme.colors.blue};
  }

  &.cancelled {
    background-color: ${({ theme }) => theme.colors.red};
  }
`;

export const StyledPayButton = styled.div`
  ${PrimaryButton} {
    font-size: 0.75rem;
    margin: 0.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const StyledIconShow = styled(IconShow)`
  cursor: pointer;
`;
