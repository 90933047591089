import Client from 'api/services/client';
import Http from 'api/http';
import { EmptyResponse } from 'api/types/common';
import {
  ConfirmResetPasswordRequest,
  LoginRequest,
  SocialLoginUrlRequest,
  SocialLoginUrlResponse,
  LoginUserResponse,
  RefreshUserResponse,
  RegisterRequest,
  ResetPasswordRequest,
  SocialLoginRequest,
  RegisterClientRequest,
  PasswordTokenRequest,
} from 'api/types/user/auth';

class Auth extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      LOGIN: `${this.http.baseUrl}/auth/login`,
      REGISTER: `${this.http.baseUrl}/auth/register`,
      REGISTER_CLIENT: `${this.http.baseUrl}/auth/register-client`,
      REFRESH: `${this.http.baseUrl}/auth/refresh`,
      LOGOUT: `${this.http.baseUrl}/auth/logout`,
      RESET_PASSWORD: `${this.http.baseUrl}/auth/reset-password`,
      CONFIRM_RESET_PASSWORD: `${this.http.baseUrl}/auth/confirm-reset-password`,
      VALIDATE_PASSWORD_TOKEN: `${this.http.baseUrl}/auth/validate-password-token`,
      VERIFY_EMAIL: `${this.http.baseUrl}/auth/email/verify/{verify_link}`,
      SOCIAL_GET_URL: `${this.http.baseUrl}/auth/social`,
      SOCIAL_LOGIN: `${this.http.baseUrl}/auth/social/login`,
    };
  }

  login = (data: LoginRequest): Promise<LoginUserResponse> => {
    return this.http.post(this.api.LOGIN, data);
  };

  refreshToken = (): Promise<RefreshUserResponse> => {
    return this.http.put(this.api.REFRESH);
  };

  logout = (): Promise<void> => {
    return this.http.post(this.api.LOGOUT);
  };

  resetPassword = (data: ResetPasswordRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.RESET_PASSWORD, data);
  };

  confirmResetPassword = (data: ConfirmResetPasswordRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.CONFIRM_RESET_PASSWORD, data);
  };

  validatePasswordToken = (data: PasswordTokenRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.VALIDATE_PASSWORD_TOKEN, data);
  };

  register = (data: RegisterRequest): Promise<EmptyResponse> => {
    return this.http.post(this.api.REGISTER, data);
  };

  registerClient = (data: RegisterClientRequest): Promise<LoginUserResponse> => {
    return this.http.post(this.api.REGISTER_CLIENT, data);
  };

  verifyEmail = (verify_link: any): Promise<EmptyResponse> => {
    return this.http.get(this.buildUrl(this.api.VERIFY_EMAIL, { verify_link }));
  };

  getSocialUrl = (data: SocialLoginUrlRequest): Promise<SocialLoginUrlResponse> => {
    return this.http.post(this.api.SOCIAL_GET_URL, data);
  };

  getSocialCallback = (data: SocialLoginRequest): Promise<LoginUserResponse | EmptyResponse> => {
    return this.http.post(this.api.SOCIAL_LOGIN, data);
  };
}

export default Auth;
