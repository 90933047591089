import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconClose, IconEdit, IconRemove, SecondaryButton } from '@btc-snxt/ui';
import usePermissions from 'helpers/usePermissions';
import { useMembershipContext } from 'pages/Common/Settings/tabs/MembershipSettings/MembershipSettingsContext';
import Icons from 'pages/Common/Settings/tabs/MembershipSettings/services/icon';
import { MembershipDeleteConfirmModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipDeleteConfirmModal';
import { MembershipDetailsModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipDetailsModal';
import { MembershipEditModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipEditModal';
import { MembershipSubscribeModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipSubscribeModal';
import { MembershipUnsubscribeModal } from 'pages/Common/Settings/tabs/MembershipSettings/components/MembershipUnsubscribeModal';
import {
  StyledCircle,
  StyledMembershipPlanIcon,
  StyledMembershipCard,
  StyledMembershipCardHeader,
  StyledPriceBlock,
  StyledPrice,
  StyledPriceText,
  StyledDescription,
  StyledFeaturesBlock,
  StyledFeaturesHeader,
  StyledFeaturesBody,
  StyledFeaturesFooter,
  StyledFeature,
  StyledAdminIconsBlock,
  StyledDetailsButton,
  StyledYearPriceText,
  StyledCurrentPlanButton,
  StyledUnsubscribeButton,
} from './MembershipCard.style';
import { useAppState } from 'modules/appState';

const MembershipCard: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { client } = useAppState();

  const canViewMembership = usePermissions().hasAny('memberships.get');
  const canDeleteMembership = usePermissions().hasAny('memberships.delete');
  const canUpdateMembership = usePermissions().hasAny('memberships.update');

  const membership = useMembershipContext();

  const membershipIcons = new Icons();

  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState<boolean>(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isUnsubscribeModalOpen, setUnsubscribeModalOpen] = useState<boolean>(false);

  const toggleSubscribeModal = () => setSubscribeModalOpen(!isSubscribeModalOpen);
  const toggleDetailsModal = () => setDetailsModalOpen(!isDetailsModalOpen);
  const toggleEditModal = () => setEditModalOpen(!isEditModalOpen);
  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);
  const toggleUnsubscribeModal = () => setUnsubscribeModalOpen(!isUnsubscribeModalOpen);

  return (
    <StyledMembershipCard>
      <StyledMembershipCardHeader>{membership.name[i18n.language]}</StyledMembershipCardHeader>

      <StyledMembershipPlanIcon>
        <StyledCircle>{membershipIcons.getIcon(membership.icon_id)?.icon}</StyledCircle>
      </StyledMembershipPlanIcon>

      <StyledPriceBlock>
        <StyledPrice>€{membership.monthly_price}</StyledPrice>

        <StyledPriceText>{t('membership.price_month')}</StyledPriceText>
      </StyledPriceBlock>

      <StyledYearPriceText>
        €{membership.yearly_price} {t('membership.price_year')}
      </StyledYearPriceText>

      <StyledDescription>{membership.description[i18n.language]}</StyledDescription>

      <StyledFeaturesBlock>
        <StyledFeaturesHeader>{t('membership.plan_features')}</StyledFeaturesHeader>

        <StyledFeaturesBody>
          {membership.benefits.map((benefit, index) => (
            <StyledFeature key={index} style={{ display: index > 3 ? 'none' : '' }}>
              {benefit.is_active ? <IconAdd /> : <IconClose />}
              {benefit.labels[i18n.language]}
            </StyledFeature>
          ))}
        </StyledFeaturesBody>

        <StyledFeaturesFooter>
          <StyledDetailsButton onClick={toggleDetailsModal} disabled={!canViewMembership}>
            {t('membership.details_button')}
          </StyledDetailsButton>
        </StyledFeaturesFooter>
      </StyledFeaturesBlock>

      {client?.membership?.id == membership.id ? (
        <>
          <StyledCurrentPlanButton>{t('membership.current_plan_button')}</StyledCurrentPlanButton>

          {client?.membership.auto_renew && (
            <StyledUnsubscribeButton onClick={toggleUnsubscribeModal}>
              {t('membership.unsubscribe_button')}
            </StyledUnsubscribeButton>
          )}
        </>
      ) : (
        <SecondaryButton onClick={toggleSubscribeModal}>
          {t('membership.subscribe_button')}
        </SecondaryButton>
      )}

      <StyledAdminIconsBlock>
        {canDeleteMembership && (
          <button onClick={toggleDeleteModal}>
            <IconRemove />
          </button>
        )}

        {canUpdateMembership && (
          <button onClick={toggleEditModal}>
            <IconEdit />
          </button>
        )}
      </StyledAdminIconsBlock>

      <MembershipSubscribeModal
        isOpen={isSubscribeModalOpen}
        setIsOpen={setSubscribeModalOpen}
        toggle={toggleSubscribeModal}
      />

      <MembershipDetailsModal
        isOpen={isDetailsModalOpen}
        setIsOpen={setDetailsModalOpen}
        toggle={toggleDetailsModal}
      />

      <MembershipEditModal
        isOpen={isEditModalOpen}
        setIsOpen={setEditModalOpen}
        toggle={toggleEditModal}
      />

      <MembershipDeleteConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        toggle={toggleDeleteModal}
      />

      <MembershipUnsubscribeModal
        isOpen={isUnsubscribeModalOpen}
        setIsOpen={setUnsubscribeModalOpen}
        toggle={toggleUnsubscribeModal}
      />
    </StyledMembershipCard>
  );
};

export default MembershipCard;
