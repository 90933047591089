import React, { createContext, useContext, useState } from 'react';

interface TabsContextProps {
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TabsContext = createContext<TabsContextProps>(undefined as any);

const TabsProvider: React.FC = (props) => {
  const [isLoading, setLoading] = useState<boolean>(true);

  return (
    <TabsContext.Provider value={{ isLoading, setLoading }}>{props.children}</TabsContext.Provider>
  );
};

const useTabsContext = (): TabsContextProps => {
  return useContext(TabsContext);
};

export { TabsProvider, useTabsContext };
