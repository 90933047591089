import { Api } from 'api';
import { queries } from 'api/queries';
import { UseQueryOptionsObject, defaultOptions } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { SearchRequest } from 'api/types/common';
import { SurveySelectResponseDto } from 'api/types/survey';

const useSelectableSurveysQuery = (
  request: SearchRequest,
  options?: UseQueryOptionsObject<SurveySelectResponseDto[]>,
) =>
  useMakeQuery<SurveySelectResponseDto[]>(
    [queries.survey, 'selectable-surveys', request],
    () => Api.survey.survey.getSelectableSurveys(request),
    {
      staleTime: Infinity,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );

export { useSelectableSurveysQuery };
