import styled from 'styled-components';
import { Col, ModalH3 } from '@btc-snxt/ui';

export const BodyWrapper = styled.div`
  ${Col} {
    margin-top: 0.625rem;
  }

  ${ModalH3} {
    padding-top: 1.625rem;
  }
`;

export const FooterWrapper = styled.div`
  *:not(:last-child) {
    margin-right: 1rem;
  }
`;
