import styled from 'styled-components';

export const StyledNPSDistributionBody = styled.div`
  display: flex;
`;

export const StyledPieChartBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  gap: 1rem;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
`;

export const StyledDistributionTextBlock = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const StyledMainText = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledExtraText = styled.div`
  display: flex;
  font-size: 0.75rem;
`;

export const StyledTotalText = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black}; ;
`;
