import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { SurveyType } from '@btc-snxt/survey';
import {
  IconCustomerSatisfaction,
  IconCustomerEffort,
  IconProductExperience,
  IconCustomerExperience,
  IconLoyaltyIntentions,
  IconEmployeeSatisfaction,
  IconNetPromoterScore,
  IconIndividualSurvey,
  parseTranslatable,
  CenteredSpinner,
  IconInfo,
  IconAddAlt,
  IconCloseAlt,
} from '@btc-snxt/ui';
import { SurveyTypeResponse } from 'api/types/survey';
import SurveyNameModal from 'pages/Modal/Survey/SurveyNameModal';
import {
  StyledTemplateButton,
  StyledTemplateIcon,
  StyledTemplateButtonHeader,
  StyledTemplatesContainer,
  StyledSelectSurveyTypePage,
  StyledTemplatesContainerTitle,
  StyledTemplatesContainerSubTitle,
  StyledTemplatesContainerButtonsBlock,
  StyledTemplatesContainerTitlesBlock,
  StyledTemplateButtonBody,
  StyledTemplateButtonFooter,
  StyledTemplateUseButton,
  StyledTemplateBlankButton,
  StyledTemplateIconWrapper,
  StyledDetailsSidebar,
  StyledSidebarFooter,
  StyledSidebarHeader,
  StyledSidebarBody,
  StyledSidebarHeaderTitle,
  StyledTemplateButtonHeaderTitle,
  StyledTemplateButtonHeaderIconWrapper,
  StyledSidebarHeaderIconWrapper,
} from './SelectSurveyType.style';
import { useSelectSurveyTypeQuery } from 'api/queries/survey/useSelectSurveyTypeQuery';

export interface SurveyTemplateInfo {
  type?: SurveyType;
  path?: string;
  icon?: React.ReactNode;
}

interface SurveyTemplateButton extends SurveyTemplateInfo, Omit<SurveyTypeResponse, 'type'> {}

const SelectSurveyType: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { data, isLoading } = useSelectSurveyTypeQuery();

  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const toggleSidebarOpen = (): void => setSidebarOpen((prevState) => !prevState);

  const [selectedType, setSelectedType] = useState<SurveyTemplateInfo>();

  const [selectedDetailsType, setSelectedDetailsType] = useState<SurveyTemplateButton>();

  const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
  const toggleAddModal = () => setAddModalOpen((prevState) => !prevState);

  const surveyTemplatesMap: SurveyTemplateInfo[] = [
    {
      type: SurveyType.CustomerSatisfaction,
      path: '/survey/customer-satisfaction/design',
      icon: <IconCustomerSatisfaction />,
    },
    {
      type: SurveyType.CustomerEffort,
      path: '/survey/customer-effort/design',
      icon: <IconCustomerEffort />,
    },
    {
      type: SurveyType.ProductExperience,
      path: '/survey/product-experience/design',
      icon: <IconProductExperience />,
    },
    {
      type: SurveyType.CustomerExperience,
      path: '/survey/customer-experience/design',
      icon: <IconCustomerExperience />,
    },
    {
      type: SurveyType.LoyaltyIntentions,
      path: '/survey/loyalty-intentions/design',
      icon: <IconLoyaltyIntentions />,
    },
    {
      type: SurveyType.EmployeeSatisfaction,
      path: '/survey/employee-satisfaction/design',
      icon: <IconEmployeeSatisfaction />,
    },
    {
      type: SurveyType.NPS,
      path: '/survey/nps/design',
      icon: <IconNetPromoterScore />,
    },
    {
      type: SurveyType.IndividualSurvey,
      path: '/survey/individual-survey/design',
      icon: <IconIndividualSurvey />,
    },
  ];

  const buttons: SurveyTemplateButton[] | undefined =
    data &&
    data.map((v) => ({
      ...surveyTemplatesMap.find((e) => e.type === v.type),
      ...v,
    }));

  const onSurveyTypeSelect = (button: SurveyTemplateInfo) => {
    setSelectedType(button);
    setAddModalOpen(true);
  };

  const handleDetailsIconClick = (button: SurveyTemplateButton) => {
    setSelectedDetailsType(button);
    setSidebarOpen(true);
  };

  const handleDetailsCloseClick = () => {
    toggleSidebarOpen();
    setTimeout(() => setSelectedDetailsType(undefined), 150);
  };

  const selectedDetailsTypeLongDescription:
    | string
    | JSX.Element
    | (string | JSX.Element | JSX.Element[])[]
    | undefined = useMemo(() => {
    if (selectedDetailsType) {
      const descriptions = parseTranslatable(selectedDetailsType.long_description, i18n.language);

      if (typeof descriptions === 'string') {
        return parse(descriptions);
      }

      if (Array.isArray(descriptions)) {
        return descriptions.map((v) => parse(v));
      }

      return undefined;
    }
  }, [selectedDetailsType, i18n.language]);

  const renderTemplateButton = (button: SurveyTemplateButton, index: number) => (
    <StyledTemplateButton key={index} className={selectedDetailsType === button ? 'active' : ''}>
      <StyledTemplateButtonHeader>
        <StyledTemplateButtonHeaderTitle onClick={() => onSurveyTypeSelect(button)}>
          {parseTranslatable(button.title, i18n.language)}
        </StyledTemplateButtonHeaderTitle>

        <StyledTemplateButtonHeaderIconWrapper onClick={() => handleDetailsIconClick(button)}>
          <IconInfo />
        </StyledTemplateButtonHeaderIconWrapper>
      </StyledTemplateButtonHeader>

      <StyledTemplateButtonBody onClick={() => onSurveyTypeSelect(button)}>
        {parseTranslatable(button.short_description, i18n.language)}
      </StyledTemplateButtonBody>

      <StyledTemplateButtonFooter onClick={() => onSurveyTypeSelect(button)}>
        <StyledTemplateIcon>{button.icon}</StyledTemplateIcon>

        <StyledTemplateUseButton>{t('survey.type.use_template')}</StyledTemplateUseButton>
      </StyledTemplateButtonFooter>
    </StyledTemplateButton>
  );

  const renderBlankTemplateButton = (button: SurveyTemplateInfo | undefined) =>
    button && (
      <StyledTemplateBlankButton onClick={() => onSurveyTypeSelect(button)}>
        <StyledTemplateButtonHeader>
          <StyledTemplateButtonHeaderTitle>
            {t('survey.type.blank')}
          </StyledTemplateButtonHeaderTitle>
        </StyledTemplateButtonHeader>

        <StyledTemplateIconWrapper>
          <IconAddAlt />
        </StyledTemplateIconWrapper>
      </StyledTemplateBlankButton>
    );

  return (
    <StyledSelectSurveyTypePage>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <StyledTemplatesContainer className={isSidebarOpen ? 'open' : ''}>
          <StyledTemplatesContainerTitlesBlock>
            <StyledTemplatesContainerTitle>{t('survey.type.title')}</StyledTemplatesContainerTitle>

            <StyledTemplatesContainerSubTitle>
              {t('survey.type.sub_title')}
            </StyledTemplatesContainerSubTitle>
          </StyledTemplatesContainerTitlesBlock>

          <StyledTemplatesContainerButtonsBlock>
            {renderBlankTemplateButton(
              surveyTemplatesMap.find((v) => v.type === SurveyType.IndividualSurvey),
            )}

            {buttons?.map((button, index) => renderTemplateButton(button, index))}
          </StyledTemplatesContainerButtonsBlock>
        </StyledTemplatesContainer>
      )}

      <StyledDetailsSidebar className={isSidebarOpen ? 'open' : ''}>
        {selectedDetailsType && (
          <>
            <StyledSidebarHeader>
              <StyledSidebarHeaderTitle>
                <IconInfo />

                {t('survey.type.details')}
              </StyledSidebarHeaderTitle>

              <StyledSidebarHeaderIconWrapper onClick={handleDetailsCloseClick}>
                <IconCloseAlt />
              </StyledSidebarHeaderIconWrapper>
            </StyledSidebarHeader>

            <StyledSidebarBody>
              {selectedDetailsTypeLongDescription && selectedDetailsTypeLongDescription}
            </StyledSidebarBody>

            <StyledSidebarFooter>
              <StyledTemplateUseButton onClick={() => onSurveyTypeSelect(selectedDetailsType)}>
                {t('survey.type.use_template')}
              </StyledTemplateUseButton>
            </StyledSidebarFooter>
          </>
        )}
      </StyledDetailsSidebar>

      <SurveyNameModal
        isOpen={isAddModalOpen}
        setIsOpen={setAddModalOpen}
        toggle={toggleAddModal}
        size={'md'}
        selectedSurveyType={selectedType}
        // handleAdd={() => setRender((prevState) => prevState + 1)}
      />
    </StyledSelectSurveyTypePage>
  );
};

export default SelectSurveyType;
