import styled from 'styled-components';

export const StyledMessagePage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const StyledPageHeader = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2.5rem;
`;

export const StyledPageBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
`;

export const StyledFailText = styled.div``;

export const StyledSpinnerWrapper = styled.div`
  margin: 1rem;
`;

export const StyledRedirectText = styled.div``;
