import { useEffect, useState } from 'react';

const useDebounceQuery = <Data,>(value: Data, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<Data>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export { useDebounceQuery };
